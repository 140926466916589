import axios from "axios";
import Card from "components/card";
import SwitchField from "components/fields/SwitchField";
import { errorHandler } from "interfaces/utils";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import env from "variables/config";

const AlertSystem = () => {
    const [user, setUser] = useState(null);

    const initUser = () => {
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios
            .get(`${env.apiUrl}/user/me`, config)
            .then((res) => {
                const user = res.data;
                setUser(user);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const updateUser = (user: any) => {
        setUser(user);
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const data = {
            fullName: user.fullName,
            company: user.company,
            alerts: user.alerts,
        };
        axios
            .put(`${env.apiUrl}/user`, data, config)
            .then((res) => {
                toast.success(
                    "Préférence de notification modifiée avec succès"
                );
				console.log(data)
            })
            .catch(errorHandler);
    };

    useEffect(() => {
        initUser();
    }, []);

    return (
        <Card extra={"w-full px-[30px] py-[30px] pb-10 border"}>
            {/* Header */}
            <div className="flex items-center justify-between">
                <h4 className="text-lg font-medium">
                    Gestion des alertes
                </h4>
            </div>
            {/* content */}
            <div>
                <SwitchField
                    id="quantity"
                    label="Seuil d'alerte"
                    desc="Pour être alerté lorsque le stock d'un produit est bas."
                    mt="mt-5"
                    mb="mb-[25px]"
                    onChange={() =>
                        updateUser({
                            ...user,
                            alerts: {
                                ...user.alerts,
                                quantity: !user.alerts.quantity,
                            },
                        })
                    }
                    isDefaultChecked={user && user.alerts.quantity}
                />
                <SwitchField
                    id="dlc"
                    label="DLC en approche"
                    desc="Pour être alerté lorsque la DLC d'un produit est proche ou dépassé."
                    mt="mt-[35px]"
                    mb="mb-1"
                    onChange={() =>
                        updateUser({
                            ...user,
                            alerts: {
                                ...user.alerts,
                                dlc: !user.alerts.dlc,
                            },
                        })
                    }
                    isDefaultChecked={user && user.alerts.dlc}
                />
            </div>
        </Card>
    );
};

export default AlertSystem;
