const LoadingTableLine = () => {
    return (
        <div className="w-full flex gap-[5px] animate-pulse">
            <div className="hidden sm:block w-[15%] pl-[10px] py-3">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[4px]"></div>
            </div>
            <div className="hidden sm:block w-[10%] pl-[10px] py-3">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[4px]"></div>
            </div>
            <div className="hidden sm:block w-[25%] pl-[10px] py-3">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[4px]"></div>
            </div>
            <div className="hidden sm:block w-[10%] pl-[10px] py-3">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[4px]"></div>
            </div>
            <div className="hidden sm:block w-[10%] pl-[10px] py-3">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[4px]"></div>
            </div>
            <div className="hidden sm:block w-[15%] pl-[10px] py-3">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[4px]"></div>
            </div>
            <div className="hidden sm:block w-[15%] pl-[10px] py-3">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[4px]"></div>
            </div>
            <div className="sm:hidden w-[60%] pl-[10px] py-[3px]">
                <div className="h-2 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[65%] my-[6px]"></div>
                <div className="h-2 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[30%] my-[6px]"></div>
            </div>
            <div className="sm:hidden w-[40%] pl-[10px] py-[3px]">
                <div className="h-2 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[35%] my-[6px]"></div>
                <div className="h-2 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[20%] my-[6px]"></div>
            </div>
        </div>
    )
}

export default LoadingTableLine;