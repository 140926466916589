
function TopCustomerElement(props: {
  company: string;
  rank?: number;
  total: number;
  totalOrders?: number;
}) {
  const { rank, company, total, totalOrders } = props;

  return (
      <div className="mb-4 ml-1 flex items-center justify-between">
          {/* left side */}
          <div className="flex items-center gap-3">
              <div className="flex h-9 w-9 items-center justify-center rounded-full border">
                  {/* <img src={avatar} className="rounded-full" alt="img" /> */}
                  <p>#{rank}</p>
              </div>
              <div>
                  <h5 className="text-base font-bold text-navy-700 dark:text-white">
                      {company}
                  </h5>
                  <p className="text-sm font-medium text-gray-600"> {totalOrders} commandes </p>
              </div>
          </div>
          {/* right side */}
          <div
              className={`flex items-center justify-center rounded-xl px-2.5 py-1 bg-green-50 text-green-500`}
          >
              <p className="text-sm font-bold"> {total.toFixed()}€ </p>
          </div>
      </div>
  );
}

export default TopCustomerElement;
