import { toast } from "react-toastify";
import { useEffect, useState } from "react";

import Card from "components/card";
import { createDeliveryPerson, getDeliveryPersons, deleteDeliveryPerson } from "interfaces/delivery";
import { getDeliveryTours } from "interfaces/deliveryTour";
import InputField from "components/fields/InputField";
import DeliveryPersonsTable from "./components/DeliveryPersonsTable";
import LoadingTableLine from "./components/loadingTableLine";

const ManageDeliveryPersons = () => {
    const [deliveryPersons, setDeliveryPersons] = useState(null);
    const [newDeliveryPerson, setNewDeliveryPerson] = useState<any>({
        authorizedTours: []
    });
    const [deliveryTours, setDeliveryTours] = useState<any>([]);
    const isFullyLoaded = deliveryPersons && deliveryTours;

    const initDeliveryTours = async () => {
        const res = await getDeliveryTours(false);
        if (res) {
            setDeliveryTours(res.data)
            setNewDeliveryPerson({authorizedTours: res.data.map((o: any) => o._id)})
            return;
        }
    };

    const initDeliveryPersons = async () => {
        const res = await getDeliveryPersons();
        if (res) setDeliveryPersons(res);
    };

    const handleChange = (e: any) => {
        setNewDeliveryPerson((prevDeliveryPerson: any) => ({
            ...prevDeliveryPerson,
            [e.target.id]: e.target.value,
        }));
    };

    const addDeliveryPerson = async () => {
        const res = await createDeliveryPerson(newDeliveryPerson);
        if (res) {
            toast.success("Livreur ajouté");
            setDeliveryPersons((deliveryPersons: any) => [...deliveryPersons, res])
            setNewDeliveryPerson({
                name: "",
                email: "",
                authorizedTours: deliveryTours.map((o: any) => o._id)}
            )
        }
    };

    const delDeliveryPerson = async (id: string) => {
        const res = await deleteDeliveryPerson(id);
        if (res) {
            toast.success("Livreur supprimé");
            setDeliveryPersons(deliveryPersons.filter((deliveryPerson: any) => deliveryPerson._id !== id))
        }
    };

    useEffect(() => {
        initDeliveryTours();
        initDeliveryPersons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
    <>
        <Card
            extra="mt-2 pt-[10px] pl-[20px] pr-[20px] pb-[20px] border"
        >
            <div className="h-full w-full pt-3">
                <div>
                    <h4 className="text-lg font-medium">
                        Ajouter un livreur
                    </h4>
                    <div className="mt-5 grid grid-cols-1 gap-3 xl:grid-cols-12">
                        <div className="col-span-2">
                            <InputField
                                label="Nom*"
                                placeholder="Nom"
                                id="name"
                                type="text"
                                value={newDeliveryPerson.name}
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-3">
                            <InputField
                                label="Email*"
                                placeholder="nom.prenom@example.com"
                                id="email"
                                type="email"
                                value={newDeliveryPerson.email}
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-3">
                            <button
                                className={`sm:mt-6 linear col-span-3 flex items-center justify-center rounded-xl ${false ? "bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default" : "bg-toola-500 hover:bg-toola-600 active:bg-toola-700"} px-5 py-2 text-xs font-medium text-white`}
                                onClick={() => addDeliveryPerson()}
                            >
                                Ajouter
                            </button>
                        </div>
                    </div>
                    <p className="mt-4 text-base text-gray-600">
                        Mes livreurs
                    </p>
                    {isFullyLoaded ? (
                        deliveryPersons.length > 0 ? (
                            <DeliveryPersonsTable
                                deliveryPersons={deliveryPersons}
                                onDelete={delDeliveryPerson}
                            />
                        ) : (
                            <div className="rounded-xl border bg-white/0 outline-none">
                                <p className="my-6 text-center">
                                    Vous n'avez pas encore défini de livreurs.
                                </p>
                            </div>
                        )
                    ) : (
                        <div>
                             <DeliveryPersonsTable
                                deliveryPersons={[]}
                                onDelete={delDeliveryPerson}
                            />
                            {Array(5).fill(
                                <LoadingTableLine />,
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Card>
    </>);
}

export default ManageDeliveryPersons;