import axios from "axios";
import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getProductions = async () => {
    const res: any = await axios
        .get("/productions?limit=100", config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getSuggestion = async (production: any) => {
    const res: any = await axios
        .post("/productions/suggestion", production, config())
        .catch((err) => {
			errorHandler(err);
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const createProduction = async (production: any) => {
    const res: any = await axios
        .post("/productions/create", production, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const startScheduledProduction = async (data: any) => {
    const res: any = await axios
        .post("/productions/start-prod", data, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const deleteProduction = async (id: string) => {
	const res: any = await axios
		.delete(`/productions/${id}`, config())
		.catch(errorHandler);
	if (res.error) return res;
	return { data: res ? res.data : null, error: null };
}