import { useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

function EventCalendar(props: {
    calendarData: {
        title: string;
        border?: string;
        start: string;
        end: string;
        backgroundColor: string;
        className?: string;
        onClick?: any;
    }[];
    initialDate: string;
}) {
    const { calendarData, initialDate } = props;
    const [date, setDate] = useState<Date>(new Date(initialDate));
    const calendarRef = useRef(null);

    const handleGoToPrevMonth = () => {
        let calendarApi = calendarRef?.current.getApi();
        calendarApi.prev();
        setDate(getActualMonth());
    };

    const handleGoToNextMonth = () => {
        let calendarApi = calendarRef?.current.getApi();
        calendarApi.next();
        setDate(getActualMonth());
    };

    const getActualMonth = () => {
        if (calendarRef.current === null) return;
        let calendarApi = calendarRef?.current.getApi();
        let date = calendarApi.getDate();
        return date;
    };

    const renderEventContent = (eventInfo: any) => {
        return (
            <span
                className="cursor-pointer
            "
                title={eventInfo.event.title}
            >
                {eventInfo.event.title}
            </span>
        );
    };

    return (
        <>
            <div className="my-2 flex justify-center gap-2">
                <MdChevronLeft
                    onClick={handleGoToPrevMonth}
                    className="flex my-auto cursor-pointer"
                />
                <p className="">
                    {date.toLocaleString('default', { month: 'long' })}{' '}
                    {date.getFullYear()}
                </p>
                <MdChevronRight
                    onClick={handleGoToNextMonth}
                    className="flex my-auto cursor-pointer"
                />
            </div>
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                headerToolbar={false}
                ref={calendarRef}
                initialView="dayGridMonth"
                contentHeight="600px"
                events={calendarData}
                initialDate={date}
                editable={false}
                eventContent={renderEventContent}
                height="100%"
            />
        </>
    );
}

export default EventCalendar;
