import Card from "components/card";
import InputField from "components/fields/InputField";
import SearchSelector from "components/fields/SearchSelector";
import Switch from "components/switch";
import { getProducts } from "interfaces/products";
import { useEffect, useState } from "react";
import SubProductList from "./subProductList";

const AssembledProductForm = (props: {
    state: number;
    product: any;
    setState: any;
    handleProductChange: any;
}) => {
    const { state, product, setState, handleProductChange } = props;

    const [products, setProducts] = useState<any>(null);
    const [newProduct, setNewProduct] = useState<any>(product);
    const [selectedSubProduct, setSelectedSubProduct] = useState<any>({
        name: "",
        productId: "",
        _id: "",
        quantity: 1,
    });

    const initProducts = async () => {
        const res = await getProducts();
        if (res) setProducts(res.data);
    };

    const switchSellProduct = () => {
        handleProductChange(
            "category",
            newProduct.category === "hidden" ? "" : "hidden",
        );
    };

    const updateQuantity = (id: string, qty: number) => {
        const newSubProducts = newProduct.subProducts.map((subProduct: any) => {
            if (subProduct.productId === id) {
                subProduct.quantity = qty;
            }
            return subProduct;
        });
        handleProductChange("subProducts", newSubProducts);
    };

    const deleteSubProduct = (id: string) => {
        const newSubProducts = newProduct.subProducts.filter(
            (subProduct: any) => subProduct.productId !== id,
        );
        handleProductChange("subProducts", newSubProducts);
    };

    const addNewSubProduct = () => {
        if (selectedSubProduct.productId !== "") {
            handleProductChange("subProducts", [
                ...newProduct.subProducts,
                selectedSubProduct,
            ]);
            setSelectedSubProduct({
                name: "",
                productId: "",
                _id: "",
                quantity: 1,
            });
        }
    };

    useEffect(() => {
        initProducts();
    }, []);

    useEffect(() => {
        setNewProduct(product);
    }, [product]);

    return (
        <div className="px-3">
            <Card extra={"mt-3 w-full !p-5 border"}>
                {/* inputs */}
                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                    Produit assemblé
                </h4>
                <div className="mt-4 grid grid-cols-12 gap-3">
                    <div className="col-span-4">
                        <InputField
                            label="Nom*"
                            placeholder="Jus d'orange"
                            id="name"
                            type="text"
                            onChange={(e: any) => {
                                handleProductChange("name", e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-4">
                        <InputField
                            label="Réference*"
                            placeholder="JO-00001"
                            value={newProduct.ref}
                            id="ref"
                            type="text"
                            onChange={(e: any) => {
                                handleProductChange("ref", e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-4">
                        <InputField
                            label="Unité*"
                            placeholder="Kg"
                            value={newProduct.unity}
                            id="unity"
                            type="text"
                            onChange={(e: any) => {
                                handleProductChange("unity", e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                </div>
                {/* full width inputs */}

                <div className="mt-2 grid grid-cols-12 gap-3">
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            label={`Prix de vente (€)`}
                            placeholder="15"
                            id="price"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange("price", e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            label="Seuil d'alerte"
                            placeholder={`150 ${newProduct.unity}`}
                            id="alert"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange("alert", e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4 ml-2">
                        <InputField
                            label={`DLC (en jours)`}
                            placeholder="J conservation après production"
                            id="daysOfConservation"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange(
                                    "daysOfConservation",
                                    e.target.value,
                                );
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            label={`Alerte DLC (en jours)`}
                            placeholder="Être averti X jours avant une DLC"
                            id="dlcAlert"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange("dlcAlert", e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4 ml-2">
                        <InputField
                            label="TVA (%)*"
                            placeholder="5.5"
                            id="vat"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange("vat", e.target.value);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div
                        className={`ml-5 col-span-12 lg:col-span-4 mt-2 flex items-center justify-between`}
                    >
                        <label
                            htmlFor={"switch"}
                            className="text-navy-700 text-xs font-medium"
                        >
                            Vente possible*
                            <p className={`mt-3 text-xs text-gray-600`}>
                                Le produit assemblé est disponbile à la vente.
                            </p>
                        </label>
                        <div>
                            <Switch
                                onChange={switchSellProduct}
                                id={"id"}
                                checked={newProduct.category === ""}
                                color="toola"
                            />
                        </div>
                    </div>
                </div>
            </Card>
            <Card extra={"mt-4 col-span-12 w-full !p-5 border"}>
                {/* Header */}
                <div className="h-full w-full">
                    <div>
                        <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                            Composants
                        </h4>
                        {products && (
                            <>
                                <div className="mt-2 grid grid-cols-1 gap-3 xl:grid-cols-12">
                                    <div className="z-50 col-span-3">
                                        <SearchSelector
                                            displayKey="name"
                                            label="Composants*"
                                            options={products}
                                            onSelect={(e: any) => {
                                                setSelectedSubProduct(
                                                    (prev: any) => ({
                                                        ...prev,
                                                        name: e.name,
                                                        unity: e.unity,
                                                        productId: e._id,
                                                        _id: e._id,
                                                    }),
                                                );
                                            }}
                                            inputSmall={true}
                                        />
                                    </div>
                                    <div className="mt-7 md:mt-0 col-span-1">
                                        <InputField
                                            label="Quantité*"
                                            placeholder="1"
                                            id="quantity"
                                            type="number"
                                            value={selectedSubProduct.quantity}
                                            onChange={(e: any) =>
                                                setSelectedSubProduct(
                                                    (prev: any) => ({
                                                        ...prev,
                                                        quantity:
                                                            e.target.value,
                                                    }),
                                                )
                                            }
                                            inputSmall={true}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <button
                                            className={`sm:mt-6 linear col-span-3 flex items-center justify-center rounded-xl ${
                                                false
                                                    ? "bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default"
                                                    : "bg-toola-500 hover:bg-toola-600 active:bg-toola-700"
                                            } px-10 py-2 text-sm font-medium text-white`}
                                            onClick={addNewSubProduct}
                                        >
                                            Ajouter
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        <p className="mt-4 text-base text-gray-600">
                            Liste des composants :
                        </p>
                        <SubProductList
                            product={newProduct}
                            handleQtyChange={updateQuantity}
                            onDelete={deleteSubProduct}
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default AssembledProductForm;
