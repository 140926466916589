import axios from "axios";
import { errorHandler } from "./utils";
import env from "variables/config";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getStock = async () : Promise<{ data: any; error: any; }> => {
    const res: any = await axios.get("/stock", config()).catch(errorHandler);
	if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getStockAlerts = async () : Promise<{ data: any; error: any; }> => {
    const res: any = await axios.get("/stock/alert", config()).catch(errorHandler);
	if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};
