import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import SearchMultipleSelector from "components/fields/SearchMultipleSelector";
import { getCustomers } from "interfaces/customer";
import { getEmailCampaign, updateEmailCampaign } from "interfaces/emailing";
import { useEffect, useState } from "react";
import { GoDotFill } from "react-icons/go";

const weekDays = [
    { en: "monday", fr: "lundi", display: "L" },
    { en: "tuesday", fr: "mardi", display: "M" },
    { en: "wednesday", fr: "mercredi", display: "M" },
    { en: "thursday", fr: "jeudi", display: "J" },
    { en: "friday", fr: "vendredi", display: "V" },
    { en: "saturday", fr: "samedi", display: "S" },
    { en: "sunday", fr: "dimanche", display: "D" },
];

const UpdateReminderModal = (props: {
    isOpen: any;
    onClose: any;
    emailCampaignId: any;
}) => {
    const { isOpen, onClose, emailCampaignId } = props;
    const [customers, setCustomers] = useState<any>([]);
    const [emailCampaign, setEmailCampaign] = useState(null);

    const initEmailCampaign = async () => {
        const res = await getEmailCampaign(emailCampaignId);
        if (res) setEmailCampaign(res);
    }

    const initCustomers = async () => {
        const res = await getCustomers();
        if (res && res.data) {
            const data = res.data.map((customer: any) => {
                return {
                    ...customer,
                    active: emailCampaign?.customers.includes(customer._id),
                };
            });
            setCustomers(data);
        }
    };

    const handleUpdateEmailCampaign = async () => {
        const campaignData = {
            customers: emailCampaign.customers,
            activeDays: emailCampaign.activeDays,
            hour: emailCampaign.hour,
            status: emailCampaign.status,
            alreadySentToday: false,
        };
        const res = await updateEmailCampaign(emailCampaignId, campaignData);
        if (res) {
            setCustomers([]);
            setEmailCampaign(null);
            onClose();
        }
    }

    const handleSelectedCustomers = (customer: any) => {
        const newCustomers = [...customers];
        const cust = newCustomers.find((c: any) => c._id === customer._id);
        cust.active = !cust.active;
        const selectedCustomersIds = newCustomers.filter((c: any) => c.active).map((c: any) => c._id);
        setEmailCampaign({
            ...emailCampaign,
            customers: selectedCustomersIds,
        });
        setCustomers(newCustomers);
    };

    const updateActiveDay = (e: any, day: string) => {
        const checkboxState = e.target.checked;
        const days = emailCampaign.activeDays;

        if (checkboxState) {
            days.push(day);
        } else {
            const index = days.indexOf(day);
            days.splice(index, 1);
        }

        const uniqueDays = Array.from(new Set(days));

        setEmailCampaign({
            ...emailCampaign,
            activeDays: uniqueDays,
        });
    };

    useEffect(() => {
        if (emailCampaign && emailCampaign.customers && !customers.length)
            initCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailCampaign]);

    useEffect(() => {
        if (!emailCampaignId) return;
        initEmailCampaign();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailCampaignId]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="text-lg font-bold">Relances emails</h1>
                        <p className="mt-1 text-sm">
                            {" "}
                            Relancez vos clients avec le lien de la boutique B2B
                        </p>
                        <div className="mt-5 grid grid-cols-2 gap-2">
                            <div className="col-span-2 gap-[3px]">
                                <label
                                    htmlFor={"reminders"}
                                    className={`text-xs font-medium text-navy-700`}
                                >
                                    Statut de la relance
                                </label>
                                {emailCampaign?.status === "active" ? (
                                <div className="ml-2 mt-3 flex">
                                    <p className="text-sm">active</p>
                                    <GoDotFill className="text-green-600 my-auto" />
                                    <button
                                        className="ml-3 text-xs"
                                        onClick={() => setEmailCampaign({ ...emailCampaign, status: "inactive" })}
                                    >
                                        Désactiver
                                    </button>
                                </div>) :
                                (
                                <div className="ml-2 mt-3 flex">
                                    <p className="text-sm">désactivé</p>
                                    <GoDotFill className="text-red-600 my-auto" />
                                    <button
                                        className="ml-3 text-xs"
                                        onClick={() => setEmailCampaign({ ...emailCampaign, status: "active" })}
                                    >
                                        Activer
                                    </button>
                                </div>
                                )}
                            </div>
                            <div className="col-span-1">
                                <label
                                    htmlFor={"reminders"}
                                    className={`text-xs font-medium text-navy-700`}
                                >
                                    Jours de relance
                                </label>
                                <div className="ml-2 mt-3 flex items-center mb-4 gap-3">
                                    {weekDays.map((day, index) => (
                                        <input
                                            key={`${index}-checkbox`}
                                            id={day.en}
                                            type="checkbox"
                                            checked={
                                                emailCampaign?.activeDays.includes(
                                                    day.en,
                                                ) || false
                                            }
                                            onChange={(e) =>
                                                updateActiveDay(e, day.en)
                                            }
                                            className="w-4 h-4 text-toola-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        ></input>
                                    ))}
                                </div>
                                <div className="ml-2 flex items-center text-center gap-5">
                                    {weekDays.map((day, index) => (
                                        <label
                                            key={`${index}-label`}
                                            htmlFor="default-checkbox"
                                            className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            {day.display}
                                        </label>
                                    ))}
                                </div>
                            </div>
                            <div className="ml-10 col-span-1">
                                <label
                                    htmlFor={"remindersHour"}
                                    className={`text-xs font-medium text-navy-700`}
                                >
                                    Heure de relance
                                </label>
                                <div className="flex ml-1 mt-3 w-[85px] text-sm border rounded-xl p-1">
                                    <input
                                        type="time"
                                        id="hour"
                                        name="hour"
                                        value={emailCampaign?.hour}
                                        onChange={(e) =>
                                            setEmailCampaign({
                                                ...emailCampaign,
                                                hour: e.target.value,
                                            })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="z-50 col-span-2">
                                <SearchMultipleSelector
                                    label="Client*"
                                    displayKey="company"
                                    options={customers}
                                    onSelect={handleSelectedCustomers}
                                    inputSmall={true}
                                />
                            </div>
                        </div>
                        <div className="mt-8 flex gap-2 mx-auto">
                            <button
                                onClick={() => {
                                    setCustomers([]);
                                    setEmailCampaign(null);
                                    onClose();
                                }}
                                className="linear h-10 rounded-xl border-2 border-red-400 px-2 text-base font-medium text-red-400 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={handleUpdateEmailCampaign}
                                className="linear h-10 rounded-xl border-2 border-toola-500 px-2 text-base font-medium text-toola-500 transition duration-200 hover:bg-toola-600/5 active:bg-red-toola/5"
                            >
                                Modifier
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default UpdateReminderModal;
