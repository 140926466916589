import Card from "components/card";
import { MdOutlineCall, MdOutlineEdit, MdOutlineEmail } from "react-icons/md";

const CustomerInfoPreviewPU = (props: {customer: any, onPageChange: any}) => {
    const { customer, onPageChange } = props;

    return (
        <Card extra={"w-full !p-5 border col-span-12 sm:col-span-6 relative"}>
            {/* Header */}
            <div
                id="edit-customer"
                className="absolute top-5 right-5 hover:cursor-pointer"
                onClick={() => onPageChange("edit")}
            >
                <MdOutlineEdit />
            </div>
            <div className="flex w-full px-[8px]">
                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                    {customer && customer.displayName}{" "}
                </h4>
                <p className="ml-3 my-auto text-xs">{customer ? `${customer.company}` : " - Fiche client"}</p>
            </div>
            <div className="text-sm sm:flex gap-5 my-auto">
                <div className="mt-2 sm:mt-0 flex gap-2">
                    <MdOutlineCall className="my-auto" size={20} />
                    <div>
                        <p className="font-medium">Numéro de téléphone</p>
                        <p>{customer && customer.phone ? customer.phone : '+33- -- -- -- --'}</p>
                    </div>
                </div>
                <div className="mt-5 sm:mt-0 flex gap-2">
                    <MdOutlineEmail className="my-auto" size={20} />
                    <div>
                        <p className="font-medium">Adresse mail</p>
                        <p className="">{customer?.mail}</p>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default CustomerInfoPreviewPU;