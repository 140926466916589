// import { QrReader } from "react-qr-reader";

const Scanner = () => {
    return (
        <>
            {/* <QrReader
                onResult={(result: any, error) => {
                    if (!!result) {
						window.location.href = `${result?.text}`
                    }

                    if (!!error) {
                        console.info(error);
                    }
                }}
                constraints={{ facingMode: "environment" }}
            /> */}
        </>
    );
};

export default Scanner;
