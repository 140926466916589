import Card from "components/card";
import InputField from "components/fields/InputField";

const CommandPageInfo = (props: {user: any, handleChange: any}) => {
    const { user, handleChange } = props;
    return (
        <Card extra={"w-full mt-3 px-6 py-6 border"}>
            {/* Header */}
            <div className="w-full px-1">
                <h4 className="text-xl font-medium">Informations générales</h4>
            </div>
            {/* inputs */}
            <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
                <div className="col-span-2">
                    <InputField
                        label="Informations de contact"
                        value={user?.contactInformation}
                        placeholder={
                            "+33 6 12 34 56 78 - contact@fournisseur.fr"
                        }
                        id="contactInformation"
                        type="text"
                        onChange={handleChange}
                        inputSmall={true}
                    />
                </div>
                <div className="col-span-2">
                    <InputField
                        label="Annonces (mise en avant via un pop-up)"
                        placeholder={"Aucune livraison possible le 25 décembre"}
                        value={user?.announcements}
                        id="announcements"
                        type="text"
                        onChange={handleChange}
                        inputSmall={true}
                    />
                </div>
            </div>
        </Card>
    );
};

export default CommandPageInfo;
