import React, { useState } from "react";
import Card from "components/card";

import Comment from "./Comment";
import { IoPaperPlane } from "react-icons/io5";
import { addCustomerActivity } from "interfaces/customer";
import { MdComment, MdEmail, MdOutlineCall } from "react-icons/md";

// const columns = columnsDataCheck;
export default function CustomerComments(props: { id: string, comments: any }) {
    const { id, comments } = props;
    const [comment , setComment] = useState("");
    const [activityType, setActivityType] = useState("comment");
    const [typeFilter, setTypeFilter] = useState("all");

    const getPlaceholder = (type: string) => {
        switch (type) {
            case "comment":
                return "Votre commentaire...";
            case "call":
                return "Votre résumé d'appel...";
            case "email":
                return "Votre résumé d'email...";
            default:
                return "Votre commentaire...";
        }
    }

    const getTypeDisplay = (type: string) => {
        switch (type) {
            case "comment":
                return "commentaire";
            case "call":
                return "résumé d'appel";
            case "email":
                return "résumé d'email";
            default:
                return "commentaire";
        }
    }

    const addNewComment = async (id: string, comment: string, type: string) => {
        const res = await addCustomerActivity(id, comment, type);
        if (!res) return;
        setComment("");
        comments.push({
            content: comment,
            type,
            createdAt: new Date().toISOString(),
        });
    };

    return (
        <Card extra={"w-full h-full px-6 pb-6 border"}>
            <div className="flex mt-5 w-full px-[8px] gap-2">
                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                    Activités:
                </h4>
                <div className="ml-3 flex my-auto gap-2">
                    <p
                        className={`my-auto text-gray-400 hover:cursor-pointer ${
                            typeFilter === "all" && "text-toola-500 underline"
                        }`}
                        onClick={() => setTypeFilter("all")}
                    >
                        Tout
                    </p>
                    <MdComment
                        className={`mt-1 text-gray-400 hover:cursor-pointer ${
                            typeFilter === "comment" && "text-toola-500"
                        } `}
                        onClick={() => setTypeFilter("comment")}
                    />
                    <MdOutlineCall
                        className={`mt-1 text-gray-400 hover:cursor-pointer ${
                            typeFilter === "call" && "text-toola-500"
                        }`}
                        onClick={() => setTypeFilter("call")}
                    />
                    <MdEmail
                        className={`mt-1 text-gray-400 hover:cursor-pointer ${
                            typeFilter === "email" && "text-toola-500"
                        }`}
                        onClick={() => setTypeFilter("email")}
                    />
                </div>
            </div>

            <div className="mt-2 max-h-[170px] overflow-y-auto">
                {comments &&
                    comments
                        .filter(
                            (c: any) =>
                                c.type === typeFilter || typeFilter === "all",
                        )
                        .sort(
                            (a: any, b: any) =>
                                new Date(b.createdAt).getTime() -
                                new Date(a.createdAt).getTime(),
                        )
                        .map((comment: any) => (
                            <Comment
                                message={comment.content}
                                time={comment.createdAt}
                                type={comment.type}
                                extra="!bg-lightPrimary w-full rounded-xl shadow-sm"
                                text="text-navy-700"
                                timecolor="text-gray-400 dark:text-white"
                            />
                        ))}
                {comments &&
                    comments.filter(
                        (c: any) =>
                            c.type === typeFilter || typeFilter === "all",
                    ).length === 0 && (
                        <p className="text-center py-[70px] text-sm text-gray-600">
                            Aucun
                            {typeFilter !== "all" &&
                                ` ${getTypeDisplay(typeFilter)}`}
                            {typeFilter === "all" && "e activité"} pour le
                            moment.
                        </p>
                    )}
            </div>
            <div className="flex ml-4 mt-3 gap-2">
                <MdComment
                    className={`hover:cursor-pointer ${
                        activityType === "comment" && "text-toola-500"
                    }`}
                    onClick={() => setActivityType("comment")}
                />
                <MdOutlineCall
                    className={`hover:cursor-pointer ${
                        activityType === "call" && "text-toola-500"
                    }`}
                    onClick={() => setActivityType("call")}
                />
                <MdEmail
                    className={`hover:cursor-pointer ${
                        activityType === "email" && "text-toola-500"
                    }`}
                    onClick={() => setActivityType("email")}
                />
            </div>
            <div
                className="mt-1 flex items-center gap-2"
                onKeyDown={(e) => {
                    console.log(e.key);
                    if (e.key !== "Enter") return;
                    addNewComment(id, comment, activityType);
                }}
            >
                <div className=" flex h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3 dark:bg-navy-700">
                    {/* <p className="text-lightSecondary pl-3 text-xl hover:cursor-pointer">
                        <MdOutlineTagFaces />
                    </p> */}
                    <input
                        placeholder={getPlaceholder(activityType)}
                        className="ml-3 h-full w-full rounded-full bg-lightPrimary px-2 text-sm text-navy-700 outline-none dark:bg-navy-700 dark:text-white"
                        type="text"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <div className="flex items-center gap-4">
                        {/* <p className="text-lightSecondary text-xl hover:cursor-pointer">
                            <MdOutlineAttachment />
                        </p>

                        <p className="text-lightSecondary pr-2 text-xl hover:cursor-pointer">
                            <MdImage />
                        </p> */}
                    </div>
                </div>
                {/* button */}
                <button
                    className="linear flex items-center justify-center rounded-full bg-toola-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-toola-600 active:bg-toola-700"
                    onClick={() => addNewComment(id, comment, activityType)}
                >
                    <IoPaperPlane />
                </button>
            </div>
        </Card>
    );
}
