import axios from 'axios';

import env from 'variables/config';
import { errorHandler } from './utils';

axios.defaults.baseURL = env.apiUrl;

const config = (isExternalPage: boolean) => {
    return {
        headers: {
            Authorization:
                'Bearer ' +
                localStorage.getItem(
                    isExternalPage ? 'delivery-token' : 'token',
                ),
        },
    };
};

export const getPaginatedDeliveries = async (
    page: number,
    limit: number,
    status: string,
    date: string,
    tourId: string = null,
    search: string,
    isExternalPage: boolean,
) => {
    const res: any = await axios
        .get(
            `/deliveries?limit=${limit}&page=${page}&status=${status}&date=${date}&deliveryTourId=${tourId}&search=${search}`,
            config(isExternalPage),
        )
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const downloadDeliveriesSummary = async (date: string) => {
    const fileConfig: any = {
        headers: {
            Authorization: config(false).headers.Authorization,
        },
        responseType: 'arraybuffer',
    };
    const res = await axios
        .get(`/deliveries/summary?date=${date}`, fileConfig)
        .catch((err) => {
            const data = new TextDecoder('utf-8').decode(err.response.data);
            const json = JSON.parse(data);
            errorHandler({ response: { data: { error: json.error } } });
        });
    if (!res || !res.data) return null;
    return new Blob([res.data], { type: 'text/csv' });
};

export const getDelivery = async (
    deliveryId: string,
    isExternalPage: boolean,
) => {
    const res = await axios
        .get(`/deliveries/${deliveryId}`, config(isExternalPage))
        .catch(errorHandler);
    return res.data;
};

export const signDelivery = async (
    data: any,
    deliveryId: string,
    isExternalPage: boolean,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .post(`/deliveries/sign/${deliveryId}`, data, config(isExternalPage))
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const updateDeliveryOrder = async (
    orderId: string,
    deliveryId: string,
    data: any,
    isExternalPage: boolean,
) => {
    const res: any = await axios
        .put(
            `/deliveries/${deliveryId}/order/${orderId}`,
            data,
            config(isExternalPage),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const addCommentToDelivery = async (
    orderId: string,
    comment: string,
    isExternalPage: boolean,
) => {
    const res: any = await axios
        .put(
            `/deliveries/comment/${orderId}`,
            { comment },
            config(isExternalPage),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const startDelivery = async (
    deliveryId: string,
    isExternalPage: boolean,
) => {
    const res = await axios
        .put(`/deliveries/${deliveryId}/starting`, {}, config(isExternalPage))
        .catch(errorHandler);
    return res;
};

export const endDelivery = async (
    deliveryId: string,
    isExternalPage: boolean,
) => {
    const res = await axios
        .put(`/deliveries/${deliveryId}/finished`, {}, config(isExternalPage))
        .catch(errorHandler);
    return res;
};

export const updateDeliveriesOrder = async (
    order: any,
    isExternalPage: boolean,
) => {
    const id = order._id;
    delete order._id;
    const res: any = await axios
        .put(`/orders/${id}`, order, config(isExternalPage))
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const createDeliveryPerson = async (deliveryPerson: any) => {
    const res = await axios
        .post('/user/delivery-persons', deliveryPerson, config(false))
        .catch(errorHandler);
    return res.data;
};

export const getDeliveryPersons = async () => {
    const res: any = await axios
        .get('/user/delivery-persons?limit=500', config(false))
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const updateDeliveryPerson = async (deliveryPerson: any) => {
    const id = deliveryPerson._id;
    delete deliveryPerson._id;
    const res: any = await axios
        .put(`/user/delivery-persons/${id}`, deliveryPerson, config(false))
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const deleteDeliveryPerson = async (id: string) => {
    const res: any = await axios
        .delete(`/user/delivery-persons/${id}`, config(false))
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};
