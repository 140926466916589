import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";

const ProspectToCustomerPopUp = (props: {
    isOpen: any;
    onClose: any;
    onClick: any;
}) => {
    const { isOpen, onClose, onClick} = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="text-lg font-medium">
                            {"Prospect transformé en client"}
                        </h1>
                        <div className="max-w-[400px] items-center gap-2">
                        <p className="mt-2 text-sm">Félicitations, vous avez transformé un prospect en client !</p>
                        <p className="mt-5 text-sm">Pensez à compléter la fiche de votre client pour pouvoir lui partager la page de commande et pouvoir lui envoyer des factures.</p>
                        </div>
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Plus tard
                            </button>
                            <button
                                className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-base font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={onClick}
                            >
                                {"Compléter la fiche client"}
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ProspectToCustomerPopUp;
