import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { useNavigate } from 'react-router-dom';
import Card from '.';
import { useDisclosure } from '@chakra-ui/hooks';
import { claimLooses } from 'interfaces/stockMovement';
import { RiTruckLine } from 'react-icons/ri';

const Alert = (props: {
    icon: any;
    type: string;
    level: string;
    name: string;
    objectType: string;
    extra?: string;
    id: string;
    invId?: string;
    dlc?: string;
    lotNumber?: string;
    alert?: string;
    quantity?: number;
    unity?: string;
    onRefresh?: any;
}) => {
    const {
        icon,
        name,
        extra,
        type,
        level,
        objectType,
        id,
        dlc,
        lotNumber,
        alert,
        quantity,
        unity,
        invId,
        onRefresh,
    } = props;
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const getLineColor = (level: string) => {
        if (level === 'critical') return 'bg-red-500';
        if (level === 'warn') return 'bg-orange-500';
        if (level === 'low') return 'bg-yellow-300';
    };

    const getAlertTitle = (type: string, dlc: string) => {
        let delay = null;
        if (type === 'quantity-alert') return "Seuil d'alerte atteint";
        if (type === 'delivery-issue') return '';
        if (type === 'dlc-alert') {
            delay = Math.ceil(
                (Date.now() - Date.parse(dlc)) / (1000 * 60 * 60 * 24),
            );
            if (delay === 0) return `DLC sera dépassée aujourd'hui`;
            return `DLC dépassée depuis ${delay} jours`;
        }
        if (type === 'dlc-warning') {
            delay = Math.ceil(
                (Date.parse(dlc) - Date.now()) / (1000 * 60 * 60 * 24),
            );
            if (delay === 0) delay = 1;
            return `votre DLC sera dépassée dans ${delay} jours.`;
        }
    };

    const getAlertDescription = (type: string, dlc: string) => {
        if (type === 'quantity-alert')
            return `Vous avez ${quantity} ${unity} en stock. Le seuil d'alerte est de ${alert} ${unity}.`;
        if (type === 'dlc-alert')
            return `Votre lot ${lotNumber} (${quantity} ${unity}) a pour DLC le ${
                dlc ? dlc.split('T')[0] : 'Aucune DLC'
            }. Elle est dépassée depuis ${Math.floor(
                (Date.now() - Date.parse(dlc)) / (1000 * 60 * 60 * 24),
            )} jours.`;
        if (type === 'dlc-warning')
            return `Votre lot ${lotNumber} (${quantity} ${unity}) a pour DLC le ${
                dlc ? dlc.split('T')[0] : 'Aucune DLC'
            }.`;
        if (type === 'delivery-issue')
            return `La commande n°${invId} a rencontré un problème lors de la livraison.`;
    };

    const handleAlertAction = () => {
        if (type === 'quantity-alert')
            navigate(`/update-${objectType}/${id}?action=entry`);
        else if (type === 'dlc-alert') onOpen();
        else if (type === 'dlc-warning') navigate(`/stock/production`);
        else if (type === 'delivery-issue') navigate(`/order/${id}`);
    };

    const handleClaim = async () => {
        const data = {
            id: invId,
        };
        const res = await claimLooses(data);
        if (res.error) return console.log(res.error);
        onRefresh();
        onClose();
    };

    const ConfirmLooseModal = (props: { isOpen: any; onClose: any }) => {
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">
                                Déclarer une perte
                            </h1>
                            <p>{`Retirer ${quantity} ${unity} du lot ${lotNumber} en le déclarant comme perte.`}</p>
                            <div className="mt-5 flex gap-2">
                                <button
                                    onClick={onClose}
                                    className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                    Annuler
                                </button>
                                <button
                                    className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-red-500 px-3 py-3 text-base font-medium text-white active:bg-red-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                    onClick={() => handleClaim()}
                                >
                                    Retirer du stock
                                </button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    };

    return (
        <div
            className={`flex h-[200px] w-full flex-row rounded-[20px] bg-white bg-clip-border p-4 !pb-10 shadow-3xl shadow-shadow-500 ${extra}`}
        >
            <ConfirmLooseModal isOpen={isOpen} onClose={onClose} />
            <div
                className={`h-[110%] w-3 rounded-xl ${getLineColor(level)}`}
            ></div>
            {type === 'delivery-issue' ? (
                <RiTruckLine className="my-auto ml-7 h-[100px] w-[100px] text-toola-500" />
            ) : (
                <img
                    className="my-auto ml-7 h-[100px] w-[100px]"
                    src={icon}
                    alt="icon"
                />
            )}

            <div className="ml-8 w-full justify-between pt-6">
                <div>
                    <div className="flex flex-col 2xl:flex-row">
                        <div>
                            <h2 className="text-[12px] font-bold leading-7 text-navy-700 dark:text-white md:text-[18px] 3xl:text-[28px] 3xl:leading-10">
                                {`${name}: ${getAlertTitle(type, dlc)}`}
                            </h2>
                            <p className="invisible mt-3 text-[0px] text-gray-600 sm:visible sm:text-base lg:text-lg">
                                {getAlertDescription(type, dlc)}
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 md:col-span-3 md:col-start-10 2xl:col-start-11 2xl:col-span-2">
                            <button
                                className="mx-auto w-full items-center justify-center rounded-xl bg-[#0260CB] py-4 font-dm text-xs font-medium text-white transition duration-200 hover:bg-[#01499b] active:bg-[#024086] sm:text-base"
                                onClick={() => handleAlertAction()}
                            >
                                {type === 'dlc-warning' &&
                                    'Programmer une prod'}
                                {type === 'quantity-alert' &&
                                    'Ajouter en stock'}
                                {type === 'dlc-alert' &&
                                    'Pertes, retirer du stock'}
                                {type === 'delivery-issue' &&
                                    'Voir la commande'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alert;
