import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import Checkbox from 'components/checkbox';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import env from 'variables/config';
import logoToola from 'assets/img/logo/toola.png';

function SignInDefault() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const login = () => {
        axios
            .post(`${env.apiUrl}/auth/login`, {
                email: email.toLocaleLowerCase(),
                password: password,
            })
            .then((response: any) => {
                console.log(response);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('name', response.data.fullName);
                localStorage.setItem(
                    'permissions',
                    JSON.stringify(response.data.permissions),
                );
                if (response.data.ownerId)
                    localStorage.setItem('ownerId', response.data.ownerId);
                else localStorage.removeItem('ownerId');
                navigate('/dashboard');
            })
            .catch((error: any) => {
                console.log(error);
                toast.error('Email ou mot de passe incorrect');
            });
    };

    return (
        <Default
            maincard={
                <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0">
                    {/* Sign in section */}
                    <div className="w-full max-w-full flex-col items-center px-4 lg:px-0 lg:pl-0 xl:max-w-[420px]">
                        <img
                            alt="logo toola"
                            className="h-10 mb-5 lg:hidden"
                            src={logoToola}
                        ></img>
                        <ToastContainer />
                        <h3 className="text-xl lg:text-2xl mb-5 font-medium text-navy-700 dark:text-white">
                            Se connecter
                        </h3>
                        {/* Email */}
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Adresse mail"
                            placeholder="hello@entreprise.fr"
                            id="email"
                            type="text"
                            onChange={(e: any) => setEmail(e.target.value)}
                        />

                        {/* Password */}
                        <InputField
                            variant="auth"
                            extra="mb-3"
                            label="Mot de passe"
                            placeholder="*********"
                            id="password"
                            type="password"
                            onChange={(e: any) => setPassword(e.target.value)}
                        />
                        {/* Checkbox */}
                        <div className="mb-4 flex items-center justify-between px-2">
                            <div className="mt-2 flex items-center">
                                <Checkbox extra="checked:bg-blue-400" />
                                <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                                    Rester connecté
                                </p>
                            </div>
                        </div>
                        <button
                            id="connect-btn"
                            className="linear w-full rounded-xl bg-blue-500 py-2 text-base font-medium text-white transition duration-200 hover:bg-toola-600 active:bg-toola-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            onClick={() => login()}
                        >
                            Je me connecte
                        </button>
                    </div>
                </div>
            }
        />
    );
}

export default SignInDefault;
