import { dateToFrench } from "utils/date";
import { useNavigate } from "react-router-dom";

import Card from "components/card";
import chevron from "../../assets/svg/chevron.svg";

const OrderCard = (props: {
    company: string;
    displayName: string;
    download?: string;
    extra?: string;
    id: string;
    orderId: string;
	deliveryDate: string;
	createdAt: string;
	items: any;
    viewed?: boolean;
}) => {
    const { company, extra, id, deliveryDate, createdAt, items, viewed, displayName } = props;
    const nav = useNavigate();
    return (
        <Card
            extra={`flex flex-col w-full h-[185px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25] ${extra}`}
        >
            <div
                className="h-full w-full hover:cursor-pointer"
                onClick={() => nav(`/order/${id}`)}
            >
                <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                            {" "}
                            {displayName || company}{" "}
                            {!viewed && (
                                <>
                                    <span className="text-white bg-toola-400 px-2 py-1 rounded-xl text-[10px]">
                                        Nouveau
                                    </span>
                                </>
                            )}{" "}
                        </p>
                        <p className="mt-2 mb-2 text-sm text-[#A3AED0] dark:text-white">
                            {/* {orderId ? "#" : ''} {orderId}{" "} */}
                            {`Créée le ${dateToFrench(
                                createdAt,
                            ).toLocaleLowerCase()}.`}
                        </p>
                        {items &&
                            items
                                .slice(0, 2)
                                .map((item: any, index: number) => {
                                    return (
                                        <p
                                            key={"order_" + index}
                                            className="flex gap-0 items-center text-sm text-[#A3AED0] dark:text-white"
                                        >
                                            <img src={chevron} alt="chevron" />{" "}
                                            {item.quantity} {item.unity}{" "}
                                            {item.name}.
                                        </p>
                                    );
                                })}
                        {items && items.length > 2 && (
                            <p className="pl-[24px] text-sm text-[#A3AED0] dark:text-white">
                                + {items.length - 2} autres produits.
                            </p>
                        )}
                    </div>
                </div>
                <div className="absolute bottom-3">
                    <p className="ml-2 text-sm font-bold text-[#0260CB] dark:text-white">
                        Livraison {dateToFrench(deliveryDate.split("T")[0])}
                    </p>
                </div>
            </div>
        </Card>
    );
};

export default OrderCard;
