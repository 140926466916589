import axios from "axios";
import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = (isExternalPage: boolean) => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem(isExternalPage ? "delivery-token" : "token"),
        },
    };
};

export const getDeliveryTours = async (isExternalPage: boolean) => {
    const res: any = await axios.get("/delivery-tours?limit=100", config(isExternalPage)).catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const editDeliveryTour = async (id: string, data: any) => {
    const res = await axios.put(`/delivery-tours/${id}`, data, config(false)).catch(errorHandler);
    return res.data;
};

export const deleteDeliveryTour = async (id: string) => {
    const res = await axios.delete(`/delivery-tours/${id}`, config(false)).catch(errorHandler);
    return res.data;
}

export const createDeliveryTour = async (customer: any) => {
    const res = await axios.post("/delivery-tours", customer, config(false)).catch(errorHandler);
    return res.data;
};
