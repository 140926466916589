import Alert from 'components/card/Course';
import { useEffect, useState } from 'react';
import { getStockAlerts } from 'interfaces/stock';
import { getIcon } from 'utils/icons';
import { useNavigate } from 'react-router-dom';

const Alerts = () => {
    const [toggleState, setToggleState] = useState(1);
    const navigate = useNavigate();
    const [filter, setFilter] = useState('all');
    const [alerts, setAlerts] = useState([]);

    const initStockAlerts = async () => {
        const res = await getStockAlerts();
        if (res.error) return;
        setAlerts(res.data);
        localStorage.setItem('alertsNbr', res.data.length);
        if (res.data.length === 0) navigate('/dashboard');
    };

    useEffect(() => {
        initStockAlerts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-10 sm:mt-3 flex h-full w-full flex-col font-dm md:gap-7 lg:flex-row">
            <div className="h-full w-full rounded-[20px]">
                {/* tabs */}
                <div className="mb-4 mt-2 flex h-full w-full flex-col items-center rounded-xl bg-white/70 pl-[45px] shadow-2xl shadow-white dark:!bg-navy-900 dark:!shadow-none xl:mb-3">
                    <div className="flex w-full justify-start gap-11 overflow-hidden text-3xl">
                        <div
                            className={
                                toggleState === 1
                                    ? ' flex items-center gap-2 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer dark:border-brand-400'
                                    : 'flex items-center gap-2 border-b-[4px] border-white pb-3 hover:cursor-pointer dark:!border-navy-900'
                            }
                            onClick={() => {
                                setFilter('all');
                                setToggleState(1);
                            }}
                        >
                            <h5 className="text-base font-medium text-navy-700 dark:text-white">
                                Toutes
                            </h5>
                            <p className="text-sm font-medium text-gray-600">
                                {alerts.length}
                            </p>
                        </div>

                        <div
                            className={
                                toggleState === 2
                                    ? ' flex items-center gap-2 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer'
                                    : 'flex items-center gap-2 border-b-[4px] border-white pb-3 hover:cursor-pointer dark:!border-navy-900'
                            }
                            onClick={() => {
                                setFilter('quantity-alert');
                                setToggleState(2);
                            }}
                        >
                            <h5 className="text-base font-medium text-navy-700 dark:text-white">
                                Seuil
                            </h5>
                            <p className="text-sm font-medium text-gray-600">
                                {
                                    alerts.filter(
                                        (alert) =>
                                            alert.type === 'quantity-alert',
                                    ).length
                                }
                            </p>
                        </div>

                        <div
                            className={
                                toggleState === 5
                                    ? ' flex items-center gap-2 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer'
                                    : 'flex items-center gap-2 border-b-[4px] border-white pb-3 hover:cursor-pointer dark:!border-navy-900'
                            }
                            onClick={() => {
                                setFilter('dlc');
                                setToggleState(5);
                            }}
                        >
                            <h5 className="text-base font-medium text-navy-700 dark:text-white">
                                DLC
                            </h5>
                            <p className="text-sm font-medium text-gray-600">
                                {
                                    alerts.filter((alert) =>
                                        alert.type.includes('dlc'),
                                    ).length
                                }
                            </p>
                        </div>
                    </div>
                </div>

                {/* course section */}
                {alerts
                    .filter((alert) =>
                        filter === 'all' ? true : alert.type.includes(filter),
                    )
                    .map((alert) => (
                        <Alert
                            extra="mb-5"
                            id={alert.id}
                            invId={alert.invId}
                            level={alert.level}
                            type={alert.type}
                            name={alert.name}
                            lotNumber={alert.lotNumber}
                            dlc={alert.dlc}
                            alert={alert.alert}
                            quantity={alert.quantity}
                            unity={alert.unity}
                            objectType={alert.objectType}
                            icon={getIcon(alert.name, alert.objectType)}
                            onRefresh={() => initStockAlerts()}
                        />
                    ))}
            </div>
        </div>
    );
};

export default Alerts;
