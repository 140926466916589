import Card from "components/card";
import LoadingTableLine from "./loadingTableLine";

const VolumeTable = (props: {data: any}) => {
    const { data } = props;
    console.log(data);

    return (
        <Card
            extra={
                "w-full pb-[10px] pt-[10px] px-[29px] h-fit bg-blue-200 shadow shadow-3xl border"
            }
        >
            <p className="mb-2 text-lg font-bold text-navy-700 dark:text-white">
                Volume par produit sur la période
            </p>
            {data ? (
                data.length > 0 ? (
                    <table className="w-full">
                        <thead>
                            <tr className="text-left h-[24px]">
                                <th
                                    className="pl-[10px] w-[50%] sm:w-[35%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Produit
                                </th>
                                <th
                                    className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Quantité
                                </th>
                                <th
                                    className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Prix total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((data: any) => {
                                return (
                                    <tr className="text-left h-[24px] border-t border-[#E9EDF7] sm:border-none">
                                        <td className="text-xs sm:text-base pl-[10px] text-toola-800 font-bold">
                                            {data.name ? data.name.toLowerCase() : "N/A"}
                                            <div className="sm:hidden font-normal">
                                                {data.sum +
                                                    " " +
                                                    data.unity}
                                                <br />
                                                {data.totalPrice}
                                                €
                                            </div>
                                        </td>
                                        <td className="text-xs sm:text-base hidden sm:table-cell pl-[10px] text-toola-800 font-bold">
                                            {data.sum + " " + data.unity}
                                        </td>
                                        <td className="text-xs sm:text-base hidden sm:table-cell pl-[10px] text-toola-800 font-bold">
                                            {data.totalPrice}{" "}
                                            €
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div className="w-full flex items-center justify-center h-[50px]">
                        <p className="text-gray-700">
                            Aucun produit à livrer pour cette date
                        </p>
                    </div>
                )
            ) : (
                <div>
                    <table className="w-full">
                        <thead>
                            <tr className="text-left h-[24px]">
                                <th
                                    className="pl-[10px] w-[50%] sm:w-[35%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Produit
                                </th>
                                <th
                                    className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Quantité
                                </th>
                                <th
                                    className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7] align-bottom"
                                    rowSpan={2}
                                >
                                    Prix total
                                </th>
                                <th
                                    className="w-[50%] sm:w-[35%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium text-center"
                                    colSpan={3}
                                >
                                    Fluctuations
                                </th>
                            </tr>
                            <tr>
                                <th className="text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7]">
                                    Jour
                                </th>
                                <th className="text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7]">
                                    Semaine
                                </th>
                                <th className="text-xs sm:text-sm text-gray-600 dark:text-white font-medium border-b border-[#E9EDF7]">
                                    Mois
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div className="w-full">
                        {Array(5).fill(<LoadingTableLine />)}
                    </div>
                </div>
            )}
        </Card>
    );
}

export default VolumeTable;