import Card from "components/card";
import { dateToFrenchShort } from "utils/date";
import { MdDownload } from "react-icons/md";

const InvoiceCard = (props: {
    key: number;
    invoice: any;
    download: () => void;
    downloadBL?: () => void;
}) => {
    const {
        invoice,
        key,
        download,
    } = props;
    return (
        <Card
            extra={`flex flex-col w-full h-[145px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25]`}
            key={key}
        >
            <div className="flex flex-col justify-between h-full w-full">
                <div className="flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        <p className="text-base font-bold text-navy-700 dark:text-white">
                            Facture #{invoice.invoiceNumber}
                        </p>
                        <p className="mt-1 text-xs text-navy-700 dark:text-white">
                            Commandé le {dateToFrenchShort(invoice.invoicedAt)}{" "}
                        </p>
                        <p className="mt-2 items-center text-base text-[#A3AED0] dark:text-white">
                            Total HT: {invoice.totalHT} €
                        </p>
                        <p className="mt-2 items-center text-base text-[#A3AED0] dark:text-white">
                            Total TTC: {invoice.totalTTC} €
                        </p>
                    </div>
                    <div className="absolute bottom-3 right-4">
                        {true && (
                            <div
                                className="rounded-full border p-3 bg-gray-50 hover:cursor-pointer"
                                onClick={() => download()}
                            >
                                <div className="flex gap-1 justify-between">
                                    <p className="text-xs">Télécharger</p>
                                    <MdDownload />
                                </div>
                            </div>
                        )}
                        {/* {invoiced && (
                            <div className="mt-2 rounded-full border p-2 bg-gray-50 hover:cursor-pointer">
                                <div className="flex justify-between">
                                    <p className="text-xs">Facture</p>
                                </div>
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default InvoiceCard;
