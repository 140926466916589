/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { FaCircle } from "react-icons/fa";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
// chakra imports

export function SidebarLinks(props: { routes: RoutesType[], fullSidebar?: boolean, onNavClicked: any }) {
  //  Chakra color mode
  let location = useLocation();
  const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
  const ownerId = localStorage.getItem("ownerId");

  const { routes, fullSidebar, onNavClicked } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes: RoutesType[]) => {
      return routes
          .filter((r: any) => r.menu !== false)
          .filter((r: any) => {
              if (r.path && ownerId) {
                  return permissions.includes(
                      `menu${r.path.replace("/", ":")}`,
                  );
              } else {
                  return true;
              }
          })
          .map((route, key) => {
              if (route.collapse) {
                  return (
                      <Accordion
                          allowToggle
                          id={route.path ? route.path : `${key}`}
                          key={key}
                      >
                          <AccordionItem mb="8px" border="none" key={key}>
                              <AccordionButton
                                  display="flex"
                                  _hover={{
                                      bg: "unset",
                                  }}
                                  _focus={{
                                      boxShadow: "none",
                                  }}
                                  borderRadius="8px"
                                  w={{
                                      sm: "100%",
                                      xl: "100%",
                                  }}
                                  px={route.icon ? null : "0px"}
                                  py="0px"
                                  bg={"transparent"}
                                  ms={0}
                                  mb="4px"
                              >
                                  {route.icon ? (
                                      <div
                                          className={`mb-1.5 flex w-full items-center justify-center pl-8 pr-7 step-${route.path.replace(
                                              "/",
                                              "",
                                          )}`}
                                      >
                                          <div>
                                              <div className="align-center my-[10px] flex w-full justify-center">
                                                  <div
                                                      className={`flex items-center justify-center ${
                                                          activeRoute(
                                                              route.path.toLowerCase(),
                                                          )
                                                              ? "text-[#0260CB] dark:text-white"
                                                              : "text-gray-600"
                                                      } ${
                                                          activeRoute(
                                                              route.path.toLowerCase(),
                                                          )
                                                              ? "22px"
                                                              : "26px"
                                                      } mr-3.5 `}
                                                  >
                                                      {route.icon}
                                                  </div>
                                                  <p
                                                      className={`ml-3 mt-[2%] ${
                                                          activeRoute(
                                                              route.path.toLowerCase(),
                                                          )
                                                              ? "text-700 font-medium text-navy-700 dark:text-white"
                                                              : "font-medium text-gray-600"
                                                      } `}
                                                  >
                                                      {route.name}
                                                  </p>
                                              </div>
                                          </div>
                                          <AccordionIcon
                                              ms="auto"
                                              className="!text-gray-600"
                                              transform={
                                                  route.icon
                                                      ? null
                                                      : "translateX(-70%)"
                                              }
                                          />
                                      </div>
                                  ) : (
                                      <div
                                          className={`ml-5 flex w-full items-center pb-0 pl-12 pr-7 pt-0 step-${route.path.replace(
                                              "/",
                                              "",
                                          )}`}
                                      >
                                          <div>
                                              <p
                                                  className={`mr-auto text-sm font-medium ${
                                                      activeRoute(
                                                          route.path.toLowerCase(),
                                                      )
                                                          ? "text-800 text-navy-700 dark:text-white"
                                                          : "text-gray-600"
                                                  } ${
                                                      activeRoute(
                                                          route.path.toLowerCase(),
                                                      )
                                                          ? "22px"
                                                          : "26px"
                                                  }`}
                                              >
                                                  {route.name}
                                              </p>
                                          </div>
                                          <AccordionIcon
                                              ms="auto"
                                              className="!text-gray-600"
                                              transform={null}
                                          />
                                      </div>
                                  )}
                              </AccordionButton>
                              <AccordionPanel
                                  pe={route.icon ? null : "0px"}
                                  py="0px"
                                  ps={route.icon ? null : "8px"}
                              >
                                  <ul>
                                      {
                                          route.icon
                                              ? createLinks(route.items) // for bullet accordion links
                                              : createAccordionLinks(
                                                    route.items,
                                                ) // for non-bullet accordion links
                                      }
                                  </ul>
                              </AccordionPanel>
                          </AccordionItem>
                      </Accordion>
                  );
              } else {
                  return (
                      <Link
                          to={route.layout + route.path}
                          key={key}
                          onClick={onNavClicked}
                      >
                          {route.icon ? (
                              <div>
                                  <div
                                      className={`relative mb-2 flex hover:cursor-pointer step-${route.path.replace(
                                          "/",
                                          "",
                                      )}`}
                                  >
                                      <li
                                          className="my-[10px] flex cursor-pointer px-8"
                                          key={key}
                                      >
                                          <span
                                              className={`mt-0.5 ${
                                                  activeRoute(route.path) ===
                                                  true
                                                      ? "font-bold text-[#0260CB] dark:text-white"
                                                      : "text-gray-600"
                                              }`}
                                              title={
                                                  fullSidebar
                                                      ? null
                                                      : route.name
                                              }
                                          >
                                              {route.icon ? (
                                                  route.icon
                                              ) : (
                                                  <DashIcon />
                                              )}
                                          </span>
                                          <span
                                              id={route.name}
                                              className={`ml-7 mt-[5%] flex text-base ${
                                                  activeRoute(route.path) ===
                                                  true
                                                      ? "font-bold text-[#0260CB] dark:text-white"
                                                      : "text-gray-600"
                                              }`}
                                          >
                                              {fullSidebar && route.name}
                                          </span>
                                      </li>
                                      {activeRoute(route.path) ? (
                                          <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-[#0260CB]" />
                                      ) : null}
                                  </div>
                                  {/* <div className="w-full, mb-0 flex items-center justify-between pl-4">
                  <div>
                    <div>
                      <div
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? "brand-500"
                            : "white"
                        }
                      >
                        {route.icon}
                      </div>
                      <p
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? "text-70"
                            : "secondarygray-600"
                        }
                      >
                        {route.name}
                      </p>
                    </div>
                  </div>
                </div> */}
                              </div>
                          ) : (
                              <div
                                  className={`relative mb-2 flex hover:cursor-pointer step-${route.path.replace(
                                      "/",
                                      "",
                                  )}`}
                              >
                                  <li
                                      className="my-[3px] flex cursor-pointer items-center px-8"
                                      key={key}
                                  >
                                      <span
                                          className={`ml-9 flex text-sm leading-none ${
                                              activeRoute(route.path) === true
                                                  ? "font-medium text-navy-700 dark:text-white"
                                                  : "font-medium text-gray-600"
                                          }`}
                                      >
                                          {fullSidebar && route.name}
                                      </span>
                                  </li>
                              </div>
                          )}
                      </Link>
                  );
              }
          });
  };
  const createAccordionLinks = (routes: RoutesType[]) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          <Link key={index} to={route.layout + route.path} onClick={onNavClicked}>
            <div className="relative mb-1 ml-7 flex hover:cursor-pointer">
              <li
                className="my-[3px] flex cursor-pointer items-center px-8"
                key={index}
              >
                <span className={`text-brand-500 dark:text-white`}>
                  <FaCircle className={`mr-0.5 h-1.5 w-1.5`} />
                </span>
                <span
                  className={`ml-2 flex text-sm ${
                    activeRoute(route.path) === true
                      ? "font-medium text-navy-700 dark:text-white"
                      : "font-medium text-gray-600"
                  }`}
                >
                  {route.name}
                </span>
              </li>
            </div>
          </Link>
        );
      }
    });
  };
  // BRAND
  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
