function TaskDashboard(props: {
    markAsDone: any;
    message: string;
    time: string;
    extra: string;
    text: string;
    type?: string;
    timecolor: string;
}) {
    const { message, time, extra, text, timecolor, markAsDone } = props;
    return (
        <div className={`relative mb-3 dark:!bg-navy-700 ${extra} p-5`}>
            <h1
                className={`text-lg pb-1 text-navy-700 dark:text-white ${text}`}
            >
                {message}
            </h1>
            <div className="flex gap-1">
                <p className={`${timecolor} text-sm font-medium`}>
                    {"Programmé pour le "}
                    {new Date(time).toLocaleDateString("fr-FR")}
                </p>
            </div>
            <div className="absolute top-6 right-4 flex gap-3">
                <button
                    className="bg-toola-400 text-white text-sm py-2 px-3 rounded-xl"
                    onClick={markAsDone}
                >
                    Tâche effectué
                </button>
            </div>
        </div>
    );
}

export default TaskDashboard;