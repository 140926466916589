import { LuChevronsLeft, LuChevronsRight } from 'react-icons/lu';
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useEffect, useState } from "react";

import Select from "components/fields/Select";

const Pagination = (props: {
    page?: number
    label?: string
    type?: string
    itemsPerPage?: any[]
    maxItems: number
    buttons?: boolean
    text?: boolean
    extra?: string
    onChange?: any
    defaultItemsPerPage?: number
}) => {
    const { page = 1, label = "Afficher :", type = "éléments", itemsPerPage = ["10", "25", "100"], maxItems, buttons = true, text = true, extra, onChange, defaultItemsPerPage } = props;
    const [itemsPerPageSelected, setItemsPerPageSelected] = useState(
        defaultItemsPerPage || itemsPerPage[0],
    );
    const [p, setPage] = useState(page);

    const pageChange = (value: any) => {
        if (value < 1) return;
        onChange(value, itemsPerPageSelected);
        setPage(value);
    }

    const handleChange = (e: any) => {
        if (Math.round(maxItems / e.value) < p) {
            onChange(1, e.value);
            setPage(1);
            setItemsPerPageSelected(e.value);
            return;
        }
        onChange(p, e.value);
        setItemsPerPageSelected(e.value);
    };

    useEffect(() => {
        setItemsPerPageSelected(defaultItemsPerPage || itemsPerPage[0]);
    }, [defaultItemsPerPage, itemsPerPage]);

    //Refresh rendering when props page change
    useEffect(() => {
        setPage(page)
        setItemsPerPageSelected(itemsPerPageSelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <div className={`flex flex-col sm:flex-row items-center justify-center gap-x-[20px] gap-y-[5px] ${extra}`}>
            {/* left side */}
            {buttons && itemsPerPageSelected < maxItems && (
                <div className="flex items-center">
                    {/* button first page */}
                    <button
                        onClick={() => pageChange(1)}
                        disabled={p === 1}
                        className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-[transparent] dark:border-white dark:text-white`}
                    >
                        <LuChevronsLeft />
                    </button>

                    {/* button previous page */}
                    <button
                        onClick={() => pageChange(page - 1)}
                        disabled={p === 1}
                        className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-[transparent] dark:border-white dark:text-white`}
                    >
                        <MdChevronLeft />
                    </button>

                    {/* button current page -2 */}
                    {p -2 >= 1 && (
                        <button
                            onClick={() => pageChange(p - 2)}
                            className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-[transparent] dark:border-white dark:text-white`}
                        >
                            {p - 2}
                        </button>
                    )}

                    {/* button current page -1 */}
                    {p -1 >= 1 && (
                        <button
                            onClick={() => pageChange(p - 1)}
                            className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-[transparent] dark:border-white dark:text-white`}
                        >
                            {p - 1}
                        </button>
                    )}

                    {/* button current page */}
                    <button
                        className={`linear flex h-10 w-10 items-center justify-center rounded-full p-2 text-sm transition duration-200 bg-[#0260CB] text-white dark:bg-brand-400 dark:text-white`}
                        disabled={true}
                    >
                        {p}
                    </button>

                {/* button current page +1 */}
                {p + 1 <= Math.ceil(maxItems / itemsPerPageSelected) && (
                    <button
                        onClick={() => pageChange(p + 1)}
                        className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-[transparent] dark:border-white dark:text-white`}
                    >
                        {p + 1}
                    </button>
                )}

                {/* button current page +2 */}
                {p + 2 <= Math.ceil(maxItems / itemsPerPageSelected) && (
                    <button
                        onClick={() => pageChange(p + 2)}
                        className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-[transparent] dark:border-white dark:text-white`}
                    >
                        {p + 2}
                    </button>
                )}

                {/* button next page */}
                <button
                    onClick={() => pageChange(p + 1)}
                    disabled={p + 1 > Math.ceil(maxItems / itemsPerPageSelected)}
                    className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-[transparent] dark:border-white dark:text-white`}
                >
                    <MdChevronRight />
                </button>

                {/* button last page */}
                <button
                    onClick={() => pageChange(Math.ceil(maxItems / itemsPerPageSelected))}
                    disabled={p === Math.ceil(maxItems / itemsPerPageSelected)}
                    className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-[transparent] dark:border-white dark:text-white`}
                >
                    <LuChevronsRight />
                </button>

                </div>
            )}
            {/* right side */}
            {text && (
                <div className="flex items-center gap-[5px]">
                    <p className="text-sm text-gray-700">
                        {label}
                    </p>
                    <Select
                        extra="!mt-0 pt-[5px] h-[30px] !w-[80px]"
                        options={itemsPerPage.map((index: number) => (
                            { value: index}
                        ))}
                        displayKey="value"
                        onSelect={handleChange}
                        required={false}
                        selected={itemsPerPageSelected}
                    />
                    <p className="text-sm text-gray-700">
                        {type} par page
                    </p>
                    <p className="hidden sm:block text-sm text-gray-700">
                        ({(p - 1) * itemsPerPageSelected + 1 < maxItems && ((p - 1) * itemsPerPageSelected + 1 + ' - ')}
                        {p * itemsPerPageSelected <= maxItems ? p * itemsPerPageSelected : maxItems} sur {maxItems})
                    </p>
                </div>
                )
            }
        </div>
    );
};

export default Pagination;