import { getProduct, getProducts, updateProduct } from "interfaces/products";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Card from "components/card";
import InputField from "components/fields/InputField";
import GoBack from "components/actions/GoBack";
import SearchSelector from "components/fields/SearchSelector";
import SaveStatus from "components/actions/SaveStatus";
import SubProductList from "../createProduct/components/subProductList";

const HandleComp = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [product, setProduct] = useState(null);
    const [saveStatus, setsaveStatus] = useState('none');
    const [selectedSubProduct, setSelectedSubProduct] = useState({
        name: "",
        productId: "",
        quantity: 1,
    });
    const [products, setProducts] = useState(null);

    const initProducts = async () => {
        const res = await getProducts();
        if (!res) return;
        setProducts(res.data);
    }

    const handleSubmit = async () => {
        const newSubProducts = product.subProducts.map((subProduct: any) => {
            return {
                id: subProduct._id,
                quantity: subProduct.quantity,
            };
        });

        const newProduct = {
            name: product.name,
            unity: product.unity,
            alert: Number(product.alert),
            subProducts: newSubProducts,
            _id: product._id,
            ref: product.ref,
            quantity: product.quantity,
            price: product.price,
            daysOfConservation: product.daysOfConservation,
            dlcAlert: product.dlcAlert,
            vat: product.vat
        };
        const res = await updateProduct(newProduct);
        if (res) {
            setsaveStatus('saved');
        } else {
            setsaveStatus('error');
        }
    };

    const initProduct = async (id: string) => {
        const res: any = await getProduct(id);
        if (!res) return;

        let formatedProduct = res;
        console.log(formatedProduct);
        formatedProduct.subProducts = formatedProduct.subProducts.map((subProduct: any) => {
            return {
                ...subProduct.id,
                quantity: subProduct.quantity,
            };});
        setProduct(formatedProduct);
    };

    const updateQuantity = (id: string, quantity: number) => {
        const newProduct = product;
        newProduct.subProducts = newProduct.subProducts.map((subProduct: any) => {
            if (subProduct._id === id) {
                subProduct.quantity = quantity;
            }
            return subProduct;
        });
        setsaveStatus("saving");
        setProduct((prev: any) => ({ ...prev, ...newProduct }));
    }

    const deleteSubProduct = (id: string) => {
        const newProduct = product;
        newProduct.subProducts = newProduct.subProducts.filter((subProduct: any) => subProduct._id !== id);
        setsaveStatus('saving');
        setProduct((prev: any) => ({ ...prev, ...newProduct }));
    }

    const addNewSubProduct = () => {
        const newProduct = product;
        newProduct.subProducts.push(selectedSubProduct);
        setsaveStatus("saving");
        setProduct((prev: any) => ({ ...prev, ...newProduct }));
        setSelectedSubProduct({
            name: "",
            productId: "",
            quantity: 1
        });
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            handleSubmit();
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/auth/login");
        }
        initProduct(params.id);
        initProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-4 sm:mt-0 flex justify-between">
                <GoBack />
                <SaveStatus status={saveStatus} />
            </div>
            <Card extra={"mt-4 col-span-12 w-full !p-5 border"}>
                {/* Header */}
                <div className="h-full w-full">
                    <div>
                        <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                            Composants
                        </h4>
                        {products && (
                            <>
                                <div className="mt-2 grid grid-cols-1 gap-3 xl:grid-cols-12">
                                    <div className="z-50 col-span-3">
                                        <SearchSelector
                                            displayKey="name"
                                            label="Composants*"
                                            options={products}
                                            onSelect={(e: any) => {
                                                setSelectedSubProduct(
                                                    (prev: any) => ({
                                                        ...prev,
                                                        name: e.name,
                                                        unity: e.unity,
                                                        productId: e._id,
                                                        _id: e._id,
                                                    }),
                                                );
                                            }}
                                            inputSmall={true}
                                        />
                                    </div>
                                    <div className="mt-7 md:mt-0 col-span-1">
                                        <InputField
                                            label="Quantité*"
                                            placeholder="1"
                                            id="quantity"
                                            type="number"
                                            value={selectedSubProduct.quantity}
                                            onChange={(e: any) =>
                                                setSelectedSubProduct(
                                                    (prev: any) => ({
                                                        ...prev,
                                                        quantity:
                                                            e.target.value,
                                                    }),
                                                )
                                            }
                                            inputSmall={true}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <button
                                            className={`sm:mt-6 linear col-span-3 flex items-center justify-center rounded-xl ${
                                                false
                                                    ? "bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default"
                                                    : "bg-toola-500 hover:bg-toola-600 active:bg-toola-700"
                                            } px-10 py-2 text-sm font-medium text-white`}
                                            onClick={addNewSubProduct}
                                        >
                                            Ajouter
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        <p className="mt-4 text-base text-gray-600">
                            Liste des composants :
                        </p>
                        {product && (
                            <SubProductList
                                product={product}
                                handleQtyChange={updateQuantity}
                                onDelete={deleteSubProduct}
                            />
                        )}
                    </div>
                </div>
            </Card>
        </>
    );
};

export default HandleComp;
