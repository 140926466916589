import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";

import Card from "components/card";
import { getPreviewInvoices } from "interfaces/invoice";
import { useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import env from "variables/config";

const InvoicePreviewModal = (props: {
    isOpen: any;
    onClose: any;
    invoiceContext: any;
    generateInvoices: any;
}) => {
    const { isOpen, onClose, invoiceContext, generateInvoices } = props;
    const [previewInvoices, setPreviewInvoices] = useState<any[]>(null);
    const [invoiceIndex, setInvoiceIndex] = useState<number>(0);
    const nav = useNavigate();

    const initPreviewInvoicesMetaData = async () => {
        try {
            const data = await getPreviewInvoices(
                invoiceContext.startDate,
                invoiceContext.endDate,
                invoiceContext.selectedCustomer,
                invoiceContext.billingPeriod,
                invoiceContext.invoiceNbr,
            );
            setPreviewInvoices(data.data.previewInvoices);
            setInvoiceIndex(0);
        } catch (error) {
            console.error("Error getting preview metadata:", error);
        }
    };

    useEffect(() => {
        if (isOpen) initPreviewInvoicesMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-50" />
            <ModalContent className="mt-5 z-50">
                <ModalBody>
                    <Card extra="flex !z-50 w-[90%] sm:w-[50%] rounded-xl border mx-auto">
                        <h4 className="mt-5 px-5 text-lg font-medium">
                            Prévisualisation
                        </h4>
                        {previewInvoices && previewInvoices.length > 0 && (
                            <>
                                <p className="mt-1 px-5 text-base text-gray-600">
                                    Vérifiez vos factures avant de les générer
                                    définitivement.
                                </p>
                                <div className="mt-2 flex mx-auto gap-3">
                                    <MdChevronLeft
                                        className="mt-1 hover:cursor-pointer"
                                        onClick={() => {
                                            if (invoiceIndex === 0) return;
                                            setInvoiceIndex(invoiceIndex - 1);
                                        }}
                                    />
                                    <p className="">
                                        {
                                            previewInvoices[invoiceIndex].to
                                                .company
                                        }{" "}
                                        ({invoiceIndex + 1}/
                                        {previewInvoices.length})
                                    </p>
                                    <MdChevronRight
                                        className="mt-1 hover:cursor-pointer"
                                        onClick={() => {
                                            if (
                                                invoiceIndex ===
                                                previewInvoices.length - 1
                                            )
                                                return;
                                            setInvoiceIndex(invoiceIndex + 1);
                                        }}
                                    />
                                </div>
                                <div className="ml-2 justify-center px-8">
                                    <iframe
                                        title="invoice-pdf"
                                        data-image-dimensions="600x780"
                                        src={`${
                                            env.apiUrl
                                        }/invoices/preview/pdf?data=${encodeURIComponent(
                                            JSON.stringify(
                                                previewInvoices[invoiceIndex],
                                            ),
                                        )}#toolbar=0`}
                                        width="100%"
                                        height={450}
                                        className="border rounded-xl bg-white text-white"
                                    />
                                </div>
                            </>
                        )}
                        {previewInvoices && previewInvoices.length === 0 && (
                            <>
                                <p className="mt-4 px-5 text-sm text-gray-600 font-bold">
                                    Impossible de générer une facture:
                                </p>
                                <p className="mt-1 px-5 text-sm text-gray-600">
                                    - Vérifiez que toutes les informations de
                                    facturation sont renseignées
                                </p>
                                <p className="mt-1 px-5 text-sm text-gray-600">
                                    - Vérifiez que la TVA est renseignée sur les
                                    produits liés à cette facture
                                </p>
                                <p className="mt-1 px-5 text-sm text-gray-600">
                                    - Vérifiez qu'une commande correspond bien aux dates et clients sélectionnés
                                </p>
                            </>
                        )}
                        {previewInvoices && previewInvoices.length > 0 ? (
                            <button
                                className="mx-auto my-2 mb-5 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-2 text-sm font-medium text-white active:bg-toola-700"
                                onClick={generateInvoices}
                            >
                                Générer
                                {previewInvoices.length > 1 ? " les " : ""}
                                {` `}
                                {previewInvoices.length}
                                {` `}
                                facture{previewInvoices.length > 1 ? "s" : ""}
                            </button>
                        ) : (
                            <button
                                className="mt-4 mx-auto my-2 mb-5 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-2 text-sm font-medium text-white active:bg-toola-700"
                                onClick={() => nav("/settings")}
                            >
                                Modifier mes informations de facturation
                            </button>
                        )}
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default InvoicePreviewModal;
