import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import axios from 'axios';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import env from "variables/config";
import { isValidEmail, isValidSiren, isValidVATNumber } from 'utils/verification';

const BusinessInfo = () => {
  const [user, setUser] = useState(null);

  const initUser = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${env.apiUrl}/user/me`, config)
      .then(res => {
        const user = res.data;
        setUser(user);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setUser({ ...user, [id]: value });
  };

  const handleCheckboxChange = (e: any) => {
    const { id, checked } = e.target;
    let acceptedPaymentMethods = user?.acceptedPaymentMethods;
    if (checked) {
      acceptedPaymentMethods.push(id);
    } else {
      acceptedPaymentMethods = acceptedPaymentMethods.filter(
        (method: string) => method !== id
      );
    }
    setUser({ ...user, acceptedPaymentMethods });
  }

  const updateProfil = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      billingEmail: user?.billingEmail,
      billingAddress: user?.billingAddress,
      siren: user?.siren,
      activityCode: user?.activityCode,
      vatNumber: user?.vatNumber,
      address: user?.address,
      website: user?.website,
      paymentMethod: user?.paymentMethod,
      legalNotice: user?.legalNotice,
      acceptedPaymentMethods: user?.acceptedPaymentMethods,
    };
    axios
      .put(`${env.apiUrl}/user`, data, config)
      .then(res => {
		toast.success("Votre profil a été modifié avec succès");
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    initUser();
  }, []);

  return (
      <Card extra={"w-full mt-3 px-6 py-6 border"}>
          {/* Header */}
          <div className="w-full px-[8px]">
              <h4 className="text-xl font-medium">Votre entreprise</h4>
              <p className="mt-2 text-base text-gray-400">
                  Ces informations vont figurer sur les factures
              </p>
          </div>
          {/* inputs */}
          <div className="mt-3 grid grid-cols-1 gap-3 md:grid-cols-2">
              <InputField
                  label="Adresse mail"
                  value={user?.billingEmail}
                  placeholder={user?.billingEmail || user?.email}
                  id="billingEmail"
                  type="text"
                  onChange={handleChange}
                  error="incorrect"
                  isInvalid={!isValidEmail(user?.billingEmail)}
                  inputSmall={true}
              />
              <InputField
                  label="SIREN"
                  value={user?.siren}
                  placeholder={user?.siren || "902894317"}
                  id="siren"
                  type="text"
                  onChange={handleChange}
                  error="incorrect"
                  isInvalid={!isValidSiren(user?.siren)}
                  inputSmall={true}
              />
              <InputField
                  label="Code d'activité"
                  value={user?.activityCode}
                  placeholder={user?.activityCode || "6201Z"}
                  id="activityCode"
                  type="text"
                  onChange={handleChange}
                  inputSmall={true}
              />
              <InputField
                  label="Numéro de TVA"
                  value={user?.vatNumber}
                  placeholder={user?.vatNumber || "FR902894317"}
                  id="vatNumber"
                  type="text"
                  onChange={handleChange}
                  error="incorrect"
                  isInvalid={!isValidVATNumber(user?.vatNumber)}
                  inputSmall={true}
              />
              <InputField
                  label="Adresse"
                  value={user?.billingAddress}
                  placeholder={
                      user?.billingAddress || "1 rue de la paix, Paris, France"
                  }
                  id="billingAddress"
                  type="text"
                  onChange={handleChange}
                  inputSmall={true}
              />
              <InputField
                  label="Site internet"
                  value={user?.website}
                  placeholder={user?.website || "toola.eu"}
                  id="website"
                  type="text"
                  onChange={handleChange}
                  error="incorrect"
                  isInvalid={user?.website && !user?.website.includes(".")}
                  inputSmall={true}
              />
              <div>
                  <label
                      htmlFor={"paymentMethodOptions"}
                      className={`text-xs font-medium text-navy-700`}
                  >
                      Moyens de paiement acceptés
                  </label>
                  <div className="mt-2 flex gap-3">
                    {[
                      {display: "Chèque", value: "check"},
                      {display: "Espèce", value: "cash"},
                      {display: "Virement", value: "transfer"},
                      {display: "Prélèvement", value: "direct-debit"},
                    ].map((option, index) => (
                      <div className="flex gap-2">
                          <p className="text-xs mb-1">{option.display}</p>
                          <input
                              key={`checkbox-${index}`}
                              id={option.value}
                              type="checkbox"
                              checked={user?.acceptedPaymentMethods.includes(option.value)}
                              onChange={handleCheckboxChange}
                              className="w-4 h-4 text-toola-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          ></input>
                      </div>
                    ))}
                  </div>
              </div>
              <div className="col-span-2">
                  <InputField
                      label="Informations de paiement"
                      value={user?.paymentMethod}
                      placeholder={user?.paymentMethod || "IBAN: ..., BIC: ..."}
                      id="paymentMethod"
                      type="text"
                      onChange={handleChange}
                      inputSmall={true}
                  />
              </div>
              <div className="col-span-2">
                  <InputField
                      label="Mentions légales"
                      value={user?.legalNotice}
                      placeholder={user?.legalNotice || " "}
                      id="legalNotice"
                      type="text"
                      onChange={handleChange}
                      inputSmall={true}
                  />
              </div>
          </div>
          <div className="mt-3 flex w-full justify-end">
              <button
                  className="linear rounded-xl bg-toola-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-toola-600 active:bg-toola-700 dark:bg-toola-400 dark:text-white dark:hover:bg-toola-300 dark:active:bg-toola-200"
                  onClick={() => updateProfil()}
              >
                  Sauvegarder
              </button>
          </div>
      </Card>
  );
};

export default BusinessInfo;
