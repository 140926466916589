// components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DeliveryCard from "components/card/deliveryCard";
import { getPaginatedDeliveries } from "interfaces/delivery";
import { useSearchParams } from "react-router-dom";
import orderImg from "assets/img/order.png";
import Card from "components/card";
import LoadingCard from "./components/loadingCard";
import SearchIcon from "components/icons/SearchIcon";
import Pagination from "components/pagination/Pagination";
import { getDeliveryTours } from "interfaces/deliveryTour";
import InputField from "components/fields/InputField";
import { getFilter, removeFilter, saveFilter } from "utils/filtersStorage";
import { MdSettings } from "react-icons/md";

const ListDeliveries = (props: {
    isExternalPage: boolean
}) => {
    const nav = useNavigate();
    const [search, setSearch] = useState("");
    const [deliveries, setDeliveries] = useState(null);
    const [deliveriesMeta, setDeliveriesMeta] = useState(null);
    const [page, setPage] = useState(1);
    const [limitPerPage, setLimitPerpage] = useState(8);
    const [toggleState, setToggleState] = useState("pending");
    const [deliveryTours, setDeliveryTours] = useState<any>([]);
    const [selectedTour, setSelectedTour] = useState("");
    const [firstLoad, setFirstLoad] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0])
    const isFullyLoaded = deliveries && deliveryTours && deliveriesMeta;
    const { isExternalPage } = props;

    const changeToogleState = (state: string) => {
        setToggleState(state);
        fetchDeliveries(1, limitPerPage, state, selectedDate, selectedTour);
        saveFilter("deliveries", "state", state);
    };

    const handleDeliveryTourneyChange = (e: any) => {
        setPage(1);
        setSelectedTour(e.target.value);
        fetchDeliveries(1, limitPerPage, toggleState, selectedDate, e.target.value);
        saveFilter("deliveries", "tour", e.target.value);
    };

    const handleDateChange = (e: any) => {
        setPage(1);
        setSelectedDate(e.target.value)
        fetchDeliveries(1, limitPerPage, toggleState, e.target.value, selectedTour);
        saveFilter("deliveries", "date", e.target.value);
    };

    const initDeliveryTours = async () => {
        const res = await getDeliveryTours(isExternalPage);
        if (res) {
            setDeliveryTours(res.data);
            return;
        }
    };

    const fetchDeliveries = async (
        page: number,
        limit: number,
        state: string,
        date:string,
        tourId: string
    ) => {
        setLimitPerpage(limit);
        setDeliveries(null);
        const res = await getPaginatedDeliveries(page, limit, state, date, tourId, search, isExternalPage);
        if (res) {
            setDeliveries(res.data);
            setDeliveriesMeta(res.meta);
            setPage(page);
            setFirstLoad(true);
        } else {
            console.log("error", res);
            if (isExternalPage) {
                localStorage.removeItem("delivery-token");
                nav("/delivery-ext/login");
            } else
                nav("/");
        }
    };

    const getDefaultFilters = () => {
        let date = new Date().toISOString().split("T")[0];
        let state = toggleState;
        let tour = "";

        // f param used if the page load saved filters
        if (searchParams.get("f")) {
            const defaultFilter = getFilter("deliveries");

            if (defaultFilter.tour || defaultFilter.tour === "") {
                setSelectedTour(defaultFilter.tour);
                tour = defaultFilter.tour;
            }

            console.log("DATE:", defaultFilter.date);
            if (defaultFilter.date || defaultFilter.date === "") {
                console.log("DATE2:", defaultFilter.date);
                setSelectedDate(defaultFilter.date);
                date = defaultFilter.date;
            }

            if (defaultFilter.state) {
                state = defaultFilter.state;
                setToggleState(defaultFilter.state);
            }
        } else {
            removeFilter("deliveries");
        }
        return { date, state, tour };
    };

    useEffect(() => {
        const { date, state, tour } = getDefaultFilters();

        initDeliveryTours();
        fetchDeliveries(page, limitPerPage, state, date, tour);
        setSearchParams({ f: "1"});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Delay the last search change
    useEffect(() => {
        if (!firstLoad) return;
        setDeliveries(null);
        setDeliveriesMeta(null);
        const delayDebounceFn = setTimeout(() => {
            fetchDeliveries(1, limitPerPage, toggleState, selectedDate, selectedTour);
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <div>
            <div
                className={`${
                    !isExternalPage && "hidden"
                } p-4 flex items-center gap-4 h-[105px]`}
            >
                <h4 className="text-[28px] text-[#2B3674] font-bold">
                    Livraisons
                </h4>
            </div>
            <Card
                extra={`${
                    isExternalPage ? "mt-0" : "mt-5"
                } sm:mt-0 relative pl-[20px] pr-[20px] pb-[20px] border`}
            >
                {isExternalPage === false && (
                    <div className="hidden sm:block absolute top-0 right-0 mt-4 mr-5">
                        <button
                            className="flex items-center gap-2 text-sm text-gray-700"
                            onClick={() => nav("/settings/deliveries")}
                        >
                            <MdSettings />
                            <span>Options de livraison</span>
                        </button>
                    </div>
                )}
                <div className="grid h-full w-full grid-cols-1 gap-5">
                    <div className="h-full w-full rounded-[20px]">
                        {!isExternalPage && (
                            <div className="w-full flex items-center m-auto">
                                <div className="mt-2 flex w-full gap-[2px] overflow-hidden border-b border-gray-200 justify-center flex-row">
                                    <div
                                        className={
                                            toggleState === "pending"
                                                ? "border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center"
                                                : "pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center"
                                        }
                                        onClick={() => {
                                            changeToogleState("pending");
                                        }}
                                    >
                                        <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[18px]">
                                            <span className="hidden sm:contents">
                                                Tournées en attente
                                            </span>
                                            <span className="contents sm:hidden">
                                                En attente
                                            </span>{" "}
                                            (
                                            {deliveriesMeta
                                                ? deliveriesMeta.pending
                                                : "-"}
                                            )
                                        </p>
                                    </div>

                                    <div
                                        className={
                                            toggleState === "delivered"
                                                ? "border-b-[4px] border-[#0260CB] pb-[8px] pt-[8px] hover:cursor-default dark:border-brand-400 bg-[#7090B0]/[0.05] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center"
                                                : "pb-[11px] pt-[8px] hover:cursor-pointer bg-[#7090B0]/[0.15] flex items-center justify-center gap-2 rounded-t-[15px] w-1/3 sm:w-[250px] h-[40px] text-center"
                                        }
                                        onClick={() => {
                                            changeToogleState("delivered");
                                        }}
                                    >
                                        <p className="text-xs font-medium text-navy-700 dark:text-white md:text-[18px]">
                                            <span className="hidden sm:contents">
                                                Tournées effectuées
                                            </span>
                                            <span className="contents sm:hidden">
                                                Effectuées
                                            </span>{" "}
                                            (
                                            {deliveriesMeta
                                                ? deliveriesMeta.delivered
                                                : "-"}
                                            )
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="mt-0 sm:mt-[20px] flex flex-col sm:flex-row justify-between sm:items-center gap-y-[10px]">
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                                <div className="w-full items-center">
                                    <div className="grid grid-cols-2 gap-0">
                                        <p className="block text-sm text-gray-700">
                                            Journée :
                                        </p>
                                        {selectedDate !== "" &&
                                            !isExternalPage && (
                                                <p
                                                    className="text-xs text-toola-500 hover:underline hover:cursor-pointer"
                                                    onClick={() =>
                                                        handleDateChange({
                                                            target: {
                                                                value: "",
                                                            },
                                                        })
                                                    }
                                                >
                                                    tout afficher
                                                </p>
                                            )}
                                    </div>
                                    <InputField
                                        id="deliveryDate"
                                        inputSmall={true}
                                        type="date"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                    />
                                </div>
                                <div className="items-center">
                                    <p className="block text-sm mb-3 text-gray-700">
                                        Tournée :
                                    </p>
                                    <div
                                        className={`w-full h-[30px] rounded-xl border bg-white border-gray-200 px-2.5 py-1 text-sm outline-none`}
                                    >
                                        <select
                                            className={`mb-0 w-full rounded-xl border-none bg-white text-sm font-medium outline-none`}
                                            name="data"
                                            defaultValue={selectedTour}
                                            onChange={
                                                handleDeliveryTourneyChange
                                            }
                                        >
                                            <option
                                                value=""
                                                selected={selectedTour === ""}
                                            >
                                                Toutes les tournées
                                            </option>
                                            {deliveryTours &&
                                                deliveryTours.map(
                                                    (
                                                        deliveryTour: any,
                                                        i: number,
                                                    ) => {
                                                        return (
                                                            <option
                                                                value={
                                                                    deliveryTour._id
                                                                }
                                                                key={i}
                                                                selected={
                                                                    deliveryTour._id ===
                                                                    selectedTour
                                                                }
                                                            >
                                                                {
                                                                    deliveryTour.name
                                                                }
                                                            </option>
                                                        );
                                                    },
                                                )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 sm:mt-0 flex h-[38px] w-full items-center rounded-xl bg-lightPrimary text-sm text-gray-600 sm:w-[30%]">
                                <SearchIcon />
                                <input
                                    type="text"
                                    placeholder="Rechercher une tournée, ...."
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                                />
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4">
                            {isFullyLoaded ? (
                                deliveriesMeta.pending === 0 &&
                                deliveriesMeta.delivered === 0 ? (
                                    <div className="col-span-12 mt-10">
                                        <img
                                            className="mx-auto flex h-[250px] w-[250px] items-center justify-center"
                                            src={orderImg}
                                            alt=""
                                        />
                                        <h4 className="col-span-12 my-auto mt-3 text-center text-2xl font-medium text-navy-700 dark:text-white">
                                            Aucune livraison prévue
                                        </h4>
                                        <p className="col-span-12 my-auto mt-3 text-center text-base text-navy-700 dark:text-white">
                                            Vous pouvez commencer par créer une
                                            nouvelle commande
                                        </p>
                                        <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                                            <button
                                                className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                                onClick={() =>
                                                    nav("/create-order")
                                                }
                                            >
                                                Créer une page de commande
                                            </button>
                                            <button
                                                className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                                onClick={() =>
                                                    nav("/create-order")
                                                }
                                            >
                                                Ajouter une commande
                                            </button>
                                        </div>
                                    </div>
                                ) : deliveries.length === 0 ? (
                                    <div className="col-span-12">
                                        <h4 className="col-span-12 my-auto mb-3 text-center text-2xl font-medium text-navy-700 dark:text-white">
                                            Aucune tournée{" "}
                                            {toggleState === "pending"
                                                ? "en attente"
                                                : toggleState === "delivered"
                                                  ? "effectuée"
                                                  : "ne pose de problème"}
                                        </h4>
                                    </div>
                                ) : (
                                    deliveries.map(
                                        (delivery: any, index: number) => {
                                            return (
                                                <DeliveryCard
                                                    external={isExternalPage}
                                                    status={toggleState}
                                                    deliveryTourName={
                                                        delivery.name
                                                    }
                                                    date={delivery.deliveryDate}
                                                    displayDate={
                                                        selectedDate === ""
                                                    }
                                                    ordersNbr={
                                                        delivery.ordersNbr
                                                    }
                                                    products={delivery.items}
                                                    customersNbr={
                                                        delivery.customersNbr
                                                    }
                                                    deliveryTourId={
                                                        delivery.deliveryTour
                                                    }
                                                    deliveryId={delivery._id}
                                                    key={index}
                                                />
                                            );
                                        },
                                    )
                                )
                            ) : (
                                Array(limitPerPage)
                                    .fill(0)
                                    .map((delivery: any, index: number) => {
                                        return <LoadingCard key={index} />;
                                    })
                            )}
                        </div>
                    </div>
                </div>
                {/* pagination */}
                {isFullyLoaded && (
                    <Pagination
                        extra="mt-3"
                        text={false}
                        maxItems={deliveriesMeta ? deliveriesMeta.total : 12}
                        itemsPerPage={[limitPerPage]}
                        page={page}
                        onChange={(page: number, limit: number) =>
                            fetchDeliveries(
                                page,
                                limit,
                                toggleState,
                                selectedDate,
                                selectedTour,
                            )
                        }
                    />
                )}
            </Card>
        </div>
    );
};

export default ListDeliveries;
