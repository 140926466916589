const LoadingTableLine = () => {
    return (
        <div className="w-full flex gap-[5px] animate-pulse">
            <div className="w-[50%] sm:w-[35%] pl-[10px]">
                <div className="h-2 sm:h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[65%] sm:w-[50%] my-[7px]"></div>
                <div className="sm:hidden h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[35%] my-[7px]"></div>
                <div className="sm:hidden h-2 bg-[#1B254B]/[0.25] rounded-full dark:bg-gray-700 w-[35%] my-[7px]"></div>
            </div>
            <div className="hidden sm:block w-[15%] pl-[10px]">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[7px]"></div>
            </div>
            <div className="hidden sm:block w-[15%] pl-[10px]">
                <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[50%] my-[7px]"></div>
            </div>
            <div className="w-[50%] sm:w-[35%] flex justify-center items-center">
                <div className="w-[33%] flex justify-center">
                    <div className="h-2 sm:h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[85%] sm:w-[50%] my-[7px]"></div>
                </div>
                <div className="w-[34%] flex justify-center">
                    <div className="h-2 sm:h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[85%] sm:w-[50%] my-[7px]"></div>
                </div>
                <div className="w-[33%] flex justify-center">
                    <div className="h-2 sm:h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[85%] sm:w-[50%] my-[7px]"></div>
                </div>
            </div>
        </div>
    )
}

export default LoadingTableLine;