import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import KanbanBoard from "components/kanbanBoard/KanbanBoard"
import { getProspects, updateCustomer } from "interfaces/customer";
import { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import Card from "components/card";
import CustomerPopUp from "../editCustomer/customerPopUp";
import EditInformationPU from "../editCustomer/components/pop-up/editInformationPU";
import { useNavigate } from "react-router-dom";
import ProspectToCustomerPopUp from "./component/prospectToCustomerPopUp";

const Kanban = () => {
    const [cards, setCards] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenProspect,
        onOpen: onOpenProspect,
        onClose: onCloseProspect
    } = useDisclosure();
    const [selectedCard, setSelectedCard] = useState(null);
    const [lastCardMoved, setLastCardMoved] = useState(null);
    const [popUpState, setPopUpState] = useState('page');
    const nav = useNavigate();

    const fetchCustomers = async () => {
        const res = await getProspects("");
        if (res) {
            const defaultCards: any = [];
            res.data.forEach((card: any, index: number) => {
                defaultCards.push({
                    id: index + 1,
                    columnId: card.status === "prospect" ? "qualified" : card.status,
                    content: card.company,
                    ...card
                })
            })
            setCards(defaultCards)
        }
    };

    const editCustomer = async (customer: any) => {

        const data = {
            _id: customer._id,
            deliveryTourId: customer.deliveryTourId,
            deliveryAddress: customer.deliveryAddress,
            billingAddress: customer.billingAddress,
            billingEmail: customer.billingEmail,
            billingPeriod: customer.billingPeriod,
            contact: customer.contact,
            company: customer.company,
            customerId: customer.customerId,
            mail: customer.mail,
            notes: customer.notes,
            vatNumber: customer.vatNumber,
            siren: customer.siren,
            phone: customer.phone,
            paymentMethod: customer.paymentMethod,
            displayName: customer.displayName,
            tags: customer.tags,
            status: customer.status,
        };
        await updateCustomer(data);
        // if (res.data) setSaveStatus("saved");
        // else setSaveStatus("error");
    };

    const onColumnDrop = (card: any) => {
        card.status = card.columnId;
        setLastCardMoved(card);
        editCustomer(card);
    }

    const onDragEnd = () => {
        console.log("end", lastCardMoved);
        if (lastCardMoved && lastCardMoved.status === "active")
            onOpenProspect();
    }

    useEffect(() => {
        fetchCustomers();
    }, []);

    return (
        <div className="relative">
            <ProspectToCustomerPopUp
                isOpen={isOpenProspect}
                onClose={onCloseProspect}
                onClick={() => {
                    nav(`/edit-customer/${lastCardMoved._id}`);
                    onCloseProspect();
                }}
            />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="top-[22vh] !m-auto !w-max !max-w-[85%] md:top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] sm:ml-[168px] sm:mr-[9px] sm:pt-[35px] pb-[40px] xl:max-w-[80%] xl:max-w-7xl flex !z-[1004] xl:mx-auto max-h-[600px] overflow-y-auto">
                            {popUpState === "page" && (
                                <CustomerPopUp
                                    id={selectedCard}
                                    onPageChange={(page: string) =>
                                        setPopUpState(page)
                                    }
                                />
                            )}
                            {popUpState === "edit" && (
                                <EditInformationPU
                                    id={selectedCard}
                                    onPageChange={(page: string) =>
                                        setPopUpState(page)
                                    }
                                />
                            )}
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {cards && (
                <KanbanBoard
                    cards={cards}
                    onColumnDrop={onColumnDrop}
                    onDragEnded={onDragEnd}
                    onCardClick={(id: string) => {
                        setSelectedCard(id);
                        setPopUpState("page");
                        onOpen();
                    }}
                />
            )}
        </div>
    );
}

export default Kanban;