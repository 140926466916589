import { useDisclosure } from "@chakra-ui/hooks";
import GoBack from "components/actions/GoBack";
import CreateButton from "components/buttons/CreateButton";
import Card from "components/card";
import InputField from "components/fields/InputField";
import SearchIcon from "components/icons/SearchIcon";
import { getCustomer } from "interfaces/customer";
import { createPricings, deletePricing, getPricing, updatePricings } from "interfaces/pricing";
import AddProductsModal from "logicComponent/addProductsModal";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditCustomerPricing = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [customer, setCustomer] = useState<any>({});
    const [pricings, setPricings] = useState<any>([{}]);

    const initCustomer = async (id: string) => {
        const res = await getCustomer(id);
        console.log(res);
        if (res) {
            setCustomer(res);
            return;
        }
        navigate("/customers");
    };

    const editPricing = async () => {
		const pricingToUpdate = pricings.map((item: any) => ({
			_id: item._id,
			price: item.price,
			customerId: item.customerId,
		}));
		const res = await updatePricings({pricings: pricingToUpdate});
		if (res) toast.success("Les prix ont été mis à jour avec succès");
    };

    const removePricing = async (id: string) => {
        const res = await deletePricing(id);
        if (res)
            setPricings((prevPricings: any) =>
                prevPricings.filter((item: any) => item._id !== id)
            );
    };

    const handlePriceChange = (e: any, item: any) => {
        console.log(e.target.value);
        console.log(item);
        const editedPricings = [...pricings].map((p: any) => {
            if (p._id === item._id) return { ...p, price: e.target.value };
            return p;
        });
        setPricings(editedPricings);
    };

    const TableHead = () => {
        return (
            <thead>
                <tr key={0} className="!border-px !border-gray-400">
                    <th
                        key={1}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 pl-7 pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Produit
                        </p>
                    </th>
                    <th
                        key={2}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 pl-7 pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Prix unitaire HT (€)
                        </p>
                    </th>
                    <th
                        key={3}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 pl-7 pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Unité
                        </p>
                    </th>
                    <th
                        key={4}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer border-b border-gray-200 pb-2 pl-7 pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Actions
                        </p>
                    </th>
                </tr>
            </thead>
        );
    };

    const handleNewPricing = async (products: any) => {
		const productsToSent = products.map((item: any) => ({
			_id: item._id,
			price: item.price || 0,
			name: item.name,
			unity: item.unity,
			ref: item.ref,
		}));
        const newPricing = await createPricings({
            customerId: customer._id,
            products: productsToSent,
        });
        if (newPricing)
            setPricings((prevPricings: any) => [
                ...prevPricings,
                ...newPricing,
            ]);
        console.log(newPricing);
        onClose();
    };

    const initPricing = async (id: string) => {
        const res = await getPricing(id);
        if (res) setPricings(res);
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/login");
        }
        initCustomer(params.id);
        initPricing(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-5 grid  grid-cols-12">
            <AddProductsModal
                isOpen={isOpen}
                onClose={onClose}
                addProducts={(p: any) => handleNewPricing(p)}
                existingProducts={pricings.map((item: any) => item.productId)}
            />
            <div className={`col-span-12`}>
                <GoBack text="Fiche client" />
                <Card extra={"w-full !p-5"}>
                    {/* Header */}
                    <div className="w-full px-[8px]">
                        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                            {customer
                                ? `Prix personnalisées - ${customer.company}`
                                : "Prix personnalisées"}
                        </h4>
                        <p className="mt-4 text-base text-gray-600">
                            Vous pouvez proposer des prix personnalisés à{" "}
                            {customer ? customer.company : "Ce client"}.
                        </p>
                    </div>
                    <div className="mt-2 grid grid-cols-12">
                        <div className="col-span-12 xl:col-span-10 2xl:col-span-6">
                            {pricings.length > 0 ? (
                                <div className="">
                                    <div className="ml-2 mt-5 grid grid-cols-12">
                                        <div className="col-span-3">
                                            <CreateButton
                                                extra="flex text-xs py-2 px-1"
                                                value="Ajouter des produits"
                                                onClick={onOpen}
                                            />
                                        </div>
                                        <div className="col-span-6">
                                            <div className="flex h-[38px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600">
                                                <SearchIcon />
                                                <input
                                                    type="text"
                                                    placeholder="Entreprise, mail, téléphone, contact, ...."
                                                    onChange={(e) => {}}
                                                    className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <table className="mt-5 w-full">
                                        <TableHead />
                                        <tbody>
                                            {pricings.map(
                                                (item: any, index: number) => (
                                                    <tr key={`${index}`}>
                                                        <td
                                                            key={0}
                                                            className="mt-[20px] border-b px-[100px] py-[32px] pl-7 text-sm dark:border-white/10"
                                                        >
                                                            <div className="col-span-12">
                                                                <p className="w-full text-base font-bold text-navy-700 dark:text-white">
                                                                    {item.name ||
                                                                        "Produit"}
                                                                </p>
                                                                <p className="font-base mt-[2px] text-gray-600">
                                                                    #{item.ref}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td
                                                            key={1}
                                                            className="min-w-[100px] mt-[20px] border-b px-auto py-[32px] pl-7 text-sm dark:border-white/10"
                                                        >
                                                            <InputField
                                                                id="price"
                                                                value={
                                                                    item.price
                                                                }
                                                                type="number"
                                                                onChange={(
                                                                    e: any,
                                                                ) =>
                                                                    handlePriceChange(
                                                                        e,
                                                                        item,
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td
                                                            key={2}
                                                            className="mt-[20px] border-b px-[100px] py-[32px] pl-7 text-sm dark:border-white/10"
                                                        >
                                                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                                                                {item.unity ||
                                                                    "N/a"}
                                                            </p>
                                                        </td>
                                                        <td
                                                            key={3}
                                                            className="mt-[20px] border-b px-[100px] py-[32px] pl-7 text-sm dark:border-white/10"
                                                        >
                                                            <button
                                                                className="linear col-span-3 flex items-center justify-center rounded-xl bg-red-400 px-3 py-3 text-sm font-medium text-white hover:bg-red-500 active:bg-red-600"
                                                                onClick={() =>
                                                                    removePricing(
                                                                        item._id,
                                                                    )
                                                                }
                                                            >
                                                                Retirer
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="rounded-xl border bg-white/0 outline-none">
                                    <p className="ml-3 mt-6 text-center">
                                        Vous n'avez pas encore défini de prix
                                        personnalisés pour ce client.
                                    </p>
                                    <CreateButton
                                        extra="mt-3 mb-5 align-center mx-auto flex text-xs py-2 px-1"
                                        value="Ajouter un prix personnalisé"
                                        onClick={onOpen}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mt-10 grid grid-cols-12 gap-5">
                        <div className="col-span-3">
                            <button
                                className="flex items-center justify-center rounded-xl bg-[#0260CB] px-5 py-3 text-sm font-medium text-white hover:bg-[#01499b] active:bg-[#024086]"
                                onClick={() => editPricing()}
                            >
                                Sauvegarder
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default EditCustomerPricing;
