import axios from "axios";
import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const createEmailCampaign = async (data: any) => {
    const res: any = await axios.post("/emailing", data, config()).catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getEmailCampaigns= async () => {
    const res: any = await axios.get(`/emailing`, config()).catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getEmailCampaign = async (id: string) => {
    const res: any = await axios.get(`/emailing/${id}`, config()).catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const updateEmailCampaign = async (id: string, data: any) => {
	const res: any = await axios
        .put(`/emailing/${id}`, data, config())
        .catch(errorHandler);
	if (!res) return null;
	return res.data;
}
