import Card from "components/card";

const LoadingCard = () => {
    return (
        <Card
        extra={"flex flex-col w-full h-[185px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25]"}
    >
        <div>
            <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                <div className="mb-2 w-full animate-pulse">
                    <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[100%] mt-2 mb-5"></div>
                    <div className="h-2 bg-[#A3AED0]/[0.75] rounded-full dark:bg-gray-700 w-[50%] mb-5"></div>
                    <div className="h-2 bg-[#A3AED0]/[0.75] rounded-full dark:bg-gray-700 w-[45%] mb-5"></div>
                    <div className="h-2 bg-[#A3AED0]/[0.75] rounded-full dark:bg-gray-700 w-[40%] mb-5"></div>
                    <div className="h-2 bg-[#A3AED0]/[0.75] rounded-full dark:bg-gray-700 w-[40%] mb-5"></div>
                </div>
            </div>
            <div className="absolute bottom-3 w-full animate-pulse">
                <div className="h-2 bg-[#0260CB]/[0.75] rounded-full dark:bg-gray-700 w-[30%]"></div>
            </div>
        </div>
    </Card>
    )
}

export default LoadingCard;