import { useNavigate } from "react-router-dom";

import { dateToFrench } from "utils/date";
import Card from "components/card";

const InvoiceOrderCard = (props: {
	key: number;
    company: string;
    download?: string;
    extra?: string;
    id: string;
    orderId: string;
	createdAt: string;
    deliveryDate: string;
	items: any;
	totalPrice: number;
}) => {
    const { company, extra, id, deliveryDate, items, totalPrice, key } = props;
    const nav = useNavigate();
    return (
        <Card
            extra={`flex flex-col hover:shadow-2xl w-full h-full !p-4 3xl:p-![18px] bg-white ${extra} border`}
			key={key}
        >
            <div
                className="h-full w-full hover:cursor-pointer"
                onClick={() => nav(`/order/${id}`)}
            >
                <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                            {" "}
                            Commande pour {company}
                        </p>
                        <p className="mt-2 mb-2 text-sm text-navy-700 dark:text-white">
                            {/* {orderId ? "#" : ''} {orderId}{" "} */}
                            {`Livré le ${dateToFrench(deliveryDate).toLocaleLowerCase()}.`}
                        </p>
						{items && items.slice(0,2).map((item: any, index: number) => {
							return (
								<p className="text-sm text-navy-700 dark:text-white">
									•  {item.quantity} {item.unity} {item.name}.
								</p>
							)
						})}
						{items && items.length > 2 && <p className="text-sm mb-1 text-navy-700 dark:text-white">+ {items.length - 2} autres articles.</p> }
                    </div>
                </div>
                <div className="absolute bottom-3">
					<p className="ml-2 text-xl font-bold text-[#0260CB] dark:text-white">
                       {totalPrice.toFixed(2).replace(/\.00$/, '')} € HT
                    </p>
                </div>
            </div>
        </Card>
    );
};

export default InvoiceOrderCard;
