import { useEffect, useState } from "react";

// Custom components
function FileSelector(props: {
    description: string;
    logo: string;
    onChange: (e: any) => void;
}) {
    const { description, onChange, logo } = props;

    const [image, setImage] = useState(null);

    useEffect(() => {
        setImage(logo);
    }, [logo]);

    return (
        <div className="flex items-center justify-center w-full">
            {image ? (
                <div>
                    <div>
                        <img src={image} alt="file" className="max-h-64" />
                    </div>
                    <div className="text-center">
                        <p
                            className="text-xs hover:cursor-pointer mx-auto"
                            onClick={() => setImage(null)}
                        >
                            Modifier l'image
                        </p>
                    </div>
                </div>
            ) : (
                <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                            className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                        >
                            <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">Cliquez</span> ou
                            glissez-déposez une image
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                            {description}
                        </p>
                    </div>
                    <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        onChange={(e) => {
                            setImage(URL.createObjectURL(e.target.files[0]));
                            onChange(e);
                        }}
                    />
                </label>
            )}
        </div>
    );
}

export default FileSelector;
