// Assets

import Card from "components/card";
import SearchSelector from "components/fields/SearchSelector";
import { GoDotFill } from "react-icons/go";
import CreateCategory from "./CreateCategory";
import { useDisclosure } from "@chakra-ui/hooks";
import { useEffect, useState } from "react";
import { getMyInfo, updateMyInfo } from "interfaces/user";

const ProductCommandSettings = (props: {product: any, handleChange: any}) => {
  const { product, handleChange } = props;
  const defaultCategories = [
      {
          name: "Aucune catégorie",
          id: "all",
          _id: "all",
      },
      {
          name: "Produit invisible",
          id: "hidden",
          _id: "hidden",
      },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState(null);

  const initUser = async () => {
      const res = await getMyInfo();
      if (res) setUser(res.data);
  };

  const updateCategories = async (newCategory: any) => {
        console.log(newCategory);
        const newCategoryId = newCategory.name.toLowerCase().replace(" ", "-");
        if (user.categories.find((c: any) => c.id === newCategoryId)) return;

        const res = await updateMyInfo({
            categories: [...user.categories, newCategory],
        });
        if (res) {
            setUser({
                ...user,
                categories: [...user.categories, newCategory],
            });
        }
    }

 const sortAlpha = (array: any) => {
    if (!array) return [];

    return array.sort((a: any, b: any) => a.id.localeCompare(b.id));
 }

  useEffect(() => {
      initUser();
  }, []);

  return (
      <Card extra="px-5 py-5 border">
          <CreateCategory
              isOpen={isOpen}
              onClose={onClose}
              onClick={(newCategory: any) => updateCategories(newCategory)}
          />
          <div className="flex justify-between">
              <h4 className="text-lg font-medium">Boutique B2B</h4>
              {product?.category === "hidden" && (
                  <div className="flex gap-1">
                      <p className="text-xs">Produit invisible</p>
                      <GoDotFill className="text-red-600" />
                  </div>
              )}
              {product?.price > 0 && product.category !== "hidden" && (
                  <div className="flex gap-[3px]">
                      <p className="text-xs">Produit visible</p>
                      <GoDotFill className="text-green-600" />
                  </div>
              )}
              {product?.price <= 0 && product.category !== "hidden" && (
                  <div className="flex gap-1">
                      <p className="text-xs">
                          Visible pour les clients avec un prix défini
                      </p>
                      <GoDotFill className="text-toola-500" />
                  </div>
              )}
          </div>
          <div>
              <div className="mt-3 col-span-2">
                  <SearchSelector
                      label="Catégorie"
                      displayKey="name"
                      options={
                          [
                              ...defaultCategories,
                              ...sortAlpha(user?.categories),
                          ] || defaultCategories
                      }
                      value={product?.category}
                      onSelect={(e: any) => {
                          handleChange("category", e.id);
                      }}
                      defaultKey="id"
                      canCreate={true}
                      createNew={onOpen}
                      inputSmall={true}
                  />
              </div>
              <div className="mt-5 col-span-3">
                  <label
                      htmlFor={"description"}
                      className={`text-navy-700 text-xs font-medium`}
                  >
                      Description
                  </label>
                  <textarea
                      id="textInput"
                      className="mt-1 w-full h-[35px] px-4 py-2 border rounded-xl text-sm my-auto focus:outline-none focus:ring focus:border-blue-300 transition-colors duration-300"
                      placeholder="Ajouter ma description ici..."
                      value={product?.description}
                      onChange={(e: any) => {
                          handleChange("description", e.target.value);
                      }}
                  ></textarea>
              </div>
          </div>
      </Card>
  );
};

export default ProductCommandSettings;
