import Card from "components/card";
import chevron from "../../../../assets/svg/chevron.svg"
import { dateToFrenchShort } from "utils/date";
import { MdDownload } from "react-icons/md";

const OrderCard = (props: {
    orderId: string;
    createdAt: string;
    deliveredAt?: string;
    products: any;
    extra?: string;
    key: number;
    status?: string;
    downloadBL?: () => void;
}) => {
    const {
        createdAt,
        orderId,
        deliveredAt,
        products,
        extra,
        key,
        status,
        downloadBL,
    } = props;
    return (
        <Card
            extra={`flex flex-col w-full h-[185px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25] ${extra}`}
            key={key}
        >
            <div className="flex flex-col justify-between h-full w-full">
                <div className="flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                            Bon de livraison #{orderId}
                        </p>
                        <p className="mt-1 text-xs text-navy-700 dark:text-white">
                            Commandé le {dateToFrenchShort(createdAt)},{" "}
                            {status === "delivered"
                                ? "livré le "
                                : "livraison prévue le"}{" "}
                            {dateToFrenchShort(deliveredAt)}
                        </p>
                        <p className="mt-2 items-center text-sm text-[#A3AED0] dark:text-white">
                            Commande contenant:
                        </p>
                        {products &&
                            products
                                .slice(0, 2)
                                .map((item: any, index: number) => {
                                    return (
                                        <p
                                            className="flex gap-0 items-center text-sm text-[#A3AED0] dark:text-white"
                                            key={index}
                                        >
                                            <img src={chevron} alt="chevron" />{" "}
                                            {item.quantity} {item.unity}{" "}
                                            {item.name}.
                                        </p>
                                    );
                                })}
                        {products && products.length > 2 && (
                            <p className="ml-[24px] items-center text-sm text-[#A3AED0] dark:text-white">
                                {`+ ${products.length - 2} autre${
                                    products.length - 2 > 1 ? "s" : ""
                                } produit${
                                    products.length - 2 > 1 ? "s" : ""
                                }.`}
                            </p>
                        )}
                    </div>
                    <div className="absolute bottom-3 right-4">
                        {status === "delivered" && (
                            <div className="rounded-full border p-3 bg-gray-50 hover:cursor-pointer">
                                <div
                                    className="flex gap-1 justify-between"
                                    onClick={downloadBL}
                                >
                                    <p className="text-xs">Télécharger</p>
                                    <MdDownload />
                                </div>
                            </div>
                        )}
                        {/* {invoiced && (
                            <div className="mt-2 rounded-full border p-2 bg-gray-50 hover:cursor-pointer">
                                <div className="flex justify-between">
                                    <p className="text-xs">Facture</p>
                                </div>
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default OrderCard;
