
const SignDisplayCard = (props: { order: any }) => {
    const { order } = props;

    console.log(order);
    return (
        <div
            key={order._id}
            className="mx-[10px] mt-[10px] p-[15px] border rounded-[30px]"
        >
            {order.customer ? (
                <div>
                    <p className="text-lg font-bold text-navy-700 dark:text-white">
                        Commande de{" "}
                        {order.customer.displayName || order.customer.company}
                    </p>
                </div>
            ) : (
                <div className="w-full text-center">Pas d'infos client !</div>
            )}
            <table className="w-full mt-[20px]">
                <thead>
                    <tr>
                        <th className="mt-[20px] w-[60%] px-[10px] text-start text-sm">
                            <p className="text-sm font-bold text-gray-600 dark:text-white">
                                Produit
                            </p>
                        </th>
                        <th className="mt-[20px] w-[40%] px-[10px] text-start text-sm">
                            <p className="text-sm font-bold text-gray-600 dark:text-white">
                                Quantité
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {order.items.map((product: any) => {
                        return (
                            <tr className="border-t border-gray-200 h-[30px]">
                                <td className="px-[10px]">
                                    <p className="font-medium text-sm">
                                        {product.name}
                                    </p>
                                </td>
                                <td className="px-[10px]">
                                    <p className="font-medium text-sm">
                                        {product.quantity} {product.unity}
                                    </p>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default SignDisplayCard;