import CreateButton from "components/buttons/CreateButton";
import { useNavigate } from "react-router-dom";
// Import img from src/assets

const OrderSuccess = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const nav = useNavigate();

    return (
        <div className="h-screen w-full bg-gray-100">
            <div className="mx-auto my-auto flex max-w-screen-xl flex-col items-center pb-24">
                <p className="mt-10 text-[100px]">🥳</p>
                <h2 className="mb-2 mt-10 text-center text-[35px] font-bold text-navy-700 dark:text-white">
                    Votre commande a bien été envoyée
                </h2>
                {id && (
                    <p className="text-center text-navy-700">
                        Commande numéro{" "}
                        <span className="font-bold">#{id}</span>
                    </p>
                )}
                <CreateButton
                    extra="mt-10"
                    value="Passer une nouvelle commande"
                    onClick={() => nav("/command")}
                />
            </div>
        </div>
    );
};

export default OrderSuccess;
