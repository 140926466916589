import Card from "components/card";

import SlackLogo from "assets/img/plugins/slack.svg";
import GoBack from "components/actions/GoBack";
import InputField from "components/fields/InputField";
import SaveStatus from "components/actions/SaveStatus";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getPlugin, updatePlugin } from "interfaces/plugin";

const SlackPlugin = () => {
    const [saveStatus, setSaveStatus] = useState<any>("none");
    const [plugin, setPlugin] = useState<any>({
        service: "slack",
        enabled: true,
        events: ['order.created'],
        variables: { webhook: '' },
    });

    const editPlugin = async () => {
        const res = await updatePlugin("slack", plugin);
        if (res)
            setSaveStatus("saved");
        else
            setSaveStatus("error");
    }


    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            if (plugin?.variables?.webhook?.includes(
                    "https://hooks.slack.com/services/",
                ) || plugin?.variables?.webhook === ''
            ) {
                editPlugin();
            } else {
                setSaveStatus("error");
                toast.error("URL invalide");
            }
        }, 1500);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plugin]);

    const initPlugin = async () => {
        const res = await getPlugin("slack");
        if (res) {
            console.log(res);
            if (res.variables && res.variables.webhook)
                setPlugin({...plugin, variables: res.variables});
        }
    }

    useEffect(() => {
        initPlugin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="">
            <GoBack />
            <Card extra={"relative w-full mt-4 px-2 py-6 border"}>
                {/* Badge */}
                <div className="absolute top-3 right-0">
                    <SaveStatus status={saveStatus} />
                </div>
                {/* Header */}
                <div className="flex w-full px-1">
                    <img
                        src={SlackLogo}
                        alt={`slack-logo`}
                        className="ml-1 w-[70px] h-[70px] border rounded-lg"
                    />
                    <div className="ml-3 mt-2">
                        <h4 className="text-xl font-medium">Slack</h4>
                        <p className="mt-2 text-xs">
                            Recevez des notifications lors de nouvelles
                            commandes.
                        </p>
                    </div>
                </div>
                <InputField
                    id="webhook"
                    label="Webhook URL"
                    placeholder="https://hooks.slack.com/services/..."
                    value={plugin.variables.webhook}
                    extra="mt-4"
                    inputSmall={true}
                    onChange={(e: any) => {
                        setPlugin({ ...plugin, variables: { webhook: e.target.value } })
                        setSaveStatus("saving")
                    }}
                />
            </Card>
        </div>
    );
};

export default SlackPlugin;
