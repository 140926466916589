import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";

const ExportModal = (props: {
    isOpen: any;
    onClose: any;
    handleExport: any;
    archived?: boolean;
}) => {
    const { isOpen, onClose, handleExport } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <p
                            className="absolute text-white/75 top-2 right-2 text-xs cursor-pointer"
                            onClick={() => {
                                handleExport(true);
                            }}
                        >
                            tout exporter
                        </p>
                        <h1 className="mb-[20px] text-2xl font-bold">
                            Export comptable
                        </h1>
                        <p>
                            Cet export vous permet de récupérer les écritures
                            comptables au format:
                        </p>
                        <p className="bg-gray-500 text-white mt-2 rounded-xl mx-auto px-3">
                            fichier;journal;date;écheance;compte;N.facture;Id.facture;montant;type
                        </p>
                        <p className="mt-3 text-sm">
                            💡 Vous pouvez contacter le support si vous
                            souhaitez créer un format d'export personnalisé.
                        </p>
                        <div className="mt-5 flex gap-2">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            <button
                                className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-xs font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={() => {
                                    handleExport(false);
                                }}
                            >
                                Exporter les nouvelles écritures
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ExportModal;
