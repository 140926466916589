import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { useEffect, useState } from "react";

import Card from "components/card";
import CloseModalButton from "components/buttons/CloseModalButton";
import { createStockMovement } from "interfaces/stockMovement";
import InputField from "components/fields/InputField";

const StockEntryModal = (props: {
    isOpen: any;
    onClose: any;
    stock: any;
    onDone: any;
    missingProducts?: any;
}) => {
    const { isOpen, onClose, stock, onDone, missingProducts } = props;
    const [buttonLoading, setButtonLoading] = useState(false);
    const [newMovement, setNewMovement] = useState({
        quantity: 10,
        objectId: "",
        reason: "Achat",
        dlc: null,
        lotNumber: "",
        area: "zone-a",
    });

    const doStockMovement = async () => {
        setButtonLoading(true);
        const data: any = { ...newMovement };
        data.quantity = Number(data.quantity);
        Object.keys(data).forEach((key) => {
            if (data[key] === "") delete data[key];
        });
        const res = await createStockMovement(data);
        if (res.error) {
            console.log(res.error);
            setButtonLoading(false);
            return;
        }
        setNewMovement({
            quantity: 10,
            objectId: "",
            reason: "Achat",
            dlc: null,
            lotNumber: "",
            area: "zone-a",
        });
        onDone();
        onClose();
        setButtonLoading(false);
    };

    const handleChange = (e: any) => {
        const { id, value } = e.target;
        console.log(id, value);

        setNewMovement({ ...newMovement, [id]: value });
    };

    useEffect(() => {
        if (stock && stock.length === 1) {
            setNewMovement({ ...newMovement, objectId: stock[0]._id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stock]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <CloseModalButton 
                            onClick={onClose}
                            extra={"w-[34px] h-[34px]"}
                        />
                        <h1 className="mb-1 text-2xl font-bold">
                            Entrée de stock
                        </h1>
                        <p className="mb-2 ml-1">
                            {stock && stock.length === 1 && stock[0].name}
                        </p>
                        {missingProducts && missingProducts.length > 0 && (
                            <p className="ml-2 text-red-500">Produits manquants: {missingProducts.join(', ').toLowerCase()}.</p>
                        )}
                        <div className="mb-2 mt-3 grid grid-cols-12 gap-3">
                            {stock && stock.length > 1 && (
                                <div className="col-span-12 md:col-span-4">
                                    <label
                                        htmlFor="objectId"
                                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                    >
                                        Produit/Composant*
                                    </label>
                                    <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                        <select
                                            id="objectId"
                                            className="mb-0 w-full bg-white dark:!bg-navy-800"
                                            onChange={handleChange}
                                        >
                                            <option value="0">
                                                {"Sélectionner..."}
                                            </option>
                                            {stock &&
                                                stock.length > 0 &&
                                                stock?.map(
                                                    (item: any, index: any) => (
                                                        <option
                                                            key={index}
                                                            value={item._id}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    )
                                                )}
                                            {(!stock || stock.length === 0) && (
                                                <option value="0">
                                                    {"Aucun produit"}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="col-span-6 md:col-span-4">
                                <label
                                    htmlFor="reason"
                                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                >
                                    Motif*
                                </label>
                                <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                    <select
                                        id="reason"
                                        className="mb-0 w-full bg-white dark:!bg-navy-800"
                                        onChange={handleChange}
                                    >
                                        {["Achat", "Production", "Autre"].map(
                                            (item, index) => (
                                                <option
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>
                            <InputField
                                label="Quantité*"
                                placeholder="10"
                                extra="col-span-6 md:col-span-4"
                                id="quantity"
                                type="number"
                                onChange={(e: any) => handleChange(e)}
                            />
                            <div className="col-span-6 md:col-span-4">
                                <label
                                    htmlFor="reason"
                                    className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                                >
                                    Zone*
                                </label>
                                <div className="mt-2 w-full rounded-xl border border-gray-200 px-2.5 py-3 text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                    <select
                                        id="area"
                                        className="mb-0 w-full bg-white dark:!bg-navy-800"
                                        onChange={handleChange}
                                    >
                                        {[
                                            { v: "zone-a", l: "Zone A" },
                                            { v: "zone-b", l: "Zone B" },
                                            { v: "zone-c", l: "Zone C" },
                                        ].map((item, index) => (
                                            <option key={index} value={item.v}>
                                                {item.l}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <InputField
                                label="DLC*"
                                value={newMovement.dlc}
                                extra="col-span-6 md:col-span-4"
                                id="dlc"
                                type="date"
                                onChange={(e: any) => handleChange(e)}
                            />
                            <InputField
                                label="Numéro de lot*"
                                placeholder="FO-0025"
                                value={newMovement.lotNumber}
                                extra="col-span-6 md:col-span-4"
                                id="lotNumber"
                                type="text"
                                onChange={(e: any) => handleChange(e)}
                            />
                        </div>
                        <div className="mt-3 flex gap-2">
                            <button
                                className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-base font-medium text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={doStockMovement}
                                disabled={buttonLoading}
                            >
                                Ajouter au stock
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default StockEntryModal;
