import axios from "axios";
import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const createPricings = async (data: any) => {
    const res: any = await axios.post("pricing/bulk", data, config()).catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getPricing = async (customerId: string) => {
    const res: any = await axios.get(`pricing/${customerId}`, config()).catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const updatePricings = async (data: any) => {
	const res: any = await axios.put(`pricing/bulk`, data, config()).catch(errorHandler);
	if (!res) return null;
	return res.data;
}

export const deletePricing = async (id: string) => {
	const res: any = await axios.delete(`pricing/${id}`, config()).catch(errorHandler);
	if (!res) return null;
	return res.data;
}
