import { useState, useEffect } from "react";

import CreateButton from "components/buttons/CreateButton";
import { FaHeart } from "react-icons/fa";

function ProductCard(props: {
    name: string;
    img: string;
    unity: string;
    type: string;
    id: string;
    alert: number;
    price: number;
    onAddProduct: any;
    quantity: number;
    remove: any;
    onQuantityChange: any;
    order: any;
    orderedCount: number;
    imageUrl: string;
    onClick: any;
}) {
    const {
        name,
        img,
        unity,
        id,
        price,
        onAddProduct,
        quantity,
        onQuantityChange,
        order,
        orderedCount,
        imageUrl,
        onClick,
    } = props;
    const [toggleState, setToggleState] = useState("normal");
    const [newQuantity, setNewQuantity] = useState(quantity ? quantity : 1);

    const changeToogleState = (state: string) => {
        setToggleState(state);
    };

    const updateQuantity = (qty: any) => {
        qty = parseInt(qty);
        Number.isInteger(qty) ? setNewQuantity(qty) : setNewQuantity(0);
    };

    const handleSubmit = async () => {
        if (!quantity) {
            onAddProduct(id, name, newQuantity);
        } else {
            onQuantityChange(newQuantity, id);
            if (newQuantity === 0) {
                setToggleState("normal");
                setNewQuantity(1);
            }
        }
    };

    const displayName = (name: string): string => {
        if (!name) return "";
        name = name.toLocaleLowerCase();
        name = name[0].toUpperCase() + name.slice(1);
        return name.length > 40 ? name.substring(0, 40) + "..." : name;
    };

    useEffect(() => {
        const exist = order.items.find((i: any) => i.productId === id);

        if (!exist && toggleState === "flipped") {
            setToggleState("normal");
            setNewQuantity(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    return (
        <div
            className={`items-center rounded bg-white py-[10px] shadow-3xl shadow-shadow-500`}
            key={id}
        >
            <div className="grid grid-cols-12 gap-3 relative">
                {orderedCount > 0 && toggleState === "normal" && (
                    <div className="flex absolute -top-1.5 left-2 text-xs font-bold">
                        <FaHeart className="text-pink-500 my-auto" />
                        <span className="px-1 my-auto">Favori</span>
                    </div>
                )}
                {toggleState === "normal" ? (
                    <div
                        className="col-span-12 flex flex-col hover:cursor-pointer mt-3 sm:mt-0"
                        onClick={onClick}
                    >
                        <div className="">
                            <div className="mb-[2px] h-[140px] flex items-center justify-center">
                                <object
                                    data={imageUrl}
                                    type="image/png"
                                    className={`max-h-[140px]  max-w-[180px] rounded-lg`}
                                >
                                    <img
                                        className={`max-h-[100px]  max-w-[140px]`}
                                        src={img}
                                        alt=""
                                    />
                                </object>
                            </div>
                        </div>
                        <h2
                            className={`mt-1 px-[10px] text-sm font-medium leading-6 text-black dark:text-white`}
                        >
                            {displayName(name)}
                        </h2>
                        <span
                            className={`px-[10px] text-sm font-medium leading-6 text-black dark:text-white`}
                        >
                            {price && price.toFixed(2)}
                            {" € / "}
                            {unity}
                        </span>
                    </div>
                ) : (
                    <div className="col-span-12 flex flex-col px-2">
                        <h2
                            className={`text-sm font-medium leading-6 text-[#1B2559] dark:text-white mb-[50px]`}
                        >
                            {displayName(name)}
                        </h2>
                        <div className="flex items-center gap-[6px]">
                            <input
                                id={id}
                                value={newQuantity.toString()}
                                min={0}
                                onChange={(e) => updateQuantity(e.target.value)}
                                type="number"
                                className={`noscroll flex w-[59px] h-[40px] rounded-xl border bg-white/0 px-2 text-sm outline-none`}
                            />
                            <span className="text-gray-600 text-sm">
                                {unity}
                            </span>
                        </div>
                        <div className="mt-5 my-[4px] flex gap-[10px] items-center">
                            <span className="bold text-black font-bold text-sm">
                                Total :
                            </span>
                            <span
                                className={`text-xl font-bold leading-6 text-[#0260CB] dark:text-white`}
                            >
                                {(newQuantity * price).toFixed(2)}
                                {" €"}
                            </span>
                        </div>
                        <CreateButton
                            value={
                                !quantity
                                    ? "Ajouter"
                                    : newQuantity > 0
                                      ? "Modifier"
                                      : "Supprimer"
                            }
                            extra={`mt-[5px] h-[34px] !rounded-full ${
                                (!quantity && newQuantity === 0) ||
                                quantity === newQuantity
                                    ? "bg-[#32383D] hover:bg-[#32383D] active:bg-[#32383D] cursor-default"
                                    : newQuantity === 0 && "bg-[#F87171]"
                            }`}
                            onClick={() => {
                                if ((!quantity && newQuantity > 0) || quantity)
                                    handleSubmit();
                            }}
                        />
                        <div
                            className="mt-5 flex items-center justify-start cursor-pointer"
                            onClick={() => changeToogleState("normal")}
                        >
                            <svg
                                className="w-5 h-5 mr-2"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 19l-7-7 7-7"
                                />
                            </svg>
                            <p className="text-xs">Retour à la fiche</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProductCard;
