import React from "react";
import Card from "components/card";
import { MdSimCardDownload } from "react-icons/md";

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { dateToFrenchShort } from "utils/date";
import { downloadInvoice } from "interfaces/invoice";

type RowObj = {
    name: string;
    status: string;
    date: string;
    progress: number;
    invoiceNumber: string;
    createdAt: string;
    _id: string;
    totalHT: number;
    totalTTC: number;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function LastInvoices(props: { tableData: any }) {
    const { tableData } = props;
    const [sorting, setSorting] = React.useState<SortingState>([]);
    let defaultData = tableData;

    const fetchInvoice = async (id: string) => {
        try {
            const blob = await downloadInvoice(id);
            if (!blob) return;

            const invoice = data.find((invoice: any) => invoice._id === id);
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${invoice?.to.company}-${invoice?.invoiceNumber}.pdf`;
            link.click();
        } catch (error) {
            console.error("Error generating PDF:", error);
        }
    };

    const columns = [
        columnHelper.accessor("invoiceNumber", {
            id: "invoiceNumber",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    N° Facture
                </p>
            ),
            cell: (info) => (
                <p className="text-xs font-bold text-navy-700 dark:text-white">
                    #{info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("createdAt", {
            id: "createdAt",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Date
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-medium text-navy-700 dark:text-white">
                    {dateToFrenchShort(info.getValue())}
                </p>
            ),
        }),
        columnHelper.accessor("totalHT", {
            id: "totalHT",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Total HT
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-medium text-navy-700 dark:text-white">
                    {info.getValue().toFixed(2)} €
                </p>
            ),
        }),
        columnHelper.accessor("totalTTC", {
            id: "totalTTC",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Total TTC
                </p>
            ),
            cell: (info) => (
                <p className="text-sm font-medium text-navy-700 dark:text-white">
                    {info.getValue().toFixed(2)} €
                </p>
            ),
        }),
        columnHelper.accessor("_id", {
            id: "_id",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Actions
                </p>
            ),
            cell: (info) => (
                <div className="flex items-center">
                    <MdSimCardDownload
                        title="Télécharger la facture"
                        className="text-toola-500 h-6 w-6 hover:cursor-pointer hover:text-toola-700"
                        onClick={() => fetchInvoice(info.getValue())}
                    />
                </div>
            ),
        }),
    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => [...defaultData]);
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto border"}>
            <div className="mt-5 w-full px-[8px]">
                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                    Dernières factures
                </h4>
            </div>

            <div className="mt-2 overflow-x-scroll xl:overflow-x-hidden">
                <table className="w-full">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                className="!border-px !border-gray-400"
                            >
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer border-b border-gray-200 pt-4 pb-2 pr-4 text-start dark:border-white/30"
                                        >
                                            <div className="items-center justify-between text-xs text-gray-200">
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                                {{
                                                    asc: "",
                                                    desc: "",
                                                }[
                                                    header.column.getIsSorted() as string
                                                ] ?? null}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table
                            .getRowModel()
                            .rows.slice(0, 5)
                            .map((row) => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={cell.id}
                                                    className="min-w-[90px] border-white/0 py-2 px-2"
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext(),
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                {table.getRowModel().rows.length === 0 && (
                    <div className="flex items-center justify-center h-32">
                        <p className="text-gray-400 dark:text-white">
                            Aucune facture
                        </p>
                    </div>
                )}
            </div>
        </Card>
    );
}
