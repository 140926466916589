import Card from "components/card";
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    deleteIngredient,
    getIngredient,
    updateIngredient,
} from "interfaces/ingredients";
import CreateButton from "components/buttons/CreateButton";
import { useDisclosure } from "@chakra-ui/hooks";
import StockEntryModal from "../stockList/components/StockEntryModal";
import StockExitModal from "../stockList/components/StockExitModal";
import mobilityImg from "assets/img/mobility.png";
import DeleteModal from "components/actions/Delete";

const UpdateIngredient = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [ingredient, setIngredient] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenDelete,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure();
    const {
        isOpen: isOpenExit,
        onOpen: onOpenExit,
        onClose: onCloseExit,
    } = useDisclosure();

    const handleChange = (key: string, value: string) => {
        setIngredient({ ...ingredient, [key]: value });
    };

    const initIngredient = async (id: string) => {
        const res: any = await getIngredient(id);
        if (!res) {
            navigate("/auth/login");
            return;
        }
        setIngredient(res);
    };

    const handleSubmit = async () => {
        const res = await updateIngredient({
            name: ingredient.name,
            unity: ingredient.unity,
            _id: ingredient._id,
            quantity: ingredient.quantity,
            alert: ingredient.alert,
			ref: ingredient.ref,
			dlcAlert: ingredient.dlcAlert,
			price: ingredient.price
        });
        if (res) {
            navigate("/stock/stock-list");
        }
    };

    const refreshData = async () => {
        initIngredient(params.id);
    };

    const delIngredient = async (id: string) => {
        const res = await deleteIngredient(id);
        if (res) {
            navigate("/stock/stock-list");
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/auth/login");
        }
        initIngredient(params.id);
		const queryParameters = new URLSearchParams(window.location.search)
		const page = queryParameters.get("action")
		if (page && page === "entry") {
			onOpen();
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card extra="px-[30px] pt-[35px] pb-[40px] flex flex-col mx-auto">
            <div className="mb-2 mt-3 grid grid-cols-1 gap-3">
                <h1 className="mb-[20px] text-2xl font-bold">
                    {" "}
                    Édition composant
                </h1>
                {/* <div className="col-span-2 col-start-10">
                    <button
                        className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-2 py-2 text-xs text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                        onClick={onOpenDelete}
                    >
                        Supprimer le composant
                    </button>
                </div> */}
            </div>
            <DeleteModal
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                description="Êtes-vous sûr de vouloir supprimer ce composant ?"
                context="composant"
                deleteFunction={() => {
                    delIngredient(ingredient?._id);
                }}
            />
            <StockEntryModal
                onClose={onClose}
                isOpen={isOpen}
                stock={ingredient ? [ingredient] : []}
                onDone={refreshData}
            />
            <StockExitModal
                onClose={onCloseExit}
                isOpen={isOpenExit}
                stock={ingredient ? [ingredient] : []}
                onDone={refreshData}
            />
            <div className="mb-2 mt-3 grid grid-cols-4 gap-3">
                <InputField
                    label="Composant"
                    placeholder="Chargement..."
                    id="name"
                    type="text"
                    value={ingredient?.name}
                    onChange={(e: any) => {
                        handleChange("name", e.target.value);
                    }}
                />
                <InputField
                    label="Unité"
                    placeholder="Chargement..."
                    id="unity"
                    type="text"
                    value={ingredient?.unity}
                    onChange={(e: any) => {
                        handleChange("unity", e.target.value);
                    }}
                />
				<InputField
                    label="Référence"
                    placeholder="Référence"
                    id="ref"
                    type="text"
                    value={ingredient?.ref}
                    onChange={(e: any) => {
                        handleChange("ref", e.target.value);
                    }}
                />
				<InputField
                    label="Prix (€)"
                    placeholder="Prix"
                    id="price"
                    type="number"
                    value={ingredient?.price}
                    onChange={(e: any) => {
                        handleChange("price", e.target.value);
                    }}
                />
                <InputField
                    label="Seuil d'alerte"
                    placeholder="Chargement..."
                    id="name"
                    type="number"
                    value={ingredient?.alert}
                    onChange={(e: any) => {
                        handleChange("alert", e.target.value);
                    }}
                />
				<InputField
                    label="Alerte DLC proche (jours)"
                    placeholder="0"
                    id="dlcAlert"
                    type="number"
                    value={ingredient?.dlcAlert}
                    onChange={(e: any) => {
                        handleChange("dlcAlert", e.target.value);
                    }}
                />
            </div>
            <div className="col-span-2 mt-2">
                <CreateButton
                    value="Modifier le composant"
                    onClick={() => handleSubmit()}
                />
            </div>
            {ingredient &&
                ingredient.inventory &&
                ingredient.inventory.length > 0 && (
                    <div className="mt-12 grid grid-cols-12">
                        <div className="col-span-12 md:col-span-2">
                            <button
                                className="rounded-xl border-2 border-[#0260CB] px-10 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10"
                                onClick={onOpen}
                            >
                                Entrer du stock
                            </button>
                        </div>
                        <div className="col-span-12 mt-2 md:col-span-2 md:mt-0">
                            <button
                                className="rounded-xl border-2 border-[#0260CB] px-10 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10"
                                onClick={onOpenExit}
                            >
                                Sortir du stock
                            </button>
                        </div>
                    </div>
                )}
            <div className="col-span-12 mt-5 grid grid-cols-12 gap-5">
                {["Quantité", "DLC", "Zone", "Lot"].map((item, index) => (
                    <div className={`col-span-3`}>
                        <p className="text-xs font-bold md:text-sm">{item}</p>
                    </div>
                ))}
                {ingredient &&
                    ingredient.inventory &&
                    ingredient.inventory
                        .filter((i: any) => i.quantity !== 0)
                        .map((item: any, index: any) => (
                            <>
                                {[
                                    item.quantity,
                                    item.dlc ? item.dlc.split("T")[0] : "Aucune DLC",
                                    item.area || "zone-a",
                                    item.lotNumber || "Inconnu",
                                ].map((item, index) => (
                                    <div className="col-span-3 mt-4">
                                        <p className="text-xs md:text-sm">
                                            {item}
                                        </p>
                                    </div>
                                ))}
                            </>
                        ))}
                {ingredient &&
                    ingredient.inventory &&
                    ingredient.inventory.filter((i: any) => i.quantity !== 0).length === 0 && (
                        <div className="col-span-12 mt-10">
                            <img
                                className="mx-auto flex h-[120px] w-[120px] items-center justify-center"
                                src={mobilityImg}
                                alt=""
                            />
                            <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                                <button
                                    className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                    onClick={onOpen}
                                >
                                    Entrer du stock
                                </button>
                                <button
                                    className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-11"
                                    onClick={onOpenExit}
                                >
                                    Sortir du stock
                                </button>
                            </div>
                        </div>
                    )}
            </div>
        </Card>
    );
};

export default UpdateIngredient;
