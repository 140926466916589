import * as React from 'react';

declare global {
	namespace JSX {
	  interface IntrinsicElements {
		'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
	  }
	}
  }

const Subscribe = () => {
    return (
        <stripe-pricing-table
            pricing-table-id="prctbl_1OUtriDsmtt7vX9kiFnn9pY7"
            publishable-key="pk_live_51OSnBeDsmtt7vX9khRtw6U4RbPYOmNbxBchagPWiSLu6GqDHgeCNAiZf8TF7w02XDCVLeRIVyEBSO58aFWoRHvAp00JZmfx7JK"
        ></stripe-pricing-table>
    );
};

export default Subscribe;
