
const saveFilter = (filterType: string, field: string, value: string) => {
    const deliveriesFilters = localStorage.getItem(`${filterType}-filters`);

    if (deliveriesFilters) {
        const filters = JSON.parse(deliveriesFilters);
        filters[field] = value;
        localStorage.setItem("deliveries-filters", JSON.stringify(filters));
    } else {
        localStorage.setItem(
            `${filterType}-filters`,
            JSON.stringify({ [field]: value }),
        );
    }
};

const getFilter = (filterType: string) => {
    const deliveriesFilters = localStorage.getItem(`${filterType}-filters`);
    if (deliveriesFilters) {
        const filters = JSON.parse(deliveriesFilters);
        return filters;
    }
    return {};
};

const removeFilter = (filterType: string) => {
    localStorage.removeItem(`${filterType}-filters`);
}

// New version

const fetchFilter = (filterType: string) => {
    const filters = localStorage.getItem(`${filterType}-filters`);
    if (filters) return JSON.parse(filters);
    return null;
};

const setFilter = (filterType: string, filters: any) => {
    const actualFilters = getFilter(filterType);

    if (actualFilters) {
        localStorage.setItem(
            `${filterType}-filters`,
            JSON.stringify({ ...actualFilters, ...filters }),
        );
    } else {
        localStorage.setItem(`${filterType}-filters`, JSON.stringify(filters));
    }
};


export { saveFilter, getFilter, removeFilter, fetchFilter, setFilter};
