const CloseModalButton = (props: {extra?: string, onClick?: any}) => {
	const {extra, onClick} = props;
    return (
        <button
            className={`absolute right-[30px] linear rounded-full border-2 border-red-500 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10 ${extra}`}
                onClick={onClick}>
            X
        </button>
    );
};

export default CloseModalButton;