import Card from 'components/card';
import { MdOutlineCall, MdOutlineEdit, MdOutlineEmail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const CustomerInfoPreview = (props: { customer: any }) => {
    const { customer } = props;
    const navigate = useNavigate();

    return (
        <Card extra={'w-full !p-5 border col-span-12 sm:col-span-6 relative'}>
            {/* Header */}
            <div
                id="edit-customer"
                className="absolute top-5 right-5 hover:cursor-pointer"
                onClick={() => navigate(`/edit-customer/${customer?._id}`)}
            >
                <MdOutlineEdit />
            </div>
            <div className="flex w-full px-[8px]">
                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                    {customer && customer.displayName}{' '}
                </h4>
                <p className="ml-3 my-auto text-xs">
                    {customer ? `${customer.company}` : ' - Fiche client'}
                </p>
            </div>
            <div className="text-sm sm:flex gap-5 my-auto">
                <div className="mt-2 sm:mt-0 flex gap-2">
                    <MdOutlineCall className="my-auto" size={20} />
                    <div>
                        <p className="text-sm font-medium">
                            Numéro de téléphone
                        </p>
                        <p className="text-xs">
                            {customer && customer.phone
                                ? customer.phone
                                : '+33- -- -- -- --'}
                        </p>
                    </div>
                </div>
                <div className="mt-5 sm:mt-0 flex gap-2">
                    <MdOutlineEmail className="my-auto" size={20} />
                    <div>
                        <p className="text-sm font-medium">Adresse mail</p>
                        <p className="text-xs">{customer?.mail}</p>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default CustomerInfoPreview;
