import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { useState } from "react";

export const icons =  [
    {icon: "🍔", name: "Burger"},
    {icon: "🍟", name: "Frites"},
    {icon: "🍕", name: "Pizza"},
    {icon: "🍦", name: "Glace"},
    {icon: "🍩", name: "Donut"},
    {icon: "🍪", name: "Cookie"},
    {icon: "🍫", name: "Chocolat"},
    {icon: "🍬", name: "Bonbon"},
    {icon: "🍭", name: "Sucette"},
    {icon: "🍮", name: "Crème"},
    {icon: "🍯", name: "Miel"},
    {icon: "🍰", name: "Gâteau"},
    {icon: "🍱", name: "Bento"},
    {icon: "🍲", name: "Soupe"},
]

const CreateCategory = (props: {
    isOpen: any;
    onClose: any;
    onClick: any;
}) => {
    const { isOpen, onClose, onClick} =
        props;

    const getCategoryId = (name: string) => {
        return name.toLowerCase().replace(" ", "-");
    };

    const [newCategory, setNewCategory] = useState({
        id: "",
        name: "",
        icon: "none",
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h4 className="text-lg font-bold">
                            Rajouter une nouvelle catégorie
                        </h4>
                        <div className="mt-4">
                            <InputField
                                label="Catégorie"
                                placeholder="Soupe, Pizza, Burger..."
                                id="name"
                                type="text"
                                value={null}
                                onChange={(e: any) => {
                                    setNewCategory({
                                        ...newCategory,
                                        name: e.target.value,
                                        id: getCategoryId(e.target.value),
                                    });
                                }}
                                inputSmall={true}
                            />
                        </div>
                        <label
                            htmlFor="name"
                            className="mt-2 text-xs text-navy-700 font-medium"
                        >
                            Icône
                        </label>
                        <div className="mt-2 grid grid-cols-7 gap-1">
                            {icons.map((icon, index) => (
                                <p
                                    className={`border text-xl rounded mx-auto px-1 cursor-pointer ${
                                        icon.name === newCategory.icon &&
                                        "bg-gray-300"
                                    }`}
                                    onClick={() =>
                                        setNewCategory({
                                            ...newCategory,
                                            icon: icon.name,
                                        })
                                    }
                                >
                                    {icon.icon}
                                </p>
                            ))}
                            <p
                                className={`border text-xs my-auto py-1 rounded mx-auto px-1 cursor-pointer ${
                                    "none" === newCategory.icon &&
                                    "bg-gray-300"
                                }`}
                                onClick={() =>
                                    setNewCategory({
                                        ...newCategory,
                                        icon: "none",
                                    })
                                }
                            >
                                Aucun
                            </p>
                        </div>
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            <button
                                className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-base font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={() => {
                                    onClick(newCategory);
                                    onClose();
                                }}
                            >
                                Rajouter
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateCategory;
