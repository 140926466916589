import { MdConveyorBelt, MdFastfood, MdOutlineShoppingCart, MdPallet, MdPayment } from "react-icons/md";
import logoToola from "assets/img/logo/toola-white.png";

function Default(props: { maincard: JSX.Element }) {
  const { maincard } = props;
  return (
      <div className="relative h-screen w-full grid grid-cols-12 no-scrollbar">
          <div className="hidden md:block col-span-6 bg-gradient-to-r from-blue-500 via-blue-500 to-blue-700 rounded-sm h-full">
              <img
                  alt="logo toola"
                  className="mt-5 ml-7"
                  src={logoToola}
              ></img>
              <p className="mt-[19%] ml-5 text-white text-3xl font-bold px-10">
                  Rejoignez la révolution du B2B
              </p>
              <p className="mt-5 ml-5 text-white text-xl font-semibold px-10">
                  Augmentez votre taux de conversion, améliorez l’expérience
                  d’achat, boostez vos ventes. Améliorez le commerce tout
                  simplement !
              </p>
              {/* Top */}
              <div className="absolute top-[10%] left-[6%] text-blue-300 transform rotate-12 opacity-25">
                  <MdPayment size="60" className="ml-[15%] mt-10" />
              </div>
              <div className="absolute top-[1%] left-[27%] text-blue-300 transform rotate-12 opacity-25">
                  <MdPallet size="60" className="ml-[15%] mt-10" />
              </div>
              <div className="absolute top-[16%] left-[17%] text-blue-300 transform -rotate-6 opacity-25">
                  <MdOutlineShoppingCart size="60" className="ml-[15%] mt-10" />
              </div>
              <div className="absolute top-[20%] left-[38%] text-blue-300 transform -rotate-6 opacity-25">
                  <MdConveyorBelt size="60" className="ml-[15%] mt-10" />
              </div>
              {/* Bottom */}
              <div className="absolute top-[70%] left-[4%] text-blue-300 transform rotate-12 opacity-25">
                  <MdOutlineShoppingCart size="60" className="ml-[15%] mt-10" />
              </div>
              <div className="absolute top-[75%] left-[25%] text-blue-300 transform rotate-12 opacity-25">
                  <MdConveyorBelt size="60" className="ml-[15%] mt-10" />
              </div>
              <div className="absolute top-[48%] left-[30%] text-blue-300 transform rotate-12 opacity-25">
                  <MdFastfood size="60" className="ml-[15%] mt-10" />
              </div>
              <div className="absolute top-[55%] left-[14%] text-blue-300 transform -rotate-6 opacity-25">
                  <MdPallet size="60" className="ml-[15%] mt-10" />
              </div>
              <div className="absolute top-[60%] left-[38%] text-blue-300 transform -rotate-6 opacity-25">
                  <MdPayment size="60" className="ml-[15%] mt-10" />
              </div>
              <p className="ml-2 absolute bottom-1 text-white text-sm">
                  ©{new Date().getFullYear()} Toola. All Rights Reserved.{" "}
              </p>
          </div>
          <div className="col-span-12 md:col-span-6 md:ml-10">{maincard}</div>
      </div>
  );
}

export default Default;
