import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMemo, useState } from "react";
import TaskCard from "./TaskCard";
import { IoMdTrash } from "react-icons/io";
import { useNavigate } from "react-router-dom";

interface Props {
    column: any;
    deleteColumn: (id: any) => void;
    onCardClick?: (id: any) => void;
    updateColumn: (id: any, title: string) => void;

    createTask: (columnId: any) => void;
    updateTask: (id: any, content: string) => void;
    deleteTask: (id: any) => void;
    tasks: any[];
}

function ColumnContainer({
    column,
    deleteColumn,
    updateColumn,
    createTask,
    onCardClick,
    tasks,
    deleteTask,
    updateTask,
}: Props) {
    const [editMode, setEditMode] = useState(false);
    const nav = useNavigate();

    const tasksIds = useMemo(() => {
        return tasks.map((task) => task.id);
    }, [tasks]);

    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: column.id,
        data: {
            type: "Column",
            column,
        },
        disabled: editMode,
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };

    if (isDragging) {
        return (
            <div
                ref={setNodeRef}
                style={style}
                className="
      bg-columnBackgroundColor
      opacity-60
      border-2
      border-toola-500
      w-[250px]
      rounded-md
      flex
      flex-col
      "
            ></div>
        );
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="
  bg-[#f0f3f4]
  w-[250px]
  h-[550px]
  rounded-2xl
  flex
  flex-col
  "
        >
            {/* Column title */}
            <div
                {...attributes}
                {...listeners}
                onClick={() => {
                    setEditMode(true);
                }}
                className="
      bg-mainBackgroundColor
      text-base
      cursor-grab
      rounded-2xl
      rounded-b-none
      px-3
      pt-3
      font-bold
      bg-[#f0f3f4]
      flex
      items-center
      justify-between
      "
            >
                <div className="flex gap-2">
                    <p className="text-base font-medium">
                        {" "}
                        {!editMode && column.title}{" "}
                    </p>
                    {editMode && (
                        <input
                            className="bg-black focus:border-rose-500 border rounded outline-none px-2"
                            value={column.title}
                            onChange={(e) =>
                                updateColumn(column.id, e.target.value)
                            }
                            autoFocus
                            onBlur={() => {
                                setEditMode(false);
                            }}
                            onKeyDown={(e) => {
                                if (e.key !== "Enter") return;
                                setEditMode(false);
                            }}
                        />
                    )}
                </div>
                {!["active", "qualified"].includes(column.id) && (
                    <button
                        onClick={() => {
                            deleteColumn(column.id);
                        }}
                        className="
        stroke-gray-500
        hover:stroke-white
        hover:bg-columnBackgroundColor
        rounded
        px-1
        py-2
        "
                    >
                        <IoMdTrash />
                    </button>
                )}
            </div>

            {/* Column task container */}
            <div className="flex flex-grow flex-col gap-4 p-2 overflow-x-hidden overflow-y-auto">
                <SortableContext items={tasksIds}>
                    {tasks.map((task) => (
                        <TaskCard
                            key={task.id}
                            task={task}
                            deleteTask={deleteTask}
                            updateTask={updateTask}
                            onCardClick={onCardClick}
                        />
                    ))}
                </SortableContext>
            </div>
            {/* Column footer */}
            {column.id !== "active" && (
                <div className="mx-auto">
                    <button
                        className="bg-white p-2 flex items-center text-sm rounded-t-xl border-t-2 border-toola-500"
                        onClick={() => {
                            nav(`/create-prospect?status=${column.id}`);
                        }}
                    >
                        + Ajouter un prospect
                    </button>
                </div>
            )}
        </div>
    );
}

export default ColumnContainer;