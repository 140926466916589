import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card"
import { useState } from "react";
import { GiShoppingCart } from "react-icons/gi";
import { MdOutlineCall } from "react-icons/md";
import EditInformationPU from "views/crm/editCustomer/components/pop-up/editInformationPU";
import CustomerPopUp from "views/crm/editCustomer/customerPopUp";

const linesSize = [
    "w-[10rem]",
    "w-[11rem]",
    "w-[12rem]",
    "w-[13rem]",
    "w-[14rem]",
];

function InactiveCustomer(props: {
    index: number;
    id: string;
    date: string;
    sum: string;
    rank?: number;
    name: string;
    avatar?: string;
    averageDaysBetweenOrders: any;
}) {
    const { sum, id, index, averageDaysBetweenOrders } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [popUpState, setPopUpState] = useState("page");
    const [selectedCard, setSelectedCard] = useState(null);

   const averageOfanArray = (arr: any) => {
        if (!arr) return null;
        if (arr.length === 0) return null;
        return arr.reduce((a: any, b: any) => a + b, 0) / arr.length;
    }

    const average = averageOfanArray(averageDaysBetweenOrders);

    return (
        <div
            className="relative mb-4 ml-1 flex rounded-xl p-4 justify-left hover:cursor-pointer"
            onClick={() => {
                setSelectedCard(id);
                onOpen();
            }}
        >
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="top-[22vh] !m-auto !w-max !max-w-[85%] md:top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] sm:ml-[168px] sm:mr-[9px] sm:pt-[35px] pb-[40px] xl:max-w-[80%] xl:max-w-7xl flex !z-[1004] xl:mx-auto max-h-[600px] overflow-y-auto">
                            {popUpState === "page" && (
                                <CustomerPopUp
                                    id={selectedCard}
                                    onPageChange={(page: string) =>
                                        setPopUpState(page)
                                    }
                                />
                            )}
                            {popUpState === "edit" && (
                                <EditInformationPU
                                    id={selectedCard}
                                    onPageChange={(page: string) =>
                                        setPopUpState(page)
                                    }
                                />
                            )}
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* <div className="ml-2 w-[200px] relative flex border rounded-xl shadow-sm p-4">
                <h4 className="flex text-sm text-center my-auto font-medium text-navy-700 dark:text-white">
                    {name}
                </h4>
            </div> */}
            {/* left side */}
            <div className="ml-2 relative flex border rounded-xl shadow-sm p-4">
                <p className="absolute top-1 left-2 text-xs">
                    Dernières commandes:
                </p>
                <div className="mt-2 mb-1 flex relative items-center">
                    {/* <div className="absolute">
                        <p className="absolute top-5 inset-0 flex items-center justify-center text-xs font-bold text-red-400">
                            Test
                        </p>
                    </div> */}
                    {averageDaysBetweenOrders && averageDaysBetweenOrders[2] ? (
                        <>
                            <GiShoppingCart size={20} />
                            <div className="relative">
                                <div className="h-1 bg-green-500 rounded-lg w-[60px]"></div>
                                <p className="absolute top-5 inset-0 flex items-center justify-center text-xs font-bold text-green-600">
                                    {`${averageDaysBetweenOrders[2]?.toFixed()} jours`}
                                </p>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                    {averageDaysBetweenOrders && averageDaysBetweenOrders[1] ? (
                        <>
                            <GiShoppingCart size={20} />
                            <div className="relative">
                                <div className="h-1 bg-green-500 rounded-lg w-[60px]"></div>
                                <p className="absolute top-5 inset-0 flex items-center justify-center text-xs font-bold text-green-600">
                                    {`${averageDaysBetweenOrders[1]?.toFixed()} jours`}
                                </p>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                    {averageDaysBetweenOrders && averageDaysBetweenOrders[0] ? (
                        <>
                            <GiShoppingCart size={20} />
                            <div className="relative">
                                <div className="h-1 bg-green-500 rounded-lg w-[60px]"></div>
                                <p className="absolute top-5 inset-0 flex items-center justify-center text-xs font-bold text-green-600">
                                    {`${averageDaysBetweenOrders[0]?.toFixed()} jours`}
                                </p>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                    <GiShoppingCart size={20} />
                    <div className="relative">
                        <div
                            className={`h-1 bg-red-400 rounded-lg ${
                                linesSize[index]
                                    ? linesSize[index]
                                    : "w-[15rem]"
                            }`}
                        ></div>
                        <p className="absolute top-5 inset-0 flex items-center justify-center text-xs font-bold text-red-400">
                            {sum} sans commande
                        </p>
                    </div>
                </div>
            </div>
            <div className="ml-2 relative flex border rounded-xl shadow-sm p-4">
                <div className={`absolute top-2 left-2 flex gap-1`}>
                    <div className="bg-orange-400 rounded-full w-2 h-2"></div>
                    <p className="text-xs mx-auto"> À risque </p>
                </div>
                <p className="mt-4 text-sm font-medium text-gray-600">
                    Aucune commande passé par votre client depuis {sum}
                    {average
                        ? `, la moyenne est de ${average.toFixed()} jours`
                        : "."}
                </p>
            </div>
            <button
                className="right-0 -top-6 absolute flex items-center gap-1 text-sm text-gray-700"
                onClick={() => {
                    setSelectedCard(id);
                    onOpen();
                }}
            >
                <MdOutlineCall />
                <span>Actions commerciales</span>
            </button>
        </div>
    );
}

export default InactiveCustomer;