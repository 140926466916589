import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox  } from "react-icons/md";

const SearchCheckboxSelector = (props: {
    extra?: string
    label?: string;
    hideLabel?: boolean;
    options: any;
    displayKey: string;
    onSelect: any;
    value?: string | null;
    canCreate?: boolean;
	createNew?: any;
    disabled?: boolean;
    allSelectionText?: string
    deliveryPersonTours?: any
}) => {
    const {
        extra,
        label,
        options,
        displayKey,
        onSelect,
        hideLabel,
        value,
        canCreate,
		createNew,
        disabled,
        allSelectionText = "Sélectionner",
        deliveryPersonTours
    } = props;
    const [inputValue, setInputValue] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptionsId, setSelectedOptionsId] = useState([]);
    const [open, setOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        const defaultSelectedOptions = options.filter((o: any) =>
            deliveryPersonTours.includes(o._id),
        );
        setSelectedOptions(defaultSelectedOptions.map((o: any) => o.name));
        setSelectedOptionsId(defaultSelectedOptions.map((o: any) => o._id));
        setIsLoaded(true);
    }, [deliveryPersonTours, options])

    useEffect(() => {
        if (value) {
            const defaultValue = options.find((o: any) => o._id === value);
			if (defaultValue !== undefined)
                setSelectedOptions((selectedOptions: any) => [...selectedOptions, defaultValue?.[displayKey]]);
        }
    }, [value, options, displayKey]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onSelect(selectedOptionsId)
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptionsId])

    const elipseOption = (option:string) => {
        return option?.length > 25 ? option.substring(0, 25) + "..." : option
    }

    return (
        <div className={`font-medium w-full h-[44px] ${extra}`}>
            <label
                htmlFor={hideLabel ? "" : label}
                className={`text-sm font-bold text-navy-700 dark:text-white`}
            >
                {hideLabel ? "" : label}
            </label>
            <div
                onClick={() => {
                    if (disabled) return;
                    setOpen(!open);
                }}
                className={`flex w-full items-center justify-between rounded-xl border truncate ${
                    disabled ? "bg-gray-100" : "bg-white bg-white/0"
                } p-3 text-sm outline-none ${
                    !selectedOptions && "text-sm text-gray-300"
                }`}
            >
                {!isLoaded && "____"}
                {isLoaded &&
                selectedOptions.length > 0 &&
                selectedOptions.length === options.length
                    ? allSelectionText
                    : elipseOption(selectedOptions.toString())}
                {isLoaded && selectedOptions.length === 0 && "Aucune"}
                <BiChevronDown
                    size={20}
                    className={`${open && "rotate-180"}`}
                />
            </div>
            <ul
                className={`mt-2 overflow-y-auto rounded-xl bg-white ${
                    open ? "max-h-60 border" : "max-h-0"
                } `}
            >
                <div className="sticky top-0 flex items-center bg-white px-2">
                    <AiOutlineSearch size={18} className="text-gray-700" />
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) =>
                            setInputValue(e.target.value.toLowerCase())
                        }
                        placeholder={`Recherche...`}
                        className="w-[80%] p-2 text-sm outline-none placeholder:text-gray-700"
                    />
                    {canCreate && (
                        <button
                            className="ml-10 hidden text-xs outline-none placeholder:text-gray-700 lg:block"
                            onClick={createNew}
                        >
                            Ajouter
                        </button>
                    )}
                </div>
                {options?.map((option: any, index: number) => (
                    <li
                        key={`${option?.[displayKey]}-${index}`}
                        className={`flex items-center gap-2 sticky p-2 text-sm hover:bg-gray-100 bg-white
			  ${
                  option?.[displayKey]?.toLowerCase().includes(inputValue)
                      ? "block"
                      : "hidden"
              }`}
                        onClick={() => {
                            if (
                                !selectedOptions.find(
                                    (item: string) =>
                                        item === option?.[displayKey],
                                )
                            ) {
                                setSelectedOptions((selectedOptions: any) => [
                                    ...selectedOptions,
                                    option?.[displayKey],
                                ]);
                                setSelectedOptionsId((selectedOptions: any) => [
                                    ...selectedOptions,
                                    option?._id,
                                ]);
                            } else {
                                setSelectedOptions(
                                    selectedOptions.filter(
                                        (opt: string) =>
                                            opt !== option?.[displayKey],
                                    ),
                                );
                                setSelectedOptionsId(
                                    selectedOptionsId.filter(
                                        (opt: string) => opt !== option?._id,
                                    ),
                                );
                            }
                        }}
                    >
                        {selectedOptions.find(
                            (item: string) => item === option?.[displayKey],
                        ) ? (
                            <MdOutlineCheckBox />
                        ) : (
                            <MdCheckBoxOutlineBlank />
                        )}{" "}
                        {option?.[displayKey]}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SearchCheckboxSelector;
