import Card from "components/card";
import CreateReminderModal from "./CreateReminderModal";
import { useDisclosure } from "@chakra-ui/hooks";
import { getEmailCampaigns } from "interfaces/emailing";
import { useEffect, useState } from "react";
import UpdateReminderModal from "./updateReminderModal";

const weekDays = [
    { en: "monday", fr: "lundi", display: "L" },
    { en: "tuesday", fr: "mardi", display: "M" },
    { en: "wednesday", fr: "mercredi", display: "M" },
    { en: "thursday", fr: "jeudi", display: "J" },
    { en: "friday", fr: "vendredi", display: "V" },
    { en: "saturday", fr: "samedi", display: "S" },
    { en: "sunday", fr: "dimanche", display: "D" },
];

const CommandPageMailing = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenUpdate,
        onOpen: onOpenUpdate,
        onClose: onCloseUpdate
    } = useDisclosure();
    const [emailReminders, setEmailReminders] = useState([]);
    const [selectedEmailReminder, setSelectedEmailReminder] = useState(null);

    const initEmailReminders = async () => {
        const res = await getEmailCampaigns();
        if (res) setEmailReminders(res.data);
    }

    const generateName = (activeDays: string[], hour: string) => {
        const days = weekDays.filter((day) => activeDays.includes(day.en));
        const daysNames = days.map((day) => day.fr);
        return `Relance: ${daysNames.join(", ")} à ${hour.replace(":", "h")}`;
    }

    useEffect(() => {
        initEmailReminders();
    }, []);

    const MailSchedule = (props: {emailCampaign: any, onClick: any}) => {
        const { emailCampaign, onClick } = props;

        return (
            <div
                className="mt-1 relative col-span-2 rounded-lg p-4 border shadow cursor-pointer hover:shadow-lg"
                onClick={onClick}
            >
                <div className="absolute right-0 px-5 top-10">
                    {emailCampaign.status === "active" && (
                        <p className="text-xs text-green-500 border border-green-500 rounded-lg px-2">
                            active
                        </p>
                    )}
                    {emailCampaign.status === "inactive" && (
                        <p className="text-xs text-red-500 border border-red-500 rounded-lg px-2">
                            désactivé
                        </p>
                    )}
                </div>
                <h4 className="text-sm font-medium">
                    {generateName(emailCampaign.activeDays, emailCampaign.hour)}
                </h4>
                <p className="mt-1 text-xs">
                    Mail envoyé à {emailCampaign.customers.length} clients
                    toutes les semaines
                </p>
            </div>
        );
    }

    return (
        <Card extra={"w-full mt-3 px-6 py-6 border"}>
            {/* Header */}

            <CreateReminderModal
                isOpen={isOpen}
                onClose={ () => {
                    initEmailReminders();
                    onClose();
                }}
            />
            <UpdateReminderModal
                isOpen={isOpenUpdate}
                emailCampaignId={selectedEmailReminder}
                onClose={ () => {
                    setSelectedEmailReminder(null);
                    initEmailReminders();
                    onCloseUpdate();
                }}
            />
            <div className="w-full px-1 flex justify-between">
                <div>
                    <h4 className="text-xl font-medium">Relances emails</h4>
                    <p className="mt-1 text-sm">
                        Programmer des emails de relance pour vos clients
                    </p>
                </div>
                <button
                    className="linear flex h-7 cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100"
                    onClick={onOpen}
                >
                    Créer une nouvelle relance
                </button>
            </div>
            {/* inputs */}
            <div className="mt-3 grid grid-cols-1 gap-3 md:grid-cols-4">
                {emailReminders &&
                    emailReminders.map((emailCampaign: any, index: number) => (
                        <MailSchedule
                            emailCampaign={emailCampaign}
                            onClick={() => {
                                setSelectedEmailReminder(emailCampaign._id);
                                onOpenUpdate();
                            }}
                        />
                    ))}
            </div>
        </Card>
    );
};

export default CommandPageMailing;
