import { useEffect, useState } from "react";

import SearchCheckboxSelector from "components/fields/SearchCheckboxSelector";
import { dateToFrench, formatHours } from "utils/date";
import { getDeliveryTours } from "interfaces/deliveryTour";
import { updateDeliveryPerson } from "interfaces/delivery";

const DeliveryPersonsTable = (props: {deliveryPersons: any, onDelete: any}) => {
    const { deliveryPersons, onDelete } = props;
    const [deliveryTours, setDeliveryTours] = useState<any>([]);
    const [isLoaded, setLoaded] = useState(false);

    const initDeliveryTours = async () => {
        const res = await getDeliveryTours(false);
        if (res) {
            setDeliveryTours(res.data);
            setLoaded(true);
            return;
        }
    };

    const setAuthorizedTours = async (deliveryPerson:any, tourIds: any) => {
        const data = { ...deliveryPerson };
        data.authorizedTours = tourIds;
        await updateDeliveryPerson(data);
    }

    useEffect(() => {
        initDeliveryTours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <table className="w-full">
            <thead>
                <tr key={0} className="!border-px !border-gray-400">
                    <th
                        key={1}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] w-full sm:w-[15%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Livreur
                        </p>
                    </th>
                    <th
                        key={2}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-[15%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Inscrit le
                        </p>
                    </th>
                    <th
                        key={3}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-[15%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Livraisons
                        </p>
                    </th>
                    <th
                        key={4}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-[20%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Statut
                        </p>
                    </th>
                    <th
                        key={5}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-[25%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Tournée(s)
                        </p>
                    </th>
                    <th
                        key={6}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] w-[80px] sm:w-[10%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Actions
                        </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {isLoaded &&
                    deliveryPersons.map(
                        (deliveryPerson: any, index: number) => (
                            <tr
                                key={`${deliveryPerson.name}-${index}`}
                                className="h-[64px]"
                            >
                                <td
                                    key={`${deliveryPerson.name}-${index}-name`}
                                    className="border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                                >
                                    <div className="hidden sm:table-cell">
                                        <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                                            {deliveryPerson.name}
                                        </p>
                                        <p className="font-base mt-[2px] text-gray-600">
                                            {deliveryPerson.email}
                                        </p>
                                    </div>
                                    <div className="sm:hidden">
                                        <p className="text-sm font-bold text-navy-700 dark:text-white xl:leading-3">
                                            {deliveryPerson.name}
                                        </p>
                                        <p className="font-sm text-gray-600">
                                            Inscrit le{" "}
                                            {dateToFrench(
                                                deliveryPerson.createdAt,
                                            ).toLocaleLowerCase()}
                                            <br />
                                            {deliveryPerson.deliveries} tournée
                                            {deliveryPerson.deliveries !== 1 &&
                                                "s"}{" "}
                                            - {deliveryPerson.deliveredOrders}{" "}
                                            commande
                                            {deliveryPerson.deliveredOrders !==
                                                1 && "s"}
                                            <br />
                                        </p>
                                        <p className="text-sm text-navy-700 dark:text-white xl:leading-3 mt-[5px]">
                                            {deliveryPerson.status ===
                                                "available" && "Disponible"}
                                            {deliveryPerson.status !==
                                                "available" &&
                                                deliveryPerson.live &&
                                                `En livraison
                                                ${
                                                    deliveryPerson.live
                                                        .startedAt
                                                        ? `depuis ${formatHours(
                                                              deliveryPerson
                                                                  .live
                                                                  .startedAt,
                                                          )}.`
                                                        : ""
                                                }`}
                                        </p>
                                        <p className="font-sm text-gray-600">
                                            {deliveryPerson.status !==
                                                "available" &&
                                                deliveryPerson.live &&
                                                "Tournée : " +
                                                    deliveryPerson.live
                                                        .deliveryTourName}
                                        </p>
                                        <p className="font-sm text-gray-600">
                                            {deliveryPerson.status !==
                                                "available" &&
                                                deliveryPerson.live &&
                                                "Clients livrés : " +
                                                    deliveryPerson.live
                                                        .deliveredOrders +
                                                    "/" +
                                                    deliveryPerson.live
                                                        .totalOrders}
                                        </p>
                                        <SearchCheckboxSelector
                                            extra={"mt-2"}
                                            allSelectionText="Toutes les tournées"
                                            options={deliveryTours}
                                            deliveryPersonTours={
                                                deliveryPerson.authorizedTours
                                            }
                                            displayKey="name"
                                            onSelect={(optionId: string) =>
                                                setAuthorizedTours(
                                                    deliveryPerson,
                                                    optionId,
                                                )
                                            }
                                        />
                                    </div>
                                </td>
                                <td
                                    key={`${deliveryPerson.name}-${index}-date`}
                                    className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                                >
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        {dateToFrench(
                                            deliveryPerson.createdAt,
                                        ).toLocaleLowerCase()}
                                    </p>
                                </td>
                                <td
                                    key={`${deliveryPerson.name}-${index}-deliveries`}
                                    className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                                >
                                    <p className="text-sm text-navy-700 dark:text-white">
                                        {deliveryPerson.deliveries} tournée
                                        {deliveryPerson.deliveries !== 1 && "s"}
                                    </p>
                                    <p className="text-sm text-navy-700 dark:text-white">
                                        {deliveryPerson.deliveredOrders}{" "}
                                        commande
                                        {deliveryPerson.deliveredOrders !== 1 &&
                                            "s"}
                                    </p>
                                </td>
                                <td
                                    key={`${deliveryPerson.name}-${index}-state`}
                                    className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-0"
                                >
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        {deliveryPerson.status ===
                                            "available" && "Disponible"}
                                        {deliveryPerson.status !==
                                            "available" &&
                                            deliveryPerson.live &&
                                            `En livraison
                                                ${
                                                    deliveryPerson.live
                                                        .startedAt
                                                        ? `depuis ${formatHours(
                                                              deliveryPerson
                                                                  .live
                                                                  .startedAt,
                                                          )}.`
                                                        : ""
                                                }`}
                                    </p>
                                    <p className="font-base text-gray-600">
                                        {deliveryPerson.status !==
                                            "available" &&
                                            deliveryPerson.live &&
                                            "Tournée : " +
                                                deliveryPerson.live
                                                    .deliveryTourName}
                                    </p>
                                    <p className="font-base text-gray-600">
                                        {deliveryPerson.status !==
                                            "available" &&
                                            deliveryPerson.live &&
                                            "Clients livrés : " +
                                                deliveryPerson.live
                                                    .deliveredOrders +
                                                "/" +
                                                deliveryPerson.live.totalOrders}
                                    </p>
                                </td>
                                <td
                                    key={`${deliveryPerson.name}-${index}-turns`}
                                    className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                                >
                                    <SearchCheckboxSelector
                                        allSelectionText="Toutes les tournées"
                                        options={deliveryTours}
                                        deliveryPersonTours={
                                            deliveryPerson.authorizedTours
                                        }
                                        displayKey="name"
                                        onSelect={(optionId: string) =>
                                            setAuthorizedTours(
                                                deliveryPerson,
                                                optionId,
                                            )
                                        }
                                    />
                                </td>
                                <td
                                    key={`${deliveryPerson.name}-${index}-actions`}
                                    className="border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                                >
                                    <button
                                        className="linear col-span-3 flex items-center justify-center rounded-xl bg-red-400 px-3 py-3 text-sm font-medium text-white hover:bg-red-500 active:bg-red-600"
                                        onClick={() =>
                                            onDelete(deliveryPerson._id)
                                        }
                                    >
                                        Retirer
                                    </button>
                                </td>
                            </tr>
                        ),
                    )}
            </tbody>
        </table>
    );
}

export default DeliveryPersonsTable;