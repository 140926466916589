// Assets
import Card from "components/card";

import { MdCheckCircle, MdPersonAddAlt1, MdShare } from "react-icons/md";

const AutomaticOrder = () => {
  return (
    <Card extra={"w-full pb-[80px] pt-[30px] px-[29px] h-fit xl:h-[500px]"}>
      {/* Header */}
      <div className="mt-1">
        <p className="text-xl font-semibold text-navy-700 dark:text-white">
          Partager ma page de commande à mes clients
        </p>
        <p className="mt-4 text-base text-gray-600">
          Plus besoin de perdre du temps à prendre les commandes de vos clients par email ou par téléphone !
        </p>
      </div>

      <div className="relative mt-[70px] flex w-full flex-col justify-center gap-[50px] md:flex-row">
        {/* icons */}
        <div className="flex flex-col items-center">
          <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full from-[#ffffff0f] to-white/0 text-[40px] text-brand-500 shadow-xl shadow-gray-100 dark:!bg-gradient-to-b dark:text-white dark:shadow-darkinset dark:!shadow-white/20">
            <MdShare />
          </div>
          {/* text */}
          <div className="flex flex-col items-center">
            <p className="mt-[18px] text-xl font-bold text-navy-700 dark:text-white">
              Partager la page de commande
            </p>
            <p className="mt-3 text-center text-base text-gray-600">
              Un lien unique pour tout vos clients: <a className="text-blue-500" href="https://app.toola.eu/command" target="_blank" rel="noreferrer">Page de commande</a>
            </p>
          </div>
        </div>
        {/* icons2 */}
        <div className="mr-[14px] flex flex-col items-center">
          <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full from-[#ffffff0f] to-white/0 text-[40px] text-brand-500 shadow-xl shadow-gray-100 dark:!bg-gradient-to-b dark:text-white dark:shadow-darkinset dark:!shadow-white/20">
            <MdPersonAddAlt1 />
          </div>
          {/* text */}
          <div className="flex flex-col items-center">
            <p className="mt-[18px] text-xl font-bold text-navy-700 dark:text-white">
              Votre client se connecte
            </p>
            <p className="mt-3 text-center text-base text-gray-600">
              En 1 clic via l'adresse mail que vous lui avez associé 
            </p>
          </div>
        </div>
        {/* icons3 */}
        <div className="flex flex-col items-center">
          <div className="flex h-[100px] w-[100px] items-center justify-center rounded-full from-[#ffffff0f] to-white/0 text-[40px] text-brand-500 shadow-xl shadow-gray-100 dark:!bg-gradient-to-b dark:text-white dark:shadow-darkinset dark:!shadow-white/20">
            <MdCheckCircle />
          </div>
          {/* text */}
          <div className="flex flex-col items-center">
            <p className="mt-[18px] text-center text-xl font-bold text-navy-700 dark:text-white">
              C'est prêt !
            </p>
            <p className="mt-3 text-center text-base text-gray-600">
              Il peut commander en parfaite autonomie
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AutomaticOrder;
