import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";

import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { getInvoiceEmailModel } from "interfaces/invoice";

const EmailModal = (props: {
    isOpen: any;
    onClose: any;
    sendEmail: any;
    archived?: boolean;
    selectedInvoiceId?: string;
}) => {
    const { isOpen, onClose, sendEmail, selectedInvoiceId } = props;
    const [message, setMessage] = useState("");
    const [object, setObject] = useState("");
    const  [email, setEmail] = useState("");

    const initMessage = async () => {
        if (!selectedInvoiceId) return;
        const res = await getInvoiceEmailModel(selectedInvoiceId);
        console.log(selectedInvoiceId);
        if (res) {
            setMessage(res.content);
            setObject(res.name);
            setEmail(res.customerEmail);
        }
    }

    useEffect(() => {
        initMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInvoiceId]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="mb-1 text-2xl font-bold">
                            Envoyer par email
                        </h1>
                        <p className="mb-2 text-sm">Destinataire: {email ? email : "inconnu"}</p>
                        <InputField
                            label="Object"
                            placeholder="Facture n° 2021-0001"
                            id="object"
                            value={object}
                            type="text"
                            onChange={(e: any) => setObject(e.target.value)}
                        />
                        <div className="mt-2">
                            <TextField
                                label="Message"
                                id="textarea"
                                placeholder="Votre message"
                                value={message}
                                cols={30}
                                rows={4}
                                onChange={(e: any) => setMessage(e.target.value)}
                            />
                        </div>
                        {selectedInvoiceId !== "all" && (!email || !email.includes("@") || !email.includes(".")) ?
                            <p className="mx-auto mt-2 text-sm text-red-500">Impossible d'envoyer une facture, aucun email indiqué.</p>
                            : null
                        }
                        <div className="mt-5 flex gap-2">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            {((selectedInvoiceId === "all") || (email && email.includes("@") && email.includes("."))) &&
                                <button
                                    className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-base font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                    onClick={() => sendEmail(object, message)}
                                    disabled={selectedInvoiceId !== "all" && (!email || !email.includes("@") || !email.includes("."))}
                                >
                                    Envoyer la facture
                                </button>
                            }
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default EmailModal;
