import { getIngredient } from "interfaces/ingredients";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const MoveStock = () => {

	const [ingredient, setIngredient] = useState(null);
	const params = useParams();
	const navigate = useNavigate();

	const initIngredient = async (id: string) => {
        const res: any = await getIngredient(id);
        if (!res) {
            navigate("/auth/login");
            return;
        }
        setIngredient(res);
    };

	useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/auth/login");
        }
        initIngredient(params.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
			<h1 className="mt-[15%] mx-auto text-center text-5xl">
				{ingredient && ingredient.name}
			</h1>
            <button className="mt-10 mx-auto linear w-[70%] p-5 text-base font-medium text-white flex items-center justify-center rounded-xl bg-brand-400 ">
                Rajouter un stock
            </button>
			<button className="mt-10 mx-auto linear w-[70%] p-5 text-base font-medium text-white flex items-center justify-center rounded-xl bg-red-400 ">
                Déclarer une perte
            </button>
			<button className="mt-10 mx-auto linear w-[70%] p-5 text-base font-medium text-white flex items-center justify-center rounded-xl bg-green-400 ">
                Transformer en commande
            </button>
        </div>
    );
};

export default MoveStock;
