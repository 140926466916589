import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";

const AnnounceModal = (props: {
    isOpen: any;
    onClose: any;
    description: any;
}) => {
    const { isOpen, onClose, description } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="mb-[20px] text-xl font-bold">
                            Annonce
                        </h1>
                        <p>{description}</p>
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-toola-400 px-5 py-3 text-base font-medium text-toola-400"
                            >
                                Compris !
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AnnounceModal;
