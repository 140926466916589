import { IoMdMenu } from "react-icons/io";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown, MdLogout, MdOutlineExtension, MdSettings } from "react-icons/md";
import { useState } from "react";

import Dropdown from "components/dropdown";
import { AiTwotoneShop } from "react-icons/ai";
import { RiTruckLine } from "react-icons/ri";

const Navbar = (props: {
    onOpenSidenav: () => void;
	openSideNav: () => void;
    brandText: string;
    secondary?: boolean | string;
}) => {
    const { brandText, openSideNav } = props;
    const [devMode, setDevMode] = useState(false);
    const navigate = useNavigate();

    const onKeyPressed = (e: any) => {
        if (e.keyCode === 113) {
            setDevMode(!devMode);
        }
    };

    return (
        <nav className="fixed left-0 sm:left-[285px] w-full sm:w-[calc(100%-295px)] top-0 py-3 p-2 flex items-center justify-between bg-white">
            <div className="block ml-2" onKeyDown={onKeyPressed}>
                <p className="ml-10 text-2xl font-medium text-navy-700">
                    <Link
                        to="#"
                        className="hover:text-navy-700 dark:hover:text-white"
                    >
                        {brandText}
                    </Link>
                </p>
            </div>
            {devMode && (
                <div className="">
                    <p className="ml-1 text-xl text-gray-900 sm:hidden md:hidden lg:hidden xl:hidden">
                        d
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 sm:inline md:hidden lg:hidden xl:hidden">
                        sm
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 md:inline lg:hidden xl:hidden">
                        md
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 lg:inline xl:hidden">
                        lg
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 xl:inline 2xl:hidden">
                        xl
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 2xl:inline 3xl:hidden">
                        2xl
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 3xl:inline">
                        3xl
                    </p>
                </div>
            )}
            <div className="flex gap-[5px] sm:gap-[10px]">
                {localStorage.getItem("alertsNbr") !== "0" &&
                    localStorage.getItem("alertsNbr") !== null && (
                        <div className="gap-2">
                            <button
                                className="hidden sm:flex mt-2 items-center justify-center gap-2 rounded-xl border border-red-500 bg-none px-2 py-1 text-base font-medium text-red-500 hover:bg-red-50 shadow"
                                onClick={() => navigate("/alerts")}
                            >
                                <IoMdNotificationsOutline className="h-4 w-4" />
                                <span className="text-xs">
                                    {" "}
                                    {localStorage.getItem("alertsNbr")} alerte
                                    {localStorage.getItem("alertsNbr") > "1"
                                        ? "s"
                                        : ""}{" "}
                                    !
                                </span>
                            </button>
                            <button
                                className="relative sm:hidden flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white dark:!bg-navy-800 dark:shadow-none shadow-xl shadow-shadow-500 text-base text-red-500 hover:bg-red-50"
                                onClick={() => navigate("/alerts")}
                            >
                                <IoMdNotificationsOutline size={35} />
                                <div className="absolute flex bg-red-500 text-white bottom-[10px] right-[10px] w-[15px] h-[15px] rounded-full text-[10px] justify-center items-center">
                                    {localStorage.getItem("alertsNbr")}
                                </div>
                            </button>
                        </div>
                    )}
                <div className="step-user-menu hidden border sm:flex my-auto relative px-2 h-[40px] gap-2 justify-between items-center rounded-xl bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500">
                    <p className="text-sm my-auto">
                        👋 Hey,{" "}
                        {localStorage.getItem("name")
                            ? localStorage.getItem("name")
                            : ""}
                    </p>
                    {/* Profile & Dropdown */}
                    <Dropdown
                        button={<MdKeyboardArrowDown size={30} />}
                        onClick={openSideNav}
                        children={
                            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                                <div className="ml-2 mt-4 flex flex-col gap-2 px-2">
                                    <div className="flex my-auto gap-1">
                                        <MdSettings className="my-auto" />
                                        <a
                                            href="/settings"
                                            className="step-my-account text-xs text-gray-800 dark:text-white hover:dark:text-white"
                                        >
                                            Gérer mon compte
                                        </a>
                                    </div>
                                    <div className="flex my-auto gap-1">
                                        <AiTwotoneShop id="test" className="my-auto" />
                                        <a
                                            href="/settings/command-page"
                                            className="step-my-account text-xs text-gray-800 dark:text-white hover:dark:text-white"
                                        >
                                            Gérer ma boutique B2B
                                        </a>
                                    </div>
                                    <div className="flex my-auto gap-1">
                                        <MdOutlineExtension className="my-auto" />
                                        <a
                                            href="/settings/plugins"
                                            className="step-my-account text-xs text-gray-800 dark:text-white hover:dark:text-white"
                                        >
                                            Mes extensions
                                        </a>
                                    </div>
                                    <div className="flex my-auto gap-1">
                                        <RiTruckLine className="my-auto" />
                                        <a
                                            href="/settings/deliveries"
                                            className="step-my-account text-xs text-gray-800 dark:text-white hover:dark:text-white"
                                        >
                                            Gérer ma distribution
                                        </a>
                                    </div>
                                    <div className="flex my-auto gap-1">
                                        <MdLogout className="my-auto text-red-500" />
                                        <a
                                            href="https://toola.eu"
                                            onClick={() => {
                                                localStorage.removeItem(
                                                    "token",
                                                );
                                            }}
                                            className="text-xs text-red-500 hover:text-red-500"
                                        >
                                            Se déconnecter
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                        classNames={"py-2 top-8 -left-[184px] w-max"}
                    />
                </div>
                <div className="sm:hidden relative flex h-[50px] w-[50px] items-center justify-around rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                    <button
                        onClick={openSideNav}
                        className={`items-center justify-center bg-[transparent] dark:border-white dark:text-white`}
                    >
                        <IoMdMenu size={35} color="#1B254B" />
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
