import axios from "axios";
import { errorHandler } from "./utils";
import env from "variables/config";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const createStockExit = async (data: any) : Promise<{ data: any; error: any; }> => {
	const res: any = await axios.put("/movements/exit", data, config()).catch(errorHandler);
	if (res.error) return res;
	return { data: res ? res.data : null, error: null };
}

export const createStockMovement = async (data: any) : Promise<{ data: any; error: any; }> => {
	const res: any = await axios.post("/movements", data, config()).catch(errorHandler);
	if (res.error) return res;
	return { data: res ? res.data : null, error: null };
}

export const claimLooses = async (data: any) : Promise<{ data: any; error: any; }> => {
	const res: any = await axios.post("/movements/claim-looses", data, config()).catch(errorHandler);
	if (res.error) return res;
	return { data: res ? res.data : null, error: null };
}

export const getStockMovement = async () : Promise<{ data: any; error: any; }> => {
    const res: any = await axios.get("/movements?limit=500", config()).catch(errorHandler);
	if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getPaginatedStockMovement = async (page: number, limit: number) => {
    const res: any = await axios
        .get(`/movements?limit=${limit}&page=${page}`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};