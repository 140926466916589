import React from "react";
import { BsThreeDots } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";

import Dropdown from "components/dropdown";
import { downloadDeliveriesSummary } from "interfaces/delivery";

function ExportMenu(props: {
    transparent?: boolean;
    vertical?: boolean;
    onClose?: any;
}) {
    const { transparent, vertical } = props;
    const [open, setOpen] = React.useState(false);

    const fetchDeliveriesSummary = async (date: string) => {
        if (!date) date = new Date().toISOString().split("T")[0];
        try {
            const blob = await downloadDeliveriesSummary(date);
            if (!blob) return;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `export-${new Date(date).toLocaleDateString()}.csv`;
            link.click();
        } catch (error) {
            console.error("Error generating PDF:", error);
        }
    };

    return (
        <Dropdown
            button={
                <button
                    onClick={() => setOpen(!open)}
                    className={`flex items-center text-xl hover:cursor-pointer ${
                        transparent
                            ? "bg-none text-white hover:bg-none active:bg-none"
                            : vertical
                              ? "bg-none text-navy-700 dark:text-white"
                              : "bg-lightPrimary py-2 px-2 text-toola-500 hover:bg-gray-100"
                    } linear justify-center rounded-lg font-bold transition duration-200`}
                >
                    {vertical ? (
                        <p className="text-[24px] hover:cursor-pointer text-navy-700">
                            <BsThreeDotsVertical />
                        </p>
                    ) : (
                        <BsThreeDots className="h-5 w-5 text-navy-700" />
                    )}
                </button>
            }
            animation={
                "origin-top-right transition-all duration-300 ease-in-out"
            }
            classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
            children={
                <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <p
                        className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium"
                        onClick={() => {fetchDeliveriesSummary(null)}}
                    >
                        Export le récapitulatif .CSV du jour
                    </p>
                </div>
            }
        />
    );
}

export default ExportMenu;
