import { Importer, ImporterField } from "react-csv-importer";
import { useState } from "react";

// Default package css
import "react-csv-importer/dist/index.css";

// Custom css to override default
import "../../../assets/css/ImportCss.css";

import { frFR } from "./local";
import GoBack from "components/actions/GoBack";
import Card from "components/card";
import { importCustomers } from "interfaces/customer";
import { useNavigate } from "react-router-dom";

const ImportCustomer = () => {
    const [rows, setRows] = useState([]);
    const nav = useNavigate();

    return (
        <>
            <div className="mb-4">
                <GoBack text="Mes clients" />
            </div>
            <Card className="bg-white border border-[#7090B0]/[0.25] rounded-xl">
                <Importer
                    dataHandler={async (rows, { startIndex }) => {
                        setRows((prev) => [...prev, ...rows]);
                    }}
                    locale={frFR}
                    defaultNoHeader={false}
                    restartable={false}
                    onComplete={async () => {
                        const result = await importCustomers(rows);
                        if (result) nav("/customers");
                    }}
                >
                    {/* Mandatory */}
                    <ImporterField name="company" label="Raison sociale" />
                    <ImporterField name="mail" label="Adresse mail" />
                    <ImporterField
                        name="deliveryAddress"
                        label="Adresse de livraison"
                    />
                    <ImporterField
                        name="billingAddress"
                        label="Adresse de facturation"
                    />

                    {/* Optional */}
                    <ImporterField
                        name="displayName"
                        label="Nom commercial"
                        optional
                    />
                    <ImporterField
                        name="customerId"
                        label="Numéro client"
                        optional
                    />
                    <ImporterField name="phone" label="Téléphone" optional />
                    <ImporterField
                        name="siren"
                        label="Numéro de SIREN"
                        optional
                    />
                    <ImporterField
                        name="vatNumber"
                        label="Numéro de TVA"
                        optional
                    />
                    <ImporterField name="notes" label="Notes utiles" optional />
                    <ImporterField name="contact" label="Contact" optional />
                </Importer>
            </Card>
        </>
    );
};

export default ImportCustomer;
