import { MdComment, MdEmail, MdOutlineCall } from "react-icons/md";

function Comment(props: {
    message: string;
    time: string;
    extra: string;
    text: string;
    type?: string;
    timecolor: string;
}) {
    const { message, time, extra, text, timecolor, type } = props;
    return (
        <div className={`mb-3 dark:!bg-navy-700 ${extra} p-5`}>
            <h1
                className={`text-xs pb-1 text-navy-700 dark:text-white ${text}`}
            >
                {message}
            </h1>
            <div className="flex gap-1">
                {type === "comment" && <MdComment className="text-gray-400" />}
                {type === "call" && <MdOutlineCall className="text-gray-400" />}
                {type === "email" && <MdEmail className="text-gray-400" />}
                <p className={`${timecolor} text-xs font-medium`}>
                    {" "}
                    {new Date(time).toLocaleDateString("fr-FR")} à{" "}
                    {new Date(time).toLocaleTimeString("fr-FR")}{" "}
                </p>
            </div>
        </div>
    );
}

export default Comment;
