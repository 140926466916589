import React from 'react';
import { BsThreeDots } from 'react-icons/bs';

import Dropdown from 'components/dropdown';

function InvoiceMenu(props: {
    invoice: any;
    updateInvoiceStatus: any;
    createCreditNote: any;
    disabled?: boolean;
    openHistory?: any;
}) {
    const {
        invoice,
        updateInvoiceStatus,
        createCreditNote,
        disabled,
        openHistory,
    } = props;
    const [open, setOpen] = React.useState(false);
    return (
        <Dropdown
            button={
                <button
                    onClick={() => disabled === false && setOpen(!open)}
                    className={`flex items-center text-xl
                    ${
                        disabled
                            ? 'text-gray-300'
                            : 'hover:cursor-pointer text-toola-500'
                    } rounded-2xl bg-lightPrimary p-2 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                    linear justify-center font-bold transition duration-200`}
                >
                    <BsThreeDots
                        title="Autres actions"
                        className={`h-6 w-6 ${
                            disabled
                                ? 'text-gray-500'
                                : 'hover:cursor-pointer text-toola-500 hover:text-toola-700'
                        }`}
                    />
                </button>
            }
            animation={
                'origin-top-right transition-all duration-300 ease-in-out'
            }
            classNames={`top-11 right-0 w-max`}
            children={
                <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    {open && (
                        <>
                            <p
                                className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium"
                                onClick={() => {
                                    updateInvoiceStatus(invoice);
                                    setOpen(false);
                                }}
                            >
                                {invoice.status === 'paid'
                                    ? 'Voir les transactions'
                                    : 'Marquer comme payé'}
                            </p>
                            <p
                                className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium"
                                onClick={() => {
                                    openHistory(invoice);
                                    setOpen(false);
                                }}
                            >
                                Historique d'envoi
                            </p>
                            {invoice.type !== 'credit-note' &&
                                invoice.status !== 'canceled' && (
                                    <p
                                        className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium"
                                        onClick={() => {
                                            createCreditNote(invoice.id);
                                        }}
                                    >
                                        Annuler la facture
                                    </p>
                                )}
                        </>
                    )}
                </div>
            }
        />
    );
}

export default InvoiceMenu;
