import { useNavigate } from "react-router-dom";
import GoBack from "components/actions/GoBack";

const ModuleCard = (props: {logo: any, name: string, service: string, description: string, url: string}) => {
    const { logo, name, description, url } = props;
    const nav = useNavigate();

    return (
        <div
            className="relative w-full mt-2 cursor-pointer"
            onClick={() => nav(url)}
        >
            <div className="px-2 py-4 border bg-gradient-to-r from-blue-400 to-blue-500 rounded-t-lg">
                <p className="ml-4 text-[50px]">{logo}</p>
            </div>
            {/* Badge */}
            {/* <div className="absolute top-3 right-3 bg-green-600/80 text-white text-xs px-2 py-1 rounded-lg">
                {"Gratuit"}
            </div> */}
            {/* Header */}
            <div className="py-3 w-full px-4 rounded-b-lg border shadow">
                <h4 className="mt-1 text-xl font-medium">{name}</h4>
                <p className="mt-2 text-xs">{description}</p>
            </div>
        </div>
    );
};

const Modules = () => {
    return (
        <div className="mt-2">
            <GoBack />
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                <ModuleCard
                    name="Boutique B2B"
                    service="shop-b2b"
                    description="Vos clients peuvent commander directement sur votre boutique."
                    logo={"🛍️"}
                    url="/settings/command-page"
                />
                <ModuleCard
                    name="CRM"
                    service="crm"
                    description="Gérez vos clients et vos prospects, planifiez vos rendez-vous."
                    logo={"📞"}
                    url="/kanban"
                />
                <ModuleCard
                    name="Livraison"
                    service="delivery"
                    description="Facilitez la vie à vos livreurs avec notre application de livraison."
                    logo={"🚚"}
                    url="/delivery/delivery-list?f=1"
                />
                <ModuleCard
                    name="Stock"
                    service="stock"
                    description="Gérez votre stock et vos productions en temps réel."
                    logo={"📦"}
                    url="/stock/stock-list"
                />
                <ModuleCard
                    name="Facturation"
                    service="invoice"
                    description="Transformez vos commandes en factures et envoyez-les en 1 clic."
                    logo={"🧾"}
                    url="/invoices"
                />
                <ModuleCard
                    name="Analyse"
                    service="data"
                    description="Analysez vos données et prenez des décisions éclairées."
                    logo={"📊"}
                    url="/"
                />
                <ModuleCard
                    name="Rétention"
                    service="retention"
                    description="Ne laissez plus vos clients vous quitter sans être prévenu."
                    logo={"🔍"}
                    url="/tasks"
                />
            </div>
        </div>
    );
};

export default Modules;
