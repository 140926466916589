import InactivCustomer from "./InactiveCustomer";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { dateToFrenchShort } from "utils/date";
import { getInactiveCustomers } from "interfaces/user";

function SleepingCustomers() {
    const [data, setData] = useState([]);
    const [sleepingCustomers, setSleepingCustomers] = useState([]);
    const [averageDaysBetweenOrders, setAverageDaysBetweenOrders] = useState([]);
    const [sinceDays, setSinceDays] = useState(14);
    const [maxDisplay, setMaxDisplay] = useState(5);
    const [menu, setMenu] = useState('2-weeks');

    const diffDays = (dateA: Date, dateB: Date) => {
        const diffTime = Math.abs(dateA.getTime() - dateB.getTime());
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const initData = async () => {
        const res = await getInactiveCustomers();
        if (res.error) return;
        setData(res.data.inactiveCustomers);
        setAverageDaysBetweenOrders(res.data.averageDaysBetweenOrders);
    }

    useEffect(() => {
        initData();
    }, []);

    useEffect(() => {
        if (!data) return;

        const sleepingCustomers: any = [];
        data.forEach((item: any) => {
            if (item.lastOrderDate) {
                const lastOrderDate = new Date(item.lastOrderDate);
                const today = new Date();
                const diffTime = Math.abs(
                    today.getTime() - lastOrderDate.getTime(),
                );
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                if (diffDays > sinceDays) {
                    sleepingCustomers.push(item);
                }
            }
        });

        //sort by lastOrderDate
        sleepingCustomers.sort((a: any, b: any) => {
            const dateA = new Date(a.lastOrderDate);
            const dateB = new Date(b.lastOrderDate);
            return dateB.getTime() - dateA.getTime();
        });
        setSleepingCustomers(sleepingCustomers);
    }, [data, sinceDays]);

    return (
        <Card extra="hidden lg:block px-[34px] pt-3 rounded-[20px] w-full border">
            <div className="flex items-center justify-between">
                <div>
                    <div className="flex gap-2">
                        <h4 className="ml-3d text-lg font-medium text-navy-700 dark:text-white">
                            Clients inactifs
                        </h4>
                        <p className="text-xs my-auto">
                            depuis {sinceDays} jours
                        </p>
                    </div>
                    <div className="px-4 mt-3 mb-2 flex gap-3">
                        <p
                            className={`cursor-pointer ${
                                menu === "1-week" && "underline"
                            }`}
                            onClick={() => {
                                setMenu("1-week");
                                setSinceDays(7);
                                setMaxDisplay(5);
                            }}
                        >
                            1 semaine
                        </p>
                        <p
                            className={`cursor-pointer ${
                                menu === "2-weeks" && "underline"
                            }`}
                            onClick={() => {
                                setMenu("2-weeks");
                                setSinceDays(14);
                                setMaxDisplay(5);
                            }}
                        >
                            2 semaine
                        </p>
                        <p
                            className={`cursor-pointer ${
                                menu === "1-month" && "underline"
                            }`}
                            onClick={() => {
                                setMenu("1-month");
                                setSinceDays(30);
                                setMaxDisplay(5);
                            }}
                        >
                            1 mois
                        </p>
                        <p
                            className={`cursor-pointer ${
                                menu === "3-months" && "underline"
                            }`}
                            onClick={() => {
                                setMenu("3-months");
                                setSinceDays(90);
                                setMaxDisplay(5);
                            }}
                        >
                            3 mois
                        </p>
                    </div>
                </div>
                <div className="w-[60px]">
                    <InputField
                        type="number"
                        id="limit"
                        inputSmall={true}
                        value={sinceDays}
                        onChange={(e: any) =>
                            setSinceDays(Number(e.target.value))
                        }
                    />
                </div>
            </div>
            {sleepingCustomers && sleepingCustomers.length === 0 && (
                <div className="text-center py-[70px] text-sm text-gray-600">
                    <p className="text-center text-xl text-gray-600 font-bold">
                        Aucune analyse de rétention disponible pour le moment.
                    </p>
                </div>
            )}
            {sleepingCustomers &&
                sleepingCustomers
                    .filter((item : any) => averageDaysBetweenOrders?.find(
                        (x: any) => x.customer === item._id)?.daysBetween.reduce((a: number, b: number) => a + b, 0) > 3)
                    .slice(0, maxDisplay)
                    .map((item: any, index: number) => (
                        <>
                            <div className="h-[2px] bg-gray-200 w-full"></div>
                            <div className="flex justify-between">
                                <p className="ml-2 mt-2">
                                    {item.displayName || item.company}
                                </p>
                            </div>
                            <InactivCustomer
                                index={index}
                                id={item._id}
                                name={item.displayName || item.company}
                                date={`Dernière commande le ${dateToFrenchShort(
                                    item.lastOrderDate,
                                )}`}
                                averageDaysBetweenOrders={
                                    averageDaysBetweenOrders?.find(
                                        (x: any) => x.customer === item._id,
                                    )?.daysBetween
                                }
                                sum={`${diffDays(
                                    new Date(item.lastOrderDate),
                                    new Date(),
                                )} jours`}
                                rank={1}
                            />
                        </>
                    ))}
            {maxDisplay < sleepingCustomers.length && (
                <p
                    className="text-toola-500 cursor-pointer text-center mb-4"
                    onClick={() => setMaxDisplay(maxDisplay + 5)}
                >
                    Voir plus
                </p>
            )}
            {/* <div className="contain bottom-1 w-max items-center gap-1">
        <div className="text-base font-bold text-toola-500 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
          See all InactivCustomers
        </div>
      </div> */}
        </Card>
    );
}

export default SleepingCustomers;
