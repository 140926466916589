import { useNavigate } from "react-router-dom";

const SettingsMenu = (props: {path: string}) => {
    const { path } = props;
    const nav = useNavigate();

    return (
        <div className="mt-4 sm:mt-0 ml-5 flex gap-4">
            <p
                className={`${
                    path === "/settings" && "underline"
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav("/settings")}
            >
                Mon compte
            </p>
            <p
                className={`${
                    path === "/settings/command-page" && "underline"
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav("/settings/command-page")}
            >
                {" "}
                Boutique B2B
            </p>
            <p
                className={`${
                    path === "/settings/deliveries" && "underline"
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav("/settings/deliveries")}
            >
                Distribution
            </p>
            <p
                className={`${
                    path === "/settings/plugins" && "underline"
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav("/settings/plugins")}
            >
                Extensions
            </p>
        </div>
    );
}

export default SettingsMenu;