import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import Card from 'components/card';
import { useEffect } from 'react';
import { dateToFrenchShort } from 'utils/date';

const EmailsHistoryModal = (props: {
    isOpen: any;
    onClose: any;
    invoice: any;
    onClick: any;
}) => {
    const { isOpen, onClose, invoice } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        {invoice &&
                            invoice.emailsHistory &&
                            invoice.emailsHistory.length > 0 && (
                                <>
                                    <h1 className="text-xl font-bold">
                                        Historique des emails
                                    </h1>
                                    <div className="mt-3 max-h-[150px] overflow-x-scroll">
                                        <table className="w-full bg-gray-100 rounded-xl border-separate">
                                            <thead>
                                                <tr className="bg-white text-xs font-medium rounded-xl">
                                                    <th className="py-3">
                                                        Date
                                                    </th>
                                                    <th className="py-3">
                                                        Destinataire
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice &&
                                                    invoice.emailsHistory.map(
                                                        (
                                                            transaction: any,
                                                            index: number,
                                                        ) => (
                                                            <tr
                                                                key={index}
                                                                className={
                                                                    index %
                                                                        2 !==
                                                                    0
                                                                        ? 'bg-gray-50 text-xs text-center'
                                                                        : 'bg-white text-xs text-center'
                                                                }
                                                            >
                                                                <td className="py-3">
                                                                    {dateToFrenchShort(
                                                                        transaction.date,
                                                                    )}
                                                                </td>
                                                                <td className="py-3">
                                                                    {transaction.email ||
                                                                        'N/A'}
                                                                </td>
                                                            </tr>
                                                        ),
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        {invoice &&
                            invoice.emailsHistory &&
                            invoice.emailsHistory.length === 0 && (
                                <p className="text-center text-gray-500">
                                    Aucun historique d'envoi
                                </p>
                            )}
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Revenir aux factures
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default EmailsHistoryModal;
