import Card from "components/card";
import { IoMdTrendingUp } from "react-icons/io";

function StockValue(props: { data: any }) {
    const { data } = props;
    return (
        <Card extra={"p-5 text-dm lg:h-full border"}>
            <div className="mb-5 flex flex-col px-2 text-center">
                <p className="text-lg font-bold text-navy-700 dark:text-white">
                    Valeur du stock
                </p>
                <p className="mt-2 px-4 text-sm font-medium text-gray-600">
                    Visualisez rapidement la valeur de votre stock
                </p>
            </div>
            <div className="flex w-full flex-col items-center">
                <div className="mt-1 flex items-center gap-2">
                    <p className="text-2xl text-gray-600">Total</p>
                    <p className="text-2xl text-green-500">
                        <IoMdTrendingUp />
                    </p>
                </div>
                <h1 className="text-[45px] font-bold text-navy-700 dark:text-white 3xl:text-[40px]">
                    {" "}
                    {data ? data.totalPrice.toFixed() : "-"}
                    {"€"}
                </h1>
                <div className="mt-8 flex gap-14 md:mt-10">
                    <div className="flex flex-col items-center">
                        <p className="mt-1 text-xl text-gray-600">
                            Composants
                        </p>
                        <h3 className="text-[25px] font-bold text-navy-700 dark:text-white 3xl:text-[38px]">
                            {data ? data.ingredientsPrice.toFixed() : "-"}€
                        </h3>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="mt-1 text-xl text-gray-600">Produits</p>
                        <h3 className="text-[25px] font-bold text-navy-700 dark:text-white 3xl:text-[38px]">
                            {data ? data.productsPrice.toFixed() : "-"}€
                        </h3>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default StockValue;
