import axios from "axios";
import env from "variables/config";
import { errorHandler } from "../utils";

axios.defaults.baseURL = env.apiUrl;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("delivery-token"),
        },
    };
};

export const loginDeliveryPerson = async (data: any) => {
    const res: any = await axios
        .post("/user/delivery-persons/login", data)
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getDeliveryPersonToken = async (magicLink: string) => {
    const res: any = await axios
        .post("/user/delivery-persons/token", { magicLink })
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};
