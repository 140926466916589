/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import {
    renderThumb,
    renderTrack,
    renderView,
} from "components/scrollbar/Scrollbar";
import ToolaImg from "assets/img/toola.png";
import { Scrollbars } from "react-custom-scrollbars-2";
import routes from "routes";
import Card from "components/card";
import { FaUserCog } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const SidebarHorizon = (props: {
    open: boolean;
    onClose?: React.MouseEventHandler<HTMLSpanElement>;
    variant?: string;
    fullSidebar?: boolean;
}) => {
    const { open, onClose, variant, fullSidebar } = props;
    const nav = useNavigate();

    return (
        <div
            className={`sm:none linear fixed top-0 !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
                variant === "auth" ? "xl:hidden" : "xl:block"
            } ${open ? "" : "-translate-x-full"} rounded-none`}
        >
            <Card
                extra={`${
                    fullSidebar ? "w-[285px]" : "w-[140px]"
                } sm:mr-4 h-[100vh] rounded-none`}
            >
                <Scrollbars
                    autoHide
                    renderTrackVertical={renderTrack}
                    renderThumbVertical={renderThumb}
                    renderView={renderView}
                >
                    <div className="flex h-full flex-col justify-between">
                        <div>
                            <span
                                className="absolute right-4 top-4 cursor-pointer sm:hidden"
                                onClick={onClose}
                            >
                                <HiX />
                            </span>
                            <div className={`mt-5 flex items-center `}>
                                <img
                                    className={`mx-auto ${
                                        fullSidebar ? " w-[150px]" : "w-[80px]"
                                    }`}
                                    src={ToolaImg}
                                    alt="Toola logo"
                                />
                            </div>
                            {/* Nav item */}
                            <ul className="mt-10 ml-[10px] pt-1 hidden sm:block">
                                <Links
                                    routes={routes}
                                    fullSidebar={fullSidebar}
                                    onNavClicked={() => {}}
                                />
                            </ul>
                            <ul className="ml-[10px] pt-1 block sm:hidden">
                                <Links
                                    routes={routes}
                                    fullSidebar={fullSidebar}
                                    onNavClicked={onClose}
                                />
                            </ul>
                            <div className="sm:hidden absolute bottom-10">
                                <div className="w-screen mb-4 h-px bg-gray-200 dark:bg-white/10" />
                                <ul className="flex gap-[8px]">
                                    <li className=" flex cursor-pointer">
                                        <a
                                            href="/settings"
                                            className="flex gap-2 ml-3 text-[#A3AED0] my-auto text-sm"
                                        >
                                            <FaUserCog
                                                className="text-inherit h-5 w-5"
                                                color="#A3AED0"
                                            />
                                            Paramètres
                                        </a>
                                    </li>
                                    <li className="flex cursor-pointer">
                                        <a
                                            href="https://toola.eu"
                                            onClick={() => {
                                                localStorage.removeItem(
                                                    "token",
                                                );
                                            }}
                                            className="flex gap-2 ml-3 text-[#A3AED0] my-auto text-sm"
                                        >
                                            <IoMdLogOut
                                                className="text-inherit h-5 w-5"
                                                color="#A3AED0"
                                            />
                                            Se déconnecter
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {/* Join others */}
                            {fullSidebar && (
                                <div className="absolute left-0 bottom-[50px] hidden lg:block mx-3 rounded-[20px] bg-lightPrimary py-4 px-6 dark:!bg-navy-700">
                                    <div className="">
                                        <h4 className="text-lg font-bold text-toola-500 dark:text-white">
                                            Allez plus loin 🚀
                                        </h4>
                                        <p className="mt-2 pr-2 text-sm text-gray-700 dark:text-white">
                                            Découvrez{" "}
                                            les différents
                                            <a
                                                className="font-bold text-gray-900 cursor-pointer"
                                                onClick={(e) => nav("/modules")}
                                            >
                                                {" "}
                                                modules
                                            </a>{" "}
                                            disponibles pour vous aider à gérer votre entreprise.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Scrollbars>
            </Card>
        </div>
    );
};

export default SidebarHorizon;
