import Card from "components/card";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteProduction, getProductions } from "interfaces/productions";
import { dateToFrench } from "utils/date";
import { getIcon } from "utils/icons";
import CreateButton from "components/buttons/CreateButton";
import PlannedProductionPreviewModal from "./components/productionPreview";
import { useDisclosure } from "@chakra-ui/hooks";
import DeleteModal from "components/actions/Delete";

const PlannedProductions = () => {
    const [productions, setProductions] = useState<any>([]);
    const [production, setProduction] = useState<any>(null);
	const [toDelete, setToDelete] = useState<any>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const nav = useNavigate();
	const {
        isOpen: isOpenDelete,
        onOpen: onOpenDelete,
        onClose: onCloseDelete,
    } = useDisclosure();

    const initProductions = async () => {
        const prods = await getProductions();
        if (!prods) {
            nav("/auth/login");
            return;
        }
        setProductions(prods.data);
    };

	const delProduction = async (id: string) => {
		const rest = await deleteProduction(id);
		if (!rest) return;
		if (rest.error) {
			console.log(rest.error);
			return;
		}
		onCloseDelete();
		const remaining = productions.filter((p: any) => p._id !== id && p.status === "scheduled");
		if (remaining.length <= 0) {
			nav("/stock/production");
			return;
		} else 
			setProductions(remaining);
	}

    useEffect(() => {
        initProductions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card extra={"w-full p-4 h-full"}>
            <div className="mb-8 w-full">
                <p className="text-2xl text-navy-700 dark:text-white">
                    Vos productions planifiées
                </p>
            </div>
            <PlannedProductionPreviewModal
                isOpen={isOpen}
                onClose={onClose}
                production={production}
            />
			<DeleteModal
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                description="Êtes-vous sûr de vouloir supprimer cette production ?"
                context="de production"
                deleteFunction={() => {
                    delProduction(toDelete._id);
                }}
            />
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-2">
                {productions &&
                    productions
                        .filter((p: any) => p.status === "scheduled")
                        .map((production: any) => (
                            <div className=" mb-3 flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-2xl shadow-gray-200 hover:shadow-2xl dark:!bg-navy-700 dark:shadow-none">
                                <div className="flex items-center">
                                    <div className="flex items-center justify-center">
                                        <img
                                            className="invisible h-24 w-24 rounded-xl lg:visible"
                                            src={getIcon(
                                                production.productName,
                                                "product"
                                            )}
                                            alt=""
                                        />
                                    </div>
                                    <div className="">
                                        <p className="text-sm font-medium text-navy-700 dark:text-white lg:text-lg">
                                            {production.productName ===
                                            "Product not found"
                                                ? "Le produit n'existe plus"
                                                : production.productName}{" "}
                                            x{production.quantity}
                                        </p>
                                        <p className="mt-2 text-xs text-gray-600 xl:text-sm">
                                            Commentaire:{" "}
                                            {production.comment !== " "
                                                ? production.comment
                                                : "Aucun commentaire"}
                                        </p>
                                    </div>
                                </div>
                                <div className="mr-3 flex items-center justify-center rounded-lg bg-orange-100 px-2 py-2 text-xs text-orange-400 xl:font-bold">
                                    Programmé le{" "}
                                    {production.date &&
                                        dateToFrench(production.date)}
                                </div>
								<div>
                                <CreateButton
                                    value="Lancer la production"
                                    extra="mt-2 col-start-1 col-span-6 md:col-span-4 lg:col-start-8 lg:col-span-3 3xl:col-start-9 3xl:col-span-2 text-xs xl:text-sm"
                                    onClick={() => {
                                        setProduction(production);
                                        onOpen();
                                    }}
                                />
								{/* Text button "delete" */}
								<button
									className="mx-auto w-full text-red-500 text-xs xl:text-sm"
									onClick={() => {
										setToDelete(production);
										onOpenDelete();
									}}
								>
									Supprimer
								</button>
								</div>
                            </div>
                        ))}
            </div>
        </Card>
    );
};

export default PlannedProductions;
