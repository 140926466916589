import axios from "axios";
import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const updateKanban = async (data: any) => {
    const res: any = await axios
        .put(`/kanban`, data, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getKanban = async () => {
    const res: any = await axios
        .get(`/kanban`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};