// components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CreateButton from "components/buttons/CreateButton";
import CustomerCard from "components/card/CustomerCard";
import customerImg from "assets/img/customer.png";
import { getPaginatedCustomers } from "interfaces/customer";
import LoadingCard from "./components/loadingCard";
import Nft4 from "assets/img/nfts/Nft4.png";
import SearchIcon from "components/icons/SearchIcon";
import Card from "components/card";
import Pagination from "components/pagination/Pagination";
import OnBoardingCard from "views/admin/main/account/coursePage/components/OnBoardingCard";
import { useShepherdTour } from "react-shepherd";
import CRMMenu from "./components/CRMMenu";
import { fetchFilter, setFilter } from "utils/filtersStorage";

const steps: any = [
    {
        title: "4/5 Personnaliser les prix",
        text: "Cliquer sur un client, puis sur 'gérer les prix'",
        attachTo: {
            element: ".step-customer-0",
            on: "top",
        },
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "J'ai compris !",
                type: "next",
            },
        ],
        id: "step-customer-0",
    },
];

const secondSteps: any = [
    {
        title: "5/5 Partager la boutique B2B",
        text: "Cliquer sur un client, puis sur 'envoyer la page de commande'. Vous pouvez maintenant découvrir la page de commande puis l'envoyer à vos clients.",
        attachTo: {
            element: ".step-customer-0",
            on: "top",
        },
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "J'ai compris !",
                type: "next",
            },
        ],
        id: "step-customer-0",
    },
];

const CRM = () => {
    const nav = useNavigate();
    const [customers, setCustomers] = useState(null);
    const [customersMeta, setCustomersMeta] = useState(null);
    const [search, setSearch] = useState('');
    const [firstFetch, setFirstFetch] = useState(true);
    const [initSearch, setInitSearch] = useState(true);
    const [page, setPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(12);
    const [onboardingStep, setOnboardingStep] = useState(null);
    const isFullyLoaded = customers;
    const tourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
        },
        useModalOverlay: true,
    };
    const tour = useShepherdTour({ tourOptions, steps });
    const secondtour = useShepherdTour({ tourOptions, steps: secondSteps });

    const fetchCustomers = async (page: number, limit: number) => {
        const res = await getPaginatedCustomers(page, limit, search);
        if (res) {
            setCustomers(res.data);
            setCustomersMeta(res.meta);
            setPage(page);
            setLimitPerPage(limit);
            if (firstFetch) setFirstFetch(false);
        }
    };

    const OnboardingOptions = () => {
        return (
            <>
                {onboardingStep === "3" && (
                    <div className="mt-5 mb-4">
                        <OnBoardingCard
                            title="Importer vos clients"
                            actualStep={3}
                            totalStep={5}
                            description="Vous pouvez import vos clients depuis un fichier CSV ou les ajouter manuellement."
                            onClick={() => {
                                setOnboardingStep("4");
                                localStorage.setItem("onboarding-step", "4");
                                tour.start();
                            }}
                        />
                    </div>
                )}
                {onboardingStep === "4" && (
                    <div className="mt-5 mb-4">
                        <OnBoardingCard
                            title="Personnaliser les prix"
                            actualStep={4}
                            totalStep={5}
                            description="Si nécessaire, vous pouvez maintenant personnaliser les prix de vos produits par client."
                            onClick={() => {
                                setOnboardingStep("5");
                                localStorage.setItem("onboarding-step", "5");
                                secondtour.start();
                            }}
                        />
                    </div>
                )}
                {onboardingStep === "5" && (
                    <div className="mt-5 mb-4">
                        <OnBoardingCard
                            title="Partager la boutique B2B"
                            actualStep={5}
                            totalStep={5}
                            description="Vous pouvez maintenant découvrir la page de commande puis l'envoyer à vos clients."
                            nextText="Terminer !"
                            onClick={() => {
                                setOnboardingStep(null);
                                localStorage.setItem("onboarding-step", "1000");
                            }}
                        />
                    </div>
                )}
            </>
        );
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            nav("/login");
        }
        // First fetch handling cached filters
        const filters = fetchFilter('customers');
        if (filters) setSearch(filters.search);
        else {
            fetchCustomers(filters?.page || page, filters?.limit || limitPerPage);
        }

        setOnboardingStep(localStorage.getItem("onboarding-step"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!firstFetch)
        setFilter("customers", { page, limit: limitPerPage, search });
    }, [page, limitPerPage, search, firstFetch]);

    useEffect(() => {

        // If init (search = '') but cached search is not empty,return
        const filters = fetchFilter('customers');
        if (initSearch && filters?.search !== '') {
            setInitSearch(false);
            return;
        }

        // Handle the first fetch with cached filters if exists
        if (firstFetch) {
            const filters = fetchFilter("customers");
            if (filters) {
                fetchCustomers(
                    filters?.page || page,
                    filters?.limit || limitPerPage
                );
            }
        } else {
            // Handle normal search
            setCustomers(null);
            const delayDebounceFn = setTimeout(() => {
                fetchCustomers(1, limitPerPage);
            }, 2000);
            return () => clearTimeout(delayDebounceFn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <>
            <OnboardingOptions />

            <CRMMenu path="/customers" />
            <Card extra="mt-2 pt-[10px] pl-[20px] pr-[20px] pb-[20px]">
                <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                    <div className="h-full w-full rounded-[20px]">
                        <div className="mt-3 flex justify-between items-center flex-col sm:flex-row gap-[10px]">
                            <div className="flex h-[38px] items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white lg:col-span-4 w-[80%] sm:w-[40%]">
                                <SearchIcon />
                                <input
                                    type="text"
                                    placeholder={"Entreprise, mail, téléphone, contact, ...."}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                                />
                            </div>
                            <div className="flex gap-2">
                                <CreateButton
                                    value="Ajouter un client"
                                    extra="my-auto h-10 col-start-1 col-span-6 md:col-span-4 lg:col-start-8 lg:col-span-3 3xl:col-start-9 3xl:col-span-2"
                                    onClick={() => nav("/create-customer")}
                                />
                                <CreateButton
                                    value="Import CSV"
                                    extra="my-auto h-10 col-start-1 col-span-6 md:col-span-4 lg:col-start-8 lg:col-span-3 3xl:col-start-9 3xl:col-span-2"
                                    onClick={() => nav("/import-customer")}
                                />
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4">
                            {isFullyLoaded && customers.length === 0 && (
                                <div className="col-span-12 mt-10">
                                    <img
                                        className="mx-auto flex h-[300px] w-[300px] items-center justify-center"
                                        src={customerImg}
                                        alt=""
                                    />
                                    <p className="col-span-12 my-auto mt-3 text-center text-base text-navy-700 dark:text-white">
                                        Gérer facilement vos prospects, clients
                                        et automatiser la prise de commandes.
                                    </p>
                                    <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                                        <button
                                            className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                            onClick={() =>
                                                nav("/create-customer")
                                            }
                                        >
                                            Ajouter votre premier client
                                        </button>
                                    </div>
                                </div>
                            )}
                            {isFullyLoaded
                                ? customers.map(
                                      (customer: any, index: number) => {
                                          return (
                                              <div
                                                  className={`step-customer-${index}`}
                                              >
                                                  <CustomerCard
                                                      id={customer._id}
                                                      company={customer.company}
                                                      displayName={
                                                          customer.displayName
                                                      }
                                                      email={customer.mail}
                                                      phone={customer.phone}
                                                      commands="0"
                                                      image={Nft4}
                                                      customerId={
                                                          customer.customerId
                                                      }
                                                  />
                                              </div>
                                          );
                                      },
                                  )
                                : Array(10)
                                      .fill(<LoadingCard />)
                                      .map((order: any) => {
                                          return order;
                                      })}
                        </div>
                    </div>
                </div>
                {/* pagination */}
                <Pagination
                    extra="mt-3"
                    maxItems={customersMeta ? customersMeta.total : 12}
                    defaultItemsPerPage={limitPerPage}
                    itemsPerPage={[12, 24, 48]}
                    page={page}
                    onChange={(page: number, limit: number) =>
                        fetchCustomers(page, limit)
                    }
                />
            </Card>
        </>
    );
};

export default CRM;
