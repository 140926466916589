import Card from "components/card";
import ExportModal from "../list-invoices/components/ExportModal";
import { useDisclosure } from "@chakra-ui/hooks";
import { downloadAccountingExport, downloadAccountingExportModel } from "interfaces/invoice";
import GoBack from "components/actions/GoBack";
import { useState } from "react";

const COMMING_SOON = [
    "Isa Compta",
    "Sage",
    "Ebp",
];

const AccountingExport = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [exportModel, setExportModel] = useState<string>(null);

    const getAccountingExport = async (exportAll: string = 'false') => {
        try {
            let blob = null;
            if (exportModel) {
                blob = await downloadAccountingExportModel(exportModel, exportAll);
            } else {
                blob = await downloadAccountingExport(exportAll);
            }
            if (!blob) return;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `export-${new Date().toLocaleDateString()}.csv`;
            link.click();
            onClose();
        } catch (error) {
            console.error("Error generating PDF:", error);
        }
    };

    return (
        <>
            <div className="mt-1">
                <GoBack />
            </div>
            <ExportModal
                isOpen={isOpen}
                onClose={onClose}
                handleExport={getAccountingExport}
            />
            <p className="mt-2 ml-2">
                Ces exports vous permettent de récupérer les écritures
                comptables
            </p>
            <div className="mt-4 grid grid-cols-12 gap-3">
                <Card
                    extra={
                        "w-full !p-5 col-span-6 border relative hover:cursor-pointer"
                    }
                    onClick={() => {
                        setExportModel(null);
                        onOpen();
                    }}
                >
                    <div className="flex justify-between">
                        <h4 className="text-lg font-medium">SIGEM</h4>
                    </div>
                    <p className="text-sm bg-gray-500 text-white mt-2 rounded-xl mx-auto px-3">
                        fichier; journal; date; écheance; compte; N.facture;
                        Id.facture; montant; type
                    </p>
                    <p className="mt-3 text-sm">
                        💡 Contactez le support si vous souhaitez créer un
                        dérivé de ce format.
                    </p>
                </Card>
                <Card
                    extra={
                        "w-full !p-5 col-span-6 border relative hover:cursor-pointer"
                    }
                    onClick={() => {
                        setExportModel("ciel");
                        onOpen();
                    }}
                >
                    <div className="flex justify-between">
                        <h4 className="text-lg font-medium">Ciel</h4>
                    </div>
                    <p className="text-sm bg-gray-500 text-white mt-2 rounded-xl mx-auto px-3">
                        date;code journal;numéro compte;libellé;numéro pièce;débit;crédit;
                    </p>
                    <p className="mt-3 text-sm">
                        💡 Contactez le support si vous souhaitez créer un
                        dérivé de ce format.
                    </p>
                </Card>
                {COMMING_SOON.map((item, index) => (
                    <Card
                        key={index}
                        extra={
                            "w-full !p-5 col-span-6 border relative hover:cursor-pointer"
                        }
                        onClick={() => alert("Bientôt disponible")}
                    >
                        <div className="flex">
                            <h4 className="text-lg font-medium">{item}</h4>
                            <p className="ml-2 text-xs bg-orange-300 py-1 text-white rounded-xl my-auto px-2">
                                Bientôt disponible
                            </p>
                        </div>
                        <p className="text-sm mt-3">
                            Ce format sera prochainement disponible pour vos
                            exports comptables. Nous pouvons créer un dérivé de
                            ce format.
                        </p>
                    </Card>
                ))}
                <Card extra={"w-full !p-5 col-span-6 border relative"}>
                    <div className="flex">
                        <h4 className="text-lg font-medium">Personalisé</h4>
                        <p className="ml-2 text-xs bg-blue-400 py-1 text-white rounded-xl my-auto px-2">
                            Demande support
                        </p>
                    </div>
                    <p className="text-sm mt-3">
                        Si aucun format ne vous convient, contactez le support
                        pour faire ajouter votre format personnalisé.
                    </p>
                </Card>
            </div>
        </>
    );
};

export default AccountingExport;
