const round = (value: any) => {
    value = parseFloat(value);
    value = parseFloat(value.toFixed(4)); // Avoid floating point errors

    // Convert to upper 0.0x
    const isZero = value.toString()[0] === "0";
    if (isZero) value += 1;
    value = Math.ceil(value * 100) / 100;
    if (isZero) value -= 1;

    value = parseFloat(value.toFixed(2)); // Round to 2 decimals
    return value;
};

const getInvoiceLineTotalHT = (items: any[]) => {
    const totalHT = items.reduce((acc: number, item: any) => {
        const discountValue = item.discountValue ? item.discountValue : 0;

        let discount = 0;
        if (item.discountType === "percent")
            discount = item.price * item.quantity * (discountValue / 100);
        else if (item.discountType === "value") discount = discountValue;
        return acc + (item.price * item.quantity - discount);
    }, 0);
    return round(totalHT);
};

// Added item per item because each line is rounded
export const getTotalHT = (items: any) => {
    let totalHT = 0;

    for (const item of items) totalHT += getInvoiceLineTotalHT([item]);

    return round(totalHT);
};
