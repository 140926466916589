import Card from "components/card";
import DeliveryToursTable from "./components/DeliveryToursTable";
import { useEffect, useState } from "react";
import { createDeliveryTour, deleteDeliveryTour, editDeliveryTour, getDeliveryTours } from "interfaces/deliveryTour";
import SaveStatus from "components/actions/SaveStatus";
import SettingsMenu from "views/admin/main/account/component/SettingsMenu";
import ManageDeliveryPersons from "../manageDeliveryPersons";

const ManageDeliveryTour = () => {
    const [data, setData] = useState<any>({tours: []});
    const [saveStatus, setSaveStatus] = useState<any>("none");

    const initDeliveryTours = async () => {
        const res = await getDeliveryTours(false);
        if (res) {
            setData({tours: res.data});
            return;
        }
    };

    const updateDeliveryTour = (e: any, id: string) => {
        const tour = data.tours.find(
            (deliveryTour: any) => deliveryTour._id === id,
        );
        tour[e.target.id] = e.target.value;
        tour.isUpdated = true;
        setSaveStatus("saving");
        setData({...data});
    };

    const updateDistributionDay = (e: any, id: string, day: string) => {
        const checkboxState = e.target.checked;
        const days = data.tours.find(
            (deliveryTour: any) => deliveryTour._id === id,
        ).distributionDays;

        if (checkboxState) {
            days.push(day);
        } else {
            const index = days.indexOf(day);
            days.splice(index, 1);
        }

        const uniqueDays = Array.from(new Set(days));

        setSaveStatus("saving");
        setData({
            tours: data.tours.map((o: any) => {
                if (o._id === id) {
                    o.distributionDays = uniqueDays;
                    o.isUpdated = true;
                }
                return o;
            }),
        });
    };

    const removeDeliveryTour = async (id: string) => {
        setSaveStatus("saving");
        const res = await deleteDeliveryTour(id);
        if (!res) {
            setSaveStatus("error");
            return;
        }
        setData({tours: data.tours.filter((o: any) => o._id !== id)});
        setSaveStatus("saved");
    }

    const removeFromUpdated = (id: string) => {
        setData({
            tours: data.tours.map((o: any) => {
                if (o._id === id) {
                    o.isUpdated = false;
                }
                return o;
            }),
        });
    }

    const addDeliveryTour = async () => {
        setSaveStatus("saving");
        const res = await createDeliveryTour({
            name: "Nouvelle tournée",
            minimumOrderAmount: 0,
            deliveryDelay: 1,
        });
        if (!res) {
            setSaveStatus("error");
            return;
        }

        const tours = data.tours;
        tours.unshift(res);
        setData({tours: [...tours]});
        setSaveStatus("saved");
    }

    useEffect(() => {
        const updatedTours = data.tours.filter(
            (deliveryTour: any) => deliveryTour.isUpdated,
        );
        const delayDebounceFn = setTimeout(async () => {
            await Promise.all(updatedTours.map(async (deliveryTour: any) => {
                await editDeliveryTour(deliveryTour._id, {
                    name: deliveryTour.name,
                    distributionDays: deliveryTour.distributionDays,
                    minimumOrderAmount: deliveryTour.minimumOrderAmount,
                    deliveryDelay: deliveryTour.deliveryDelay,

                });
                removeFromUpdated(deliveryTour._id);
            }));
            setSaveStatus("saved");
        }, 1500);

        return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        initDeliveryTours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-2">
            <SettingsMenu path="/settings/deliveries" />
            <SaveStatus status={saveStatus} />
            <Card extra="mt-4 pt-[10px] pl-[20px] pr-[20px] pb-[20px] border">
                <div className="mt-1 flex justify-between">
                    <h4 className="text-lg font-medium">Tournées de livraison</h4>
                    <button
                        className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100"
                        onClick={addDeliveryTour}
                    >
                        Ajouter une option de distribution
                    </button>
                </div>
                <div className="h-full w-full pt-3">
                    <div>
                        {data && (
                            <DeliveryToursTable
                                data={data}
                                updateDeliveryTour={updateDeliveryTour}
                                updateDistributionDay={updateDistributionDay}
                                deleteDeliveryTour={removeDeliveryTour}
                            />
                        )}
                    </div>
                </div>
            </Card>
            <ManageDeliveryPersons />
        </div>
    );
}

export default ManageDeliveryTour;