import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { getDelivery } from "interfaces/delivery";
import DesktopRecap from "./components/desktopRecap";
import PrintOrderCard from "./components/printOrderCard";
import { dateToFrench } from "utils/date";

const PrintDelivery = (props: {
    isExternalPage: boolean
}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [delivery, setDelivery] = useState(null);
    const [displayPrice, setDisplayPrice] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    // Get a specific query parameter
    const [orders, setOrders] = useState([]);
    const [showTurnSummary, setShowTurnSummary] = useState(false);
    const { isExternalPage } = props;

    const initOrders = async (deliveryId: string) => {
        const res = await getDelivery(deliveryId, isExternalPage);
        if (res) {
            document.title = `Feuille de route - tournée ${res.name ? res.name : ""}`;
            setDelivery(res);
            setOrders([...res.pendingOrders, ...res.deliveredOrders]);
            setTimeout(() => {
                window.print();
            }, 1000);
        }
    };

    useEffect(() => {
        const displayPrice = searchParams.get("f");

        if (displayPrice === "true") setDisplayPrice(true);

        if (localStorage.getItem((isExternalPage ? "delivery-" : "") + "token") === null) {
            navigate("/login");
        }
        initOrders(params.deliveryId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                <div className={`${isExternalPage && "mb-[100px]"}`}>
                    <div className="p-4 flex items-center gap-4">
                        <h4 className="text-xl text-toola-500 font-bold">
                            Tournée {delivery && `${delivery.name} du ${dateToFrench(delivery.deliveryDate)}`}
                        </h4>
                    </div>
                    {!isExternalPage && <DesktopRecap delivery={delivery} />}
                    {orders &&
                        orders.length > 0 &&
                        orders
                            .sort(
                                (a: any, b: any) =>
                                    a.deliveryOrder - b.deliveryOrder,
                            )
                            .map((order: any, index: number) => {
                                return (
                                    <PrintOrderCard order={order} displayPrice={displayPrice} />
                                );
                            })}
                    {orders && orders.length > 0 && displayPrice && (
                        <div className="mt-5 flex ml-10">
                            <p className="text-lg text-navy-700 font-bold">
                                Prix total HT : {orders.reduce((acc: any, order: any) => acc + order.totalPrice, 0).toFixed(2)} €
                            </p>
                        </div>
                    )}
                    <div className="sm:hidden fixed bottom-0 left-0 h-[86px] w-full bg-white border-t border-[#7090B0/0.25]">
                        <div className="m-[10px] px-[16px] py-[11px] h-[66px] border border-[#7090B0/0.25] rounded-[30px] flex justify-between items-center">
                            <div
                                className="flex gap-[10px] items-center"
                                onClick={() => {
                                    setShowTurnSummary(!showTurnSummary);
                                }}
                            >
                                <div className="flex flex-col">
                                    <p className="text-sm text-gray-600">
                                        {delivery &&
                                            delivery.pendingOrders.length +
                                                delivery.deliveredOrders
                                                    .length +
                                                " client" +
                                                (delivery.pendingOrders.length +
                                                    delivery.deliveredOrders
                                                        .length !==
                                                1
                                                    ? "s"
                                                    : "")}{" "}
                                        à livrer
                                        <br />
                                        {delivery &&
                                            delivery.pendingOrders.length +
                                                " client" +
                                                (delivery.pendingOrders
                                                    .length !== 1
                                                    ? "s"
                                                    : "")}{" "}
                                        restant à livrer
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default PrintDelivery;
