const Select = (props: {
    label?: string;
    options?: any;
    displayKey?: string;
    onSelect?: any;
	value?: string;
    required?: boolean;
    extra?: string;
    disabled?: boolean;
    selected?: string;
}) => {
    const { label, options, displayKey, onSelect, value, required = true, extra, disabled, selected } = props;
    return (
        <>
            <label
                htmlFor="reason"
                className="text-sm font-medium text-navy-700"
            >
                {label}{required && "*"}
            </label>
            <div className={`mt-2 w-full rounded-xl border ${disabled ? "bg-gray-100" : "bg-white"} border-gray-200 px-2.5 py-3 text-sm outline-none ${extra}`}>
                <select
                    id="reason"
                    disabled={disabled}
                    className={`mb-0 w-full rounded-xl border-none ${disabled ? "bg-gray-100" : "bg-white"} text-sm font-medium outline-none`}
					onChange={(e) => onSelect(JSON.parse(e.target.value))}
					value={JSON.stringify(options.find((o: any) => o.value === value))}
                >
                    {options?.map((option: any, index: number) => (
                        <option
                            key={`${option?.[displayKey]}-${index}`}
                            value={JSON.stringify(option)}
                            selected={option.value === selected}
                        >
                            {option?.[displayKey]}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
};

export default Select;
