import axios from 'axios';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { getFile, uploadCompanyLogo } from 'interfaces/user';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import env from "variables/config";

const Information = () => {
  const [user, setUser] = useState(null);
  const [file, setFile] = useState(null);
  const [logo, setLogo] = useState(null);

  const initUser = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios
      .get(`${env.apiUrl}/user/me`, config)
      .then(res => {
        const user = res.data;
        setUser(user);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setUser({ ...user, [id]: value });
  };

  const initCompanyLogo = async () => {
    const res = await getFile('company-logo.jpg');
    console.log(res);
    if (res && res.data && res.data.url)
      setLogo(res.data.url)
  }

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append('file', file);
    const res = await uploadCompanyLogo(formData);
    if (res)
      initCompanyLogo();

  };

  const updateProfil = () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      fullName: user.fullName,
	    company: user.company,
    };
    axios
      .put(`${env.apiUrl}/user`, data, config)
      .then(res => {
        if (file) uploadImage();
		    toast.success("Votre profil a été modifié avec succès");
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    initUser();
    initCompanyLogo();
  }, []);

  return (
      <Card extra={"w-full mt-3 px-6 py-6 border"}>
          {/* Header */}
          <div className="w-full px-[8px]">
              <h4 className="text-xl font-medium">Profil</h4>
          </div>
          {/* inputs */}
          <div className="mt-4 grid grid-cols-1 gap-3 md:grid-cols-2">
              {/* <InputField
                  extra="mb-3"
                  label="Adresse mail"
                  disabled
                  placeholder={user?.email}
                  id="email"
                  type="text"
                  onChange={handleChange}
              /> */}
              <InputField
                  extra="mb-3"
                  label="Prénom"
                  placeholder={user?.fullName}
                  id="fullName"
                  type="text"
                  onChange={handleChange}
                  inputSmall={true}
              />
              <InputField
                  extra="mb-3"
                  label="Entreprise"
                  placeholder={user?.company}
                  id="company"
                  type="text"
                  onChange={handleChange}
                  inputSmall={true}
              />
              <div className="col-span-2">
                  <label
                      htmlFor={"logo"}
                      className={`text-sm text-navy-700 dark:text-white ml-1.5 font-bold`}
                  >
                      {"Logo entreprise"}
                  </label>
                  <div>
                      {logo && (
                          <img
                              src={logo}
                              alt="logo"
                              className="mx-3 my-3 w-24 h-15"
                          />
                      )}
                      <p className="ml-1 mb-1 text-xs">
                          Cette image sera visible sur les factures et la page
                          de commande.
                      </p>
                      <input
                          type="file"
                          id="file"
                          accept="image/*"
                          className="text-xs items-center justify-center rounded-xl border bg-white/0 px-2.5 py-3 outline-none"
                          onChange={(e) => {
                              setFile(e.target.files[0]);
                              setLogo(URL.createObjectURL(e.target.files[0]));
                          }}
                      />
                  </div>
              </div>
          </div>
          <div className="mt-2 flex w-full justify-end">
              <button
                  className="linear rounded-xl bg-toola-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-toola-600 active:bg-toola-700"
                  onClick={() => updateProfil()}
              >
                  Sauvegarder
              </button>
          </div>
      </Card>
  );
};

export default Information;
