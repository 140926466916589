import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface Props {
    task: any;
    deleteTask: (id: any) => void;
    onCardClick?: (id: any) => void;
    updateTask: (id: any, content: string) => void;
}

function TaskCard({ task, deleteTask, updateTask, onCardClick }: Props) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [mouseIsOver, setMouseIsOver] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: task.id,
        data: {
            type: "Task",
            task,
        },
        disabled: editMode,
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };

    const toggleEditMode = () => {
        setEditMode((prev) => !prev);
        setMouseIsOver(false);
    };

    if (isDragging) {
        return (
            <div
                ref={setNodeRef}
                style={style}
                className="
        opacity-30
      bg-mainBackgroundColor p-2.5 h-[100px] min-h-[100px] items-center flex text-left rounded-xl border-2 border-rose-500  cursor-grab relative
      "
            />
        );
    }

    if (editMode) {
        return (
            <div
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
                className="bg-mainBackgroundColor border-2 border-xl h-[100px] min-h-[100px] items-center flex text-left rounded-xl cursor-grab relative"
            >
                <textarea
                    className="
        h-full
        w-full p-2.5 resize-none border-none rounded bg-transparent focus:outline-none
        "
                    value={`${task.content}`}
                    autoFocus
                    placeholder="Task content here"
                    onBlur={toggleEditMode}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && e.shiftKey) {
                            toggleEditMode();
                        }
                    }}
                    onChange={(e) => updateTask(task.id, e.target.value)}
                />
            </div>
        );
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            //onClick={toggleEditMode}
            onClick={() => onCardClick(task._id)}
            className="grid grid-cols-1 bg-mainBackgroundColor border bg-white p-2.5 h-[100px] min-h-[100px] items-center text-left rounded-xl hover:ring-2 hover:ring-inset hover:ring-orange-300 cursor-pointer relative task"
            onMouseEnter={() => {
                setMouseIsOver(true);
            }}
            onMouseLeave={() => {
                setMouseIsOver(false);
            }}
        >
            <p className="">{task.content}</p>
            <p className="text-xs">{task.phone}</p>
            <p className="text-xs">{task.mail}</p>

            {/* {mouseIsOver && (
                <p>Edit mode</p>
            )} */}
        </div>
    );
}

export default TaskCard;