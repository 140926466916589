import CreateButton from "components/buttons/CreateButton";
import Card from "components/card";
import SettingsMenu from "views/admin/main/account/component/SettingsMenu";

import SlackLogo from "assets/img/plugins/slack.svg";
import HubSpotLogo from "assets/img/plugins/hubspot.svg";
import PipeDriveLogo from "assets/img/plugins/pipedrive.svg";
import { useNavigate } from "react-router-dom";

const PluginCard = (props: {logo: any, name: string, service: string, description: string, commingSoon?: boolean}) => {
    const { logo, name, description, service, commingSoon = false } = props;
    const nav = useNavigate();

    return (
        <Card extra={"relative w-full mt-4 px-2 py-6 border"}>
            {/* Badge */}
            <div className="absolute top-3 right-3 bg-green-600/80 text-white text-xs px-2 py-1 rounded-lg">
                {"Gratuit"}
            </div>
            {/* Header */}
            <div className="w-full px-1">
                <img
                    src={logo}
                    alt={`${name}-logo`}
                    className="ml-1 w-[70px] h-[70px] border rounded-lg"
                />
                <h4 className="mt-4 text-xl font-medium">{name}</h4>
                <p className="mt-2 text-xs">{description}</p>
            </div>
            <div className="mt-4 flex justify-center">
                {commingSoon ? (
                    <button
                        className={`text-sm px-5 h-10 linear flex items-center justify-center rounded-xl bg-[#518dd1] py-3 font-medium text-white w-full cursor-not-allowed`}
                    >
                        Bientôt disponible
                    </button>
                ) : (
                    <CreateButton
                        value="Configurer"
                        extra="text-sm px-5 h-10 w-full mb-0 bg-toola-500"
                        onClick={() => nav('/plugins/' + service)}
                    />
                )}
            </div>
        </Card>
    );
};

const PluginsList = () => {
    return (
        <div className="mt-2">
            <SettingsMenu path="/settings/plugins" />
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                <PluginCard
                    name="Slack"
                    service="slack"
                    description="Recevez des notifications lors de nouvelles commandes."
                    logo={SlackLogo}
                />
                <PluginCard
                    name="HubSpot"
                    service="hubspot"
                    description="Synchronisez vos client entre Toola et HubSpot automatiquement."
                    logo={HubSpotLogo}
                    commingSoon={true}
                />
                <PluginCard
                    name="PipeDrive"
                    service="pipedrive"
                    description="Synchronisez vos client entre Toola et PipeDrive automatiquement."
                    logo={PipeDriveLogo}
                    commingSoon={true}
                />
            </div>
        </div>
    );
};

export default PluginsList;
