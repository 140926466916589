import Card from "components/card";

const CommandPageTuto = () => {

    return (
        <Card extra={"w-full mt-3 px-6 py-6 border"}>
            {/* Header */}
            <div className="w-full px-1">
                <h4 className="text-xl font-medium">Configuration</h4>
                <p className="mt-1 text-sm">
                    Comment bien paramétrer ma boutique B2B ?
                </p>
            </div>
            {/* inputs */}
            <div className="mt-3 grid grid-cols-1 gap-3 md:grid-cols-2">
                <div className="mt-1 col-span-2 bg-gray-100 rounded-lg p-2">
                    <h4 className="text-sm font-medium">
                        Informations de contact et annonces
                    </h4>
                    <p className="mt-1 text-xs">
                        Ces informations peuvent être modifiées sur cette page
                        et impactent tous vos clients
                    </p>
                </div>
                <div className="mt-1 col-span-2 bg-gray-100 rounded-lg p-2">
                    <h4 className="text-sm font-medium">
                        Blocages, délais de livraison et min. de commande
                    </h4>
                    <p className="mt-1 text-xs">
                        Ces options peuvent être modifié pour chaque tournée
                        dans la section "Distribution"
                    </p>
                </div>
                <div className="mt-1 col-span-2 bg-gray-100 rounded-lg p-2">
                    <h4 className="text-sm font-medium">
                        Prix par client, produits affichés
                    </h4>
                    <p className="mt-1 text-xs">
                        Ces options peuvent être gérer dans votre fiche client.
                        Un produit à 0€ ne sera pas affiché.
                    </p>
                </div>
            </div>
        </Card>
    );
};

export default CommandPageTuto;
