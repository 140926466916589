// components
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CreateButton from 'components/buttons/CreateButton';
import CustomerCard from './components/CustomerCard';
import customerImg from 'assets/img/customer.png';
import { getPaginatedCustomersByReturnables } from 'interfaces/customer';
import LoadingCard from './components/loadingCard';
import Nft4 from 'assets/img/nfts/Nft4.png';
import SearchIcon from 'components/icons/SearchIcon';
import Card from 'components/card';
import Pagination from 'components/pagination/Pagination';
import { fetchFilter, setFilter } from 'utils/filtersStorage';

const Returnables = () => {
    const nav = useNavigate();
    const [customers, setCustomers] = useState(null);
    const [customersMeta, setCustomersMeta] = useState(null);
    const [search, setSearch] = useState('');
    const [firstFetch, setFirstFetch] = useState(true);
    const [initSearch, setInitSearch] = useState(true);
    const [page, setPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(12);
    const isFullyLoaded = customers;

    const fetchCustomers = async (page: number, limit: number) => {
        const res = await getPaginatedCustomersByReturnables(
            page,
            limit,
            search,
        );
        if (res) {
            setCustomers(res.data);
            setCustomersMeta(res.meta);
            setPage(page);
            setLimitPerPage(limit);
            if (firstFetch) setFirstFetch(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            nav('/login');
        }
        // First fetch handling cached filters
        const filters = fetchFilter('customers');
        if (filters) setSearch(filters.search);
        else {
            fetchCustomers(
                filters?.page || page,
                filters?.limit || limitPerPage,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!firstFetch)
            setFilter('customers', { page, limit: limitPerPage, search });
    }, [page, limitPerPage, search, firstFetch]);

    useEffect(() => {
        // If init (search = '') but cached search is not empty,return
        const filters = fetchFilter('customers');
        if (initSearch && filters?.search !== '') {
            setInitSearch(false);
            return;
        }

        // Handle the first fetch with cached filters if exists
        if (firstFetch) {
            const filters = fetchFilter('customers');
            if (filters) {
                fetchCustomers(
                    filters?.page || page,
                    filters?.limit || limitPerPage,
                );
            }
        } else {
            // Handle normal search
            setCustomers(null);
            const delayDebounceFn = setTimeout(() => {
                fetchCustomers(1, limitPerPage);
            }, 2000);
            return () => clearTimeout(delayDebounceFn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <>
            <Card extra="mt-2 pt-[10px] pl-[20px] pr-[20px] pb-[20px]">
                <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                    <div className="h-full w-full rounded-[20px]">
                        <div className="mt-3 flex justify-between items-center flex-col sm:flex-row gap-[10px]">
                            <div className="flex h-[38px] items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white lg:col-span-4 w-[80%] sm:w-[40%]">
                                <SearchIcon />
                                <input
                                    type="text"
                                    placeholder={
                                        'Entreprise, mail, téléphone, contact, ....'
                                    }
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                                />
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4">
                            {isFullyLoaded && customers.length === 0 && (
                                <div className="col-span-12 mt-10">
                                    <img
                                        className="mx-auto flex h-[300px] w-[300px] items-center justify-center"
                                        src={customerImg}
                                        alt=""
                                    />
                                    <p className="col-span-12 my-auto mt-3 text-center text-base text-navy-700 dark:text-white">
                                        Gérer facilement vos prospects, clients
                                        et automatiser la prise de commandes.
                                    </p>
                                    <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                                        <button
                                            className="col-span-12 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5 dark:border-brand-400 dark:bg-brand-400/10 dark:text-white dark:hover:bg-brand-300/10 dark:active:bg-brand-200/10 md:col-span-2 xl:col-start-9"
                                            onClick={() =>
                                                nav('/create-customer')
                                            }
                                        >
                                            Ajouter votre premier client
                                        </button>
                                    </div>
                                </div>
                            )}
                            {isFullyLoaded
                                ? customers.map(
                                      (customer: any, index: number) => {
                                          return (
                                              <div
                                                  className={`step-customer-${customer._id}`}
                                              >
                                                  <CustomerCard
                                                      id={customer._id}
                                                      company={customer.company}
                                                      displayName={
                                                          customer.displayName
                                                      }
                                                      phone={customer.phone}
                                                      commands="0"
                                                      image={Nft4}
                                                      customerId={
                                                          customer.customerId
                                                      }
                                                      returnableCount={
                                                          customer.returnableCount
                                                      }
                                                  />
                                              </div>
                                          );
                                      },
                                  )
                                : Array(10)
                                      .fill(<LoadingCard />)
                                      .map((order: any) => {
                                          return order;
                                      })}
                        </div>
                    </div>
                </div>
                {/* pagination */}
                <Pagination
                    extra="mt-3"
                    maxItems={customersMeta ? customersMeta.total : 12}
                    defaultItemsPerPage={limitPerPage}
                    itemsPerPage={[12, 24, 48]}
                    page={page}
                    onChange={(page: number, limit: number) =>
                        fetchCustomers(page, limit)
                    }
                />
            </Card>
        </>
    );
};

export default Returnables;
