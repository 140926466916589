import React from "react";
import { useEffect } from "react";

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    PaginationState,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import { dateToFrench } from "utils/date";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

type RowObj = {
    createdAt: string;
    status: string;
    quantity: number;
    product: string;
    reason: string;
    objectName: string;
    dlc: string;
    lotNumber: string;
    area: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function MovementTable(props: { tableData: any }) {
    const { tableData } = props;
    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };
    const [sorting, setSorting] = React.useState<SortingState>([
        { id: "createdAt", desc: true },
    ]);

    // Refresh data when props change
    useEffect(() => {
        if (!tableData) return;
        console.log(tableData);
        setData(tableData);
    }, [tableData]);

    let defaultData = [
        {
            createdAt: "10/10/2023 (Exemple)",
            dlc: "15/10/2023 (Exemple)",
            lotNumber: "123456 (Exemple)",
            quantity: 10,
            objectName: "Jus d'orange (Exemple)",
            status: "Produit",
            reason: "Test",
            product: "",
            area: "zone-a",
        },
    ];
    if (tableData && tableData.length > 0) defaultData = tableData;
    const columns = [
        columnHelper.accessor("createdAt", {
            id: "createdAt",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Date
                </p>
            ),
            cell: (info) => (
                <p className="text-left text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue() !== null
                        ? dateToFrench(info.getValue())
                        : "error"}
                </p>
            ),
        }),
        columnHelper.accessor("lotNumber", {
            id: "lotNumber",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Numéro de lot
                </p>
            ),
            cell: (info) => (
                <p className="text-left text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue() ? info.getValue() : "__"}
                </p>
            ),
        }),
        columnHelper.accessor("objectName", {
            id: "objectName",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Produit / Composant
                </p>
            ),
            cell: (info) => (
                <p className="text-left text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("area", {
            id: "area",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Zone de stockage
                </p>
            ),
            cell: (info) => (
                <p className="text-left text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("quantity", {
            id: "quantity",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Quantité
                </p>
            ),
            cell: (info) => (
                <p
                    className={`text-left text-sm font-bold ${
                        info.getValue() > 0 ? "text-green-500" : "text-red-400"
                    }`}
                >
                    {info.getValue() > 0
                        ? `+${info.getValue()}`
                        : info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor("reason", {
            id: "reason",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Motif
                </p>
            ),
            cell: (info) => (
                <p className="text-left text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue() !== " "
                        ? info.getValue()
                        : "Aucun commentaire"}
                </p>
            ),
        }),
        columnHelper.accessor("dlc", {
            id: "dlc",
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    DLC
                </p>
            ),
            cell: (info) => (
                <p className="text-left text-sm font-bold text-navy-700 dark:text-white">
                    {info.getValue() && info.getValue().includes("T")
                        ? info.getValue().split("T")[0]
                        : "__"}
                </p>
            ),
        }),
    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => [...defaultData]);
    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 10,
        });

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
        },
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
    });
    return (
        // <Card extra={"hidden w-full h-full px-6 pb-6 sm:overflow-x-auto"}>

        <div className="overflow-x-scroll xl:overflow-x-hidden">
            <table className="w-full text-center">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr
                            key={headerGroup.id}
                            className="!border-px !border-gray-400"
                        >
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className="cursor-pointer border-b border-gray-200 pb-2 pr-4 pt-4 text-start dark:border-white/30"
                                    >
                                        <div className="items-center justify-between text-xs text-gray-200">
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {{
                                                asc: "",
                                                desc: "",
                                            }[
                                                header.column.getIsSorted() as string
                                            ] ?? null}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td
                                            key={cell.id}
                                            className="min-w-[150px] border-white/0 py-3  pr-4"
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="mt-3 flex h-20 w-full items-center justify-between px-6">
                <div className="flex items-center gap-3">
                    <p className="> Show rows per page text-sm text-gray-700">
                        Affichage, 10 lignes par page
                    </p>
                </div>
                <div className="flex items-center gap-2">
                    <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className={`linear flex h-10 w-10 items-center justify-center rounded-full bg-[#0260CB] p-2 text-lg text-white transition duration-200 hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                    >
                        <MdChevronLeft />
                    </button>

                    {createPages(table.getPageCount()).map(
                        (pageNumber: number, index: number) => {
                            return (
                                <button
                                    className={`linear flex h-10 w-10 items-center justify-center rounded-full p-2 text-sm transition duration-200 ${
                                        pageNumber === pageIndex + 1
                                            ? "bg-[#0260CB] text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                            : "border-[1px] border-gray-400 bg-[transparent] dark:border-white dark:text-white"
                                    }`}
                                    onClick={() =>
                                        table.setPageIndex(pageNumber - 1)
                                    }
                                    key={index}
                                >
                                    {pageNumber}
                                </button>
                            );
                        }
                    )}
                    <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className={`linear duration-20 flex h-10 w-10 items-center justify-center rounded-full bg-[#0260CB] p-2 text-lg text-white transition hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 `}
                    >
                        <MdChevronRight />
                    </button>
                </div>
            </div>
        </div>
    );
}
