import Card from "components/card";
import CRMMenu from "../list-customers/components/CRMMenu";
import SleepingCustomers from "./components/Sleepingcustomers";
import { getMyTasks, markTaskAsDone } from "interfaces/task";
import { useEffect, useState } from "react";
import TaskDashboard from "./components/taskDashboard";

const CRMTasks = () => {
    const [tasks, setTasks] = useState([]);

    const initTasks = async () => {
        const res = await getMyTasks();
        if (res) {
            setTasks(res);
        }
    }

    useEffect(() => {
        initTasks();
    }, []);

    return (
        <div>
            <CRMMenu path="/tasks" />
            <div className="mt-5">
                <Card extra={"my-5 flex w-full text-4xl border"}>
                    <h4 className="mx-5 mt-4 ml-10 text-xl font-medium text-navy-700 mb-3">
                        Tâches commerciales
                    </h4>
                    <div className="px-5">
                        {tasks &&
                            tasks.map((task: any, index: number) => (
                                <TaskDashboard
                                    key={index}
                                    markAsDone={() => {
                                        markTaskAsDone(task._id);
                                        const newTaskList = tasks
                                            .map((t: any) => {
                                                if (t._id === task._id) {
                                                    return {
                                                        ...t,
                                                        status: "done",
                                                    };
                                                }
                                                return t;
                                            })
                                            .filter(
                                                (t: any) => t.status !== "done",
                                            );
                                        setTasks(newTaskList);
                                    }}
                                    message={`${task.customer.company} - ${task.content}`}
                                    time={task.dueDate}
                                    extra="!bg-lightPrimary w-full rounded-xl"
                                    text="text-navy-700"
                                    timecolor="text-gray-400 dark:text-white"
                                />
                            ))}
                        {tasks.length === 0 && (
                            <div className="text-center py-[70px] text-sm text-gray-600">
                                <p className="text-center text-2xl text-gray-600 font-bold">
                                    Aucune tâche pour le moment.
                                </p>
                            </div>
                        )}
                    </div>
                </Card>
                <SleepingCustomers />
            </div>
        </div>
    );
};

export default CRMTasks;
