const CreateButton = (props: {value: string, extra?: string, onClick?: any}) => {
	const {value, extra, onClick} = props;
    return (
        <button className={`linear flex items-center justify-center rounded-xl bg-[#0260CB] px-4 py-3 text-base font-medium text-white hover:bg-[#01499b] active:bg-[#024086] ${extra}`}
		onClick={onClick}>
            {value}
        </button>
    );
};

export default CreateButton;