import Login from "./components/Login";

const Delivery = () => {

    return (
        <div>
            <Login />
        </div>
    );
};

export default Delivery;
