import Card from "components/card";
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function TopCustomersMap(props: { data: any }) {
    const { data } = props;

    const [limit, setLimit] = useState(7);
    const [lineChartDataOverallRevenue, setLineChartDataOverallRevenue] =
        useState<any[]>([
            {
                data: [
                    {
                        x: "New Delhi",
                        y: 218,
                    },
                    {
                        x: "Kolkata",
                        y: 149,
                    },
                ],
            },
        ]);

    const options = {
        legend: {
            show: false,
        },
        chart: {
            toolbar: {
                show: false,
            },
            //colors: ["#417ec4", "#0260CB"],
            height: 350,
            type: "treemap",
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "12px",
            },
            formatter: function (text: any, op: any) {
                return [text, `${op.value} €`];
            },
            offsetY: -4,
        },
    };

    useEffect(() => {
        if (!data) return;

        const TopCustomer: any = [];
        data.slice(0,limit).forEach((item: any, index: number) => {
            TopCustomer.push({
                x: item.company,
                y: Number(item.totalSpent.toFixed())
            });
        });

        setLineChartDataOverallRevenue([
            {
                data: TopCustomer,
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, limit]);

    return (
        <Card extra={"p-5 text-dm lg:h-full max-h-[400px] border"}>
            <div className="flex items-center justify-between">
                <div>
                    <h4 className="ml-px text-lg font-bold text-navy-700 dark:text-white">
                        Répartition CA par client
                    </h4>
                    <p>Toutes périodes confondues</p>
                </div>
                <div className="w-[60px]">
                    <InputField
                        type="number"
                        id="limit"
                        inputSmall={true}
                        value={limit}
                        onChange={(e: any) => setLimit(Number(e.target.value))}
                    />
                </div>
            </div>
            <ReactApexChart
                options={options as any}
                series={lineChartDataOverallRevenue as any}
                type="treemap"
                width="100%"
                height="85%"
            />
        </Card>
    );
}

export default TopCustomersMap;
