import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import InputField from "components/fields/InputField";
import CreateButton from "components/buttons/CreateButton";
import { getDeliveryPersonToken, loginDeliveryPerson } from "interfaces/external/delivery";

const Login = () => {
    const params = useParams();
    const nav = useNavigate();
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const login = async () => {
        const res = await loginDeliveryPerson({ email });
        if (res) {
            setEmailSent(true);
        }
    };

    const handleAuth = async (id: string) => {
      const res = await getDeliveryPersonToken(id);
      if (res) {
        localStorage.setItem("delivery-token", res.token);
        nav("/delivery-ext");
      } else {
       setIsLoaded(true);
      }
    };

    useEffect(() => {
      if (params.id)
        handleAuth(params.id);
      else {
        if (localStorage.getItem("delivery-token"))
            nav("/delivery-ext");
        else
            setIsLoaded(true);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <ToastContainer />
            {isLoaded && (
                <div className="mb-16 flex h-full w-full items-center justify-center">
                    {/* Sign in section */}
                    <div className="mt-[16vh] flex max-w-[90%] flex-col rounded-[20px] pb-16 pt-12 lg:max-w-[42%]">
                        {!emailSent ? (
                            <div>
                                <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
                                    Bienvenue sur Toola 👋
                                </h2>
                                <p className="mb-8 ml-1 mt-[10px] text-base text-gray-600">
                                    Connectez-vous à votre espace de livraison.
                                </p>
                                <InputField
                                    variant="auth"
                                    label="Adresse mail"
                                    placeholder="marc@otacos.com"
                                    id="email"
                                    type="text"
                                    onChange={(e: any) =>
                                        setEmail(e.target.value)
                                    }
                                />
                                {/* button */}
                                <CreateButton
                                    extra="mt-5"
                                    value="Recevoir l'accès par mail"
                                    onClick={login}
                                />
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center text-center">
                                <p className="mt-10 text-[100px] text-[#0260CB]">
                                    ✉️
                                </p>
                                <p className="text-xl font-bold text-navy-700">
                                    Un email vous a été envoyé
                                </p>
                                <p className="mt-2 text-base text-gray-600">
                                    Consultez votre boite mail pour accéder à
                                    votre espace de commande
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;

