import { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import SignDisplayCard from './SignDisplayCard';
import { addCommentToDelivery, signDelivery } from 'interfaces/delivery';
import { getOrderDeliveryNote } from 'interfaces/order';
import { ToastContainer, toast } from 'react-toastify';
import InputField from 'components/fields/InputField';
import { updateCustomer, updateCustomerDelivery } from 'interfaces/customer';

const SignDelivery = (props: {
    order: any;
    onExit: any;
    isExternalPage: boolean;
    remainingOrders: number;
}) => {
    const { onExit, order, isExternalPage, remainingOrders } = props;
    const [sigPad, setSigPad] = useState({}) as any;
    const [isSigned, setIsSigned] = useState(false);
    const [comment, setComment] = useState(null);
    const [plusReturnable, setPlusReturnable] = useState(0);
    const [minusReturnable, setMinusReturnable] = useState(0);
    const [returnableCount, setReturnableCount] = useState(
        order?.customer?.returnableCount,
    );

    useEffect(() => {
        const newValue =
            order?.customer?.returnableCount +
            (plusReturnable - minusReturnable);
        setReturnableCount(newValue);
    }, [minusReturnable, plusReturnable]);

    const save = async () => {
        if (comment) {
            addCommentToDelivery(order._id, comment, isExternalPage);
        }
        if (sigPad) {
            const data = sigPad.getTrimmedCanvas().toDataURL('image/png');
            const blob = await fetch(data).then((res) => res.blob());
            const formData = new FormData();
            formData.append('file', blob);
            const res = await signDelivery(formData, order._id, isExternalPage);
            if (isExternalPage) {
                await updateCustomerDelivery({
                    _id: order.customer._id,
                    returnableCount: returnableCount,
                });
            } else {
                await updateCustomer({
                    _id: order.customer._id,
                    returnableCount: returnableCount,
                });
            }
            if (res.data) setIsSigned(true);
            else
                toast.error(
                    'Votre session a expiré, veuillez vous reconnecter',
                );
        }
    };

    const downloadDeliveryNote = async () => {
        try {
            const blob = await getOrderDeliveryNote(order._id, isExternalPage);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `bon-livraison-${order.orderId || ''}.pdf`;
            link.click();
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    useEffect(() => {
        //document.body.style.overflow = 'hidden';
        window.scrollTo(0, 0);

        // return () => {
        //     document.body.style.overflow = 'unset';
        // };
    }, []);

    return (
        <div className="overflow-y-auto">
            <ToastContainer />
            <div className="mt-10 flex items-center justify-center">
                <SignDisplayCard order={order} />
            </div>
            {!isSigned ? (
                <>
                    {remainingOrders === 1 && (
                        <p className="mt-5 flex items-center justify-center text-lg text-gray-600">
                            C'est votre dernière livraison !
                        </p>
                    )}
                    <p className="mt-5 flex items-center justify-center text-base text-gray-600 underline">
                        Signer votre bon de livraison:
                    </p>
                    <div className="mt-1 flex items-center justify-center overflow-hidden">
                        <SignatureCanvas
                            canvasProps={{
                                width: 300,
                                height: 120,
                                className:
                                    'border-2 border-dashed border-gray-300',
                            }}
                            ref={(ref): any => {
                                setSigPad(ref);
                            }}
                        />
                    </div>
                    <div className="mx-10 mt-4 flex items-center justify-center">
                        <textarea
                            id="textInput"
                            className="w-[300px] h-[60px] text-sm px-4 py-2 border rounded-xl focus:outline-none focus:ring focus:border-blue-300 transition-colors duration-300"
                            placeholder="Ajouter un commentaire, signaler un problème (visible sur le BL)"
                            onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="mt-4 flex items-center justify-center">
                        <p>
                            Consignes détenues par le client: {returnableCount}
                        </p>
                    </div>
                    <div className="mt-2 flex gap-2 justify-center">
                        <InputField
                            label="Consignes déposées"
                            value={plusReturnable}
                            placeholder="Consignes déposées"
                            id={`returnable-gived`}
                            type="number"
                            onChange={(e: any) =>
                                setPlusReturnable(e.target.value)
                            }
                            inputSmall={true}
                        />
                        <InputField
                            label="Consignes récupérées"
                            value={minusReturnable}
                            placeholder="Consignes récupérées"
                            id={`returnable--recovered`}
                            type="number"
                            onChange={(e: any) =>
                                setMinusReturnable(e.target.value)
                            }
                            inputSmall={true}
                        />
                    </div>
                    <div className="mt-3 flex items-center justify-center">
                        <button
                            className={`mt-3 w-[130px] h-[34px] items-center justify-center rounded-xl py-2 text-center text-sm font-medium text-white transition duration-200 leading-none bg-toola-500 hover:bg-toola-600 active:bg-toola-700`}
                            onClick={save}
                        >
                            Signer
                        </button>
                        {comment && (
                            <button
                                className={`ml-3 mt-3 w-[130px] h-[34px] items-center justify-center rounded-xl py-2 text-center text-sm font-medium text-white transition duration-200 leading-none bg-red-400 hover:bg-red-400 active:bg-red-700`}
                                onClick={save}
                            >
                                Signature refusée
                            </button>
                        )}
                        <button
                            className={`mt-3 ml-3 flex w-[130px] h-[34px] text-white items-center justify-center rounded-xl py-2 text-center text-sm font-medium transition duration-200 leading-none bg-red-400 hover:bg-red-500 active:bg-red-600`}
                            onClick={() => {
                                sigPad.clear();
                            }}
                        >
                            Effacer
                        </button>
                    </div>
                    <div className="mx-10 mt-4 flex items-center justify-center">
                        <button
                            className={`mt-3 ml-3 flex w-[130px] h-[34px] text-red-500 items-center justify-center rounded-xl py-2 text-center text-sm font-medium transition duration-200 leading-none bg-white hover:bg-gray-100 active:bg-gray-200`}
                            onClick={() => onExit(false)}
                        >
                            Ne pas signer
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="mx-10 mt-2 flex items-center justify-center">
                        <button
                            className={`mt-3 px-3 py-2 items-center justify-center rounded-xl text-center text-sm font-medium text-white transition duration-200 leading-none bg-toola-500 hover:bg-toola-600 active:bg-toola-700`}
                            onClick={downloadDeliveryNote}
                        >
                            Télécharger le bon de livraison
                        </button>
                    </div>
                    <div className="mx-10 mt-2 flex items-center justify-center">
                        {remainingOrders === 1 ? (
                            <button
                                className={`mt-3 px-3 py-2 items-center justify-center rounded-xl text-center text-sm font-medium text-white transition duration-200 leading-none bg-toola-500 hover:bg-toola-600 active:bg-toola-700`}
                                onClick={() => onExit(isSigned)}
                            >
                                Terminer ma tournée
                            </button>
                        ) : (
                            <button
                                className={`mt-3 px-3 py-2 items-center justify-center rounded-xl text-center text-sm font-medium text-white transition duration-200 leading-none bg-toola-500 hover:bg-toola-600 active:bg-toola-700`}
                                onClick={() => onExit(isSigned)}
                            >
                                Retour aux livraisons
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default SignDelivery;
