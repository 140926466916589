import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";

const CreditNoteModal = (props: {
    isOpen: any;
    onClose: any;
    generateCreditNote: any;
}) => {
    const { isOpen, onClose, generateCreditNote } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="mb-[20px] text-xl font-bold">
                            Annuler une facture
                        </h1>
                        <p className="font-medium mb-2">
                            Toola génère automatiquement l'avoir en créant une nouvelle facture:
                        </p>
                        <p>
                            - qui fait mention de la facture annulée.
                        </p>
                        <p>
                            - en passant toutes les lignes de facturation en
                            négatif.
                        </p>
                        <p>
                            - en rendant les commandes de la facture modifiables.
                        </p>
                        <p>
                            - en rendant ces commandes à nouveau facturables.
                        </p>
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            <button
                                className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-base font-medium text-white active:bg-red-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={generateCreditNote}
                            >
                                {"Générer l'avoir"}
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreditNoteModal;
