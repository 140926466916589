import Card from "components/card";
import { useEffect, useState } from "react";
import {
    MdLightbulbOutline,
    MdNavigateBefore,
    MdNavigateNext,
} from "react-icons/md";
import { getIcon } from "utils/icons";

const StockAlert = (props: { data: any }) => {
    const { data } = props;
    const [alert, setAlert] = useState(null);
    const [index, setIndex] = useState(0);

    const handleNext = () => {
        if (index === data.expiringInventoryLines.length - 1) return;
        setIndex(index + 1);
        setAlert(data.expiringInventoryLines[index + 1]);
    };

    const handlePrev = () => {
        if (index === 0) return;
        setIndex(index - 1);
        setAlert(data.expiringInventoryLines[index - 1]);
    };

    const daysBetween = (date1: Date, date2: Date) => {
        // The number of milliseconds in one day
        var ONE_DAY = 1000 * 60 * 60 * 24;
        return Math.round(
            Math.abs((date1.getTime() - date2.getTime()) / ONE_DAY)
        );
    };

    useEffect(() => {
        if (data && data.expiringInventoryLines.length > 0)
            setAlert(data.expiringInventoryLines[0]);
        else
            setAlert({
                name: "Orange",
                lotNumber: "EXEMPLE-001",
                dlc: new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 4),
            });
    }, [data]);

    return (
        <Card extra={"flex flex-col justify-between w-full h-full relative p-4 border"}>
            {/* Header */}
            <div className="flex flex-col px-2 text-center">
                <p className="text-lg font-bold text-navy-700 dark:text-white">
                    DLC en approche
                </p>
                <p className="align-center items-align-center mx-auto mt-2 px-4 text-center text-sm font-medium text-gray-600">
                    Vos stocks avec les dates d'expiration les plus proches
                </p>
            </div>
            <div className="my-[15px] flex w-full items-start justify-between rounded-3xl bg-gray-100 px-3 py-[20px] hover:shadow-2xl">
                <div className="flex items-center gap-3">
                    <div className="flex w-[30%] items-center justify-center">
                        <img
                            className="h-full w-full rounded-xl"
                            src={getIcon(
                                alert ? alert.name : null,
                                "ingredient"
                            )}
                            alt=""
                        />
                    </div>
                    <div className="flex flex-col">
                        <h5 className="text-base font-bold text-navy-700 dark:text-white lg:text-md 3xl:text-3xl">
                            {" "}
                            {alert && alert.name}
                        </h5>
                        <p className="mt-1 text-sm 3xl:text-lg font-normal text-gray-600">
                            {`Lot "${alert && alert.lotNumber}"`}
                        </p>
                    </div>
                </div>

                <div className="mt-1 flex text-navy-700 dark:text-white">
                    <div className="mr-2 flex items-center text-[10px] font-bold text-navy-700 dark:text-white 3xl:text-sm">
                        {/* Days between alert.dlc and now */}
                        {`Expire dans ${
                            alert &&
                            daysBetween(new Date(alert.dlc), new Date())
                        }j`}
                    </div>
                </div>
            </div>
            {data && data.expiringInventoryLines.length > 0 && (
                <div className="mx-auto mb-2 grid grid-cols-3 gap-5">
                    <div
                        className="hover:cursor-pointer"
                        onClick={() => handlePrev()}
                    >
                        <MdNavigateBefore size={30} />
                    </div>
                    <p className="text-lg font-normal">{`${index + 1}/${
                        data && data.expiringInventoryLines.length
                    }`}</p>
                    <div
                        className="hover:cursor-pointer"
                        onClick={() => handleNext()}
                    >
                        <MdNavigateNext size={30} />
                    </div>
                </div>
            )}
            {data && data.expiringInventoryLines.length === 0 && (
                <div className="mx-auto mb-3 grid grid-cols-12">
					<MdLightbulbOutline size={30} />
					<p className="ml-3 col-span-11 text-sm text-gray-600">Une fois vos premiers composants en stock, les DLC les plus proches apparaîtrons ici.</p>
				</div>
            )}
        </Card>
    );
};

export default StockAlert;
