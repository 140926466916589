import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import InputField from "components/fields/InputField";
import CommandInfo from "./components/ProductInfo";
import CreateButton from "components/buttons/CreateButton";
import {
    loginOrder,
    pingAutoOrderPage,
    verifyLoginOrder,
} from "interfaces/autoOrder";
import { getLocalOrCookie } from "utils/localAndCookieStorage";

const cookies = new Cookies(null, { path: "/" });

const CommandPage = () => {
    const params = useParams();
    const [isAuth, setIsAuth] = useState(false);
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const login = async () => {
        const res = await loginOrder({ email });
        if (res) {
            setEmailSent(true);
        }
    };

    const verifyAuth = async (v: string) => {
        const res = await verifyLoginOrder({ magicLink: v });
        if (res) {
            localStorage.setItem("order-token", res.token);
            cookies.set("order-token", res.token);
			setIsAuth(true);
			setIsLoaded(true);
        } else {
			if (getLocalOrCookie("order-token"))
				redirectIfAuth();
			else setIsLoaded(true);
		}
    };

    const redirectIfAuth = async () => {
        const res = await pingAutoOrderPage();
        if (res) {
            setIsAuth(true);
            setIsLoaded(true);
        } else {
            setIsAuth(false);
            localStorage.removeItem("order-token");
            cookies.remove("order-token");
            setIsLoaded(true);
        }
    };

    useEffect(() => {
		if (!params.v && !getLocalOrCookie("order-token")) setIsLoaded(true);

        // Test magic link in priority, if magic-link invalid, check the token
		if (params.v) verifyAuth(params.v);
        else if (getLocalOrCookie("order-token")) redirectIfAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="bg-[#f7f6f7]">
            <ToastContainer />
            {isAuth && isLoaded && (
                <div className="border-5 shadow-3x grid h-full w-full grid-cols-12 content-center items-center gap-3 rounded-[20px] bg-[#f7f6f7] bg-clip-border p-[10px] py-0 align-middle">
                    <div className="col-span-12 items-center">
                        <CommandInfo />
                    </div>
                </div>
            )}
            {!isAuth && isLoaded && (
                <div className="mb-16 flex h-full w-full items-center justify-center">
                    {/* Sign in section */}
                    <div className="mt-[16vh] flex max-w-[90%] flex-col rounded-[20px] pb-16 pt-12 lg:max-w-[42%]">
                        {!emailSent ? (
                            <>
                                <h2 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
                                    Bienvenue sur Toola 👋
                                </h2>
                                <p className="mb-8 ml-1 mt-[10px] text-base text-gray-600">
                                    Connectez-vous à l'espace de commande de
                                    votre fournisseur.
                                </p>
                                <InputField
                                    variant="auth"
                                    label="Adresse mail"
                                    placeholder="marc@otacos.com"
                                    id="email"
                                    type="text"
                                    onChange={(e: any) =>
                                        setEmail(e.target.value)
                                    }
                                />
                                {/* button */}
                                <CreateButton
                                    extra="mt-5"
                                    value="Recevoir l'accès par mail"
                                    onClick={login}
                                />
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center text-center">
                                <p className="mt-10 text-[100px] text-[#0260CB]">
                                    ✉️
                                </p>
                                <p className="text-xl font-bold text-navy-700">
                                    Un email vous a été envoyé
                                </p>
                                <p className="mt-2 text-base text-gray-600">
                                    Consultez votre boite mail pour accéder à
                                    votre espace de commande
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CommandPage;
