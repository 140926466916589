import { useDisclosure } from "@chakra-ui/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { dateToFrench } from "utils/date";
import { getStock } from "interfaces/stock";
import { getPaginatedStockMovement } from "interfaces/stockMovement";
import LoadingTableLine from "./components/loadingTableLine";
import mobilityImg from "assets/img/mobility.png";
import StockExitModal from "../stockList/components/StockExitModal";
import StockEntryModal from "../stockList/components/StockEntryModal";
import Pagination from "components/pagination/Pagination";

const StockMovement = () => {
    const [stockMovement, setStockMovement] = useState([]);
    const navigate = useNavigate();
    const [stock, setStock] = useState(null);
    const [stockMovementMeta, setStockMovementMeta] = useState(null);
	const [missingProducts, setMissingProducts] = useState<any>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenExit,
        onOpen: onOpenExit,
        onClose: onCloseExit,
    } = useDisclosure();
    const [page, setPage] = useState(1);
    const [limitPerPage, setLimitPerpage] = useState(8);
    const isFullyLoaded = stock && stockMovement;

    const initStock = async () => {
        const res = await getStock();
        if (res.error) {
            navigate("/auth/login");
            return;
        }
        setStock(res.data);
    };

    const fetchStockMovements = async (page: number, limit: number) => {
        setLimitPerpage(limit);
        setStockMovement(null);
        const res = await getPaginatedStockMovement(page, limit);
        if (res) {
            setStockMovement(res.data);
            setStockMovementMeta(res.meta);
            setPage(page);
        }
    };

    const refreshData = async () => {
		// TODO: Replace that per a state refresh
		window.location.reload();
    };

    useEffect(() => {
		const queryParameters = new URLSearchParams(window.location.search)
		const entry = queryParameters.get("entry")
		const missing = queryParameters.get("missingP")
		if (entry && entry === "true") {
			onOpen();
		}
		if (missing) {
			setMissingProducts(missing.split(','))
		}
        initStock();
        fetchStockMovements(page, limitPerPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-10 px-5">
            <StockEntryModal
                onClose={onClose}
                isOpen={isOpen}
                stock={stock}
				missingProducts={missingProducts}
                onDone={() => refreshData()}
            />
            <StockExitModal
                onClose={onCloseExit}
                isOpen={isOpenExit}
                stock={stock}
                onDone={() => refreshData()}
            />
            <div className="mb-5 grid grid-cols-12 gap-5">
                <button
                    className="col-span-6 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5"
                    onClick={onOpen}
                >
                    Entrer du stock
                </button>
                <button
                    className="col-span-6 rounded-xl border-2 border-[#0260CB] px-5 py-3 text-base font-medium text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-brand-700/5"
                    onClick={onOpenExit}
                >
                    Sortir du stock
                </button>
            </div>
            {isFullyLoaded ?
            (
                stockMovement.length > 0 ? (
                    <>
                        <table className="w-full">
                            <thead>
                                <tr className="text-left h-[24px] border-b border-[#E9EDF7]">
                                    <th className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Date</th>
                                    <th className="hidden sm:table-cell pl-[10px] w-[10%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Numéro de lot</th>
                                    <th className="hidden sm:table-cell pl-[10px] w-[25%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Produit / Composant</th>
                                    <th className="hidden sm:table-cell pl-[10px] w-[10%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Zone de stockage</th>
                                    <th className="hidden sm:table-cell pl-[10px] w-[10%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Quantité</th>
                                    <th className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Motif</th>
                                    <th className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">DLC</th>
                                    <th className="sm:hidden pl-[10px] w-[60%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Date et produit</th>
                                    <th className="sm:hidden pl-[10px] w-[40%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Mouvement</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stockMovement.map((movement: any) => {
                                    return (
                                        <tr className="h-[40px] sm:h-[24px] border-t border-[#E9EDF7] sm:border-none">
                                            <td className="hidden sm:table-cell pl-[10px] py-3 text-left text-xs sm:text-sm font-bold text-navy-700 dark:text-white">{dateToFrench(movement.createdAt)}</td>
                                            <td className="hidden sm:table-cell pl-[10px] text-left text-xs sm:text-sm font-bold text-navy-700 dark:text-white">{movement.lotNumber ? movement.lotNumber : "__"}</td>
                                            <td className="hidden sm:table-cell pl-[10px] text-left text-xs sm:text-sm font-bold text-navy-700 dark:text-white">{movement.objectName}</td>
                                            <td className="hidden sm:table-cell pl-[10px] text-left text-xs sm:text-sm font-bold text-navy-700 dark:text-white">{movement.area}</td>
                                            <td className={`hidden sm:table-cell pl-[10px] text-left text-xs sm:text-sm font-bold ${movement.quantity > 0 ? "text-green-500" : "text-red-400"}`}>{(movement.quantity > 0 && "+") + movement.quantity}</td>
                                            <td className="hidden sm:table-cell pl-[10px] text-left text-xs sm:text-sm font-bold text-navy-700 dark:text-white">{movement.reason}</td>
                                            <td className="hidden sm:table-cell pl-[10px] text-left text-xs sm:text-sm font-bold text-navy-700 dark:text-white">{(movement.dlc && movement.dlc.includes("T")) ? movement.dlc.split("T")[0] : "__"}</td>
                                            <td className="sm:hidden pl-[10px] text-left text-xs sm:text-sm font-bold text-navy-700 dark:text-white">
                                                {dateToFrench(movement.createdAt)}<br />
                                                {movement.objectName}
                                            </td>
                                            <td className="sm:hidden pl-[10px] text-left text-xs sm:text-sm font-bold text-navy-700 dark:text-white">
                                                {movement.reason}<br />
                                                <span className={`${movement.quantity > 0 ? "text-green-500" : "text-red-400"}`}>{(movement.quantity > 0 && "+") + movement.quantity}</span>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <div className="col-span-12 text-center">
                        <img
                            className="mx-auto mt-[75px] flex h-[120px] w-[120px] items-center justify-center"
                            src={mobilityImg}
                            alt=""
                        />
                        <p className="mt-[45px] text-base text-gray-600">
                            Vous n'avez pas encore effectué de mouvements.<br/>Cliquez sur l'un des boutons çi-dessus pour commencer.
                        </p>
                    </div>
                )
            ) : (
                <div>
                    <table className="w-full">
                        <thead>
                        <tr className="text-left h-[24px] border-b border-[#E9EDF7]">
                                <th className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Date</th>
                                <th className="hidden sm:table-cell pl-[10px] w-[10%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Numéro de lot</th>
                                <th className="hidden sm:table-cell pl-[10px] w-[25%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Produit / Composant</th>
                                <th className="hidden sm:table-cell pl-[10px] w-[10%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Zone de stockage</th>
                                <th className="hidden sm:table-cell pl-[10px] w-[10%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Quantité</th>
                                <th className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Motif</th>
                                <th className="hidden sm:table-cell pl-[10px] w-[15%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">DLC</th>
                                <th className="sm:hidden pl-[10px] w-[60%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Date et produit</th>
                                <th className="sm:hidden pl-[10px] w-[40%] text-xs sm:text-sm text-gray-600 dark:text-white font-medium">Mouvement</th>
                            </tr>
                        </thead>
                    </table>
                    <div className="w-full">
                        {Array(limitPerPage).fill(<LoadingTableLine />)}
                    </div>
                </div>
            )}
            <Pagination
                extra={`mt-3 ${isFullyLoaded ? "block" : "hidden"}`}
                type="mouvements"
                maxItems={stockMovementMeta ? stockMovementMeta.total : 12}
                itemsPerPage={[8, 16, 24, 32]}
                page={page}
                onChange={(page: number, limit :number) => fetchStockMovements(page, limit)}
            />
        </div>
    );
};

export default StockMovement;
