import Card from "components/card";
import { useState } from "react";

const Delete = () => {
    const [wantToDelete, setWantToDelete] = useState(false);
    return (
        <Card extra="!flex-row py-6 px-[30px] justify-between items-center w-full border">
            <div>
                <h3 className="mb-px text-lg font-medium">
                    Supprimer ce compte
                </h3>
                <p className="text-base text-gray-600">
                    Attention cette action est irréversible
                </p>
            </div>
            {wantToDelete && (
                <div className="flex flex-col items-center justify-center">
                    <p className="text-xs text-red-500">
                        Vous pouvez supprimer votre compte en envoyant un
                        mail à mathieu@toola.eu
                    </p>
                </div>
            )}
            <button
                className="linear flex cursor-pointer items-center justify-center rounded-xl border border-red-500 px-4 py-2 text-base font-medium text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                onClick={() => setWantToDelete(!wantToDelete)}
            >
                Supprimer
            </button>
        </Card>
    );
};

export default Delete;
