
import Card from "components/card";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function OrderLines(props: { data: any, allDates: any}) {
    const { allDates, data } = props;
    const [lineChartDataOverallRevenue, setLineChartDataOverallRevenue] =
        useState<any[]>([
            {
                data: [],
            },
        ]);

    const [options, setOptions] = useState({
        chart: {
            toolbar: {
                show: false,
            },
            dropShadow: {
                enabled: true,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: "#0260CB",
            },
        },
        colors: ["#0260CB", "#39B8FF"],
        markers: {
            size: 0,
            colors: "white",
            strokeColors: "#7551FF",
            strokeWidth: 3,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            // discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            showNullDataPoints: true,
        },
        tooltip: {
            theme: "dark",
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            type: "line",
        },
        xaxis: {
            type: "datetime",
            min: new Date('01 Mar 2012').getTime(),
            tickAmount: 6,
            labels: {
                rotate: -45,
                style: {
                    colors: "#A3AED0",
                    fontSize: "10px",
                    fontWeight: "200",
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: true,
            labels: {
                style: {
                    colors: "#A3AED0",
                    fontSize: "8px",
                    fontWeight: "500",
                },
            },
        },
        legend: {
            show: true,
        },
        grid: {
            show: false,
            column: {
                color: ["#7551FF", "#39B8FF"],
                opacity: 0.5,
            },
        },
        color: ["#7551FF", "#39B8FF"],
    });

    useEffect(() => {
        if (!data || !data.data) return;
        setOptions({} as any);
        setLineChartDataOverallRevenue([]);

        setOptions({
            ...options,
            xaxis: {
                ...options.xaxis,
                min: new Date(allDates[0]).getTime(),
            }
        })
        for (let i = 0; i < data.data.length; i++) {
            data.data[i] = [new Date(data.data[i].date).getTime(), data.data[i].value];
        }

        setLineChartDataOverallRevenue([
            {
                name: "Commandes",
                data: data.data,
            },
        ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDates, data])

    return (
        <Card extra={"p-5 text-dm lg:h-full max-h-[400px] border"}>
            <h4 className="ml-px text-lg font-bold text-navy-700 dark:text-white">
                Commandes
            </h4>
            <p>En moyenne {data?.totalAverage} commandes / jour.</p>
            <ReactApexChart
                options={options as any}
                series={lineChartDataOverallRevenue as any}
                type="line"
                width="100%"
                height="85%"
            />
        </Card>
    );
}

export default OrderLines;
