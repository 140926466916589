import axios from "axios";
import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const updatePlugin = async (plugin: string, data: any) => {
    const res: any = await axios
        .put(`plugins/${plugin}`, data, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getPlugin = async (plugin: string) => {
    const res: any = await axios
        .get(`plugins/${plugin}`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};