import ingredientsImg from 'assets/img/ingredients.png';
import productImg from 'assets/img/products.png';
import friesImg from 'assets/img/stock/fries.png';
import onionImg from 'assets/img/stock/onion.png';
import potatoImg from 'assets/img/stock/potato.png';
import orangeImg from 'assets/img/stock/orange.png';
import orangeJuiceImg from 'assets/img/stock/orange-juice.png';
import labelImg from 'assets/img/stock/label.png';
import bottleImg from 'assets/img/stock/water-bottle.png';
import zipLockImg from 'assets/img/stock/ziplock.png';

const icons: any = {
    ingredient: ingredientsImg,
    oignon: onionImg,
    product: productImg,
    frite: friesImg,
    'pomme de terre': potatoImg,
    "jus d'orange": orangeJuiceImg,
    orange: orangeImg,
    bouteille: bottleImg,
    étiquette: labelImg,
    'sac sous vide': zipLockImg,
};

export const getIcon = (name: string, type: string) => {
    if (!name) return icons[type];
    for (const key in icons) {
        if (name.toLowerCase().includes(key)) {
            return icons[key];
        }
    }
    return icons[type];
};
