// Assets

import Card from "components/card";
import FileSelector from "components/fields/FileSelector";
import { addProductImage } from "interfaces/products";
import { toast } from "react-toastify";

const ProductImageWidget = (props: {productId: string, imageUrl: string}) => {
  const { productId, imageUrl } = props;

  const saveProductImage = async (file: string) => {
    const formData = new FormData();
    formData.append('file', file);
    const res = await addProductImage(productId, formData);
    if (res) {
      toast.success('Image ajoutée avec succès');
    }
  }

  return (
      <Card extra="mt-5 px-5 py-5 border">
          <h4 className="mb-[20px] text-lg font-medium"> Image du produit</h4>
          <FileSelector
              description="SVG, PNG, JPG or GIF (MAX. 800x400px)"
              onChange={(e) => saveProductImage(e.target.files[0])}
              logo={imageUrl}
          />
      </Card>
  );
};

export default ProductImageWidget;
