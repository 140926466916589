import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import { toast } from "react-toastify";

const shareOrderModal = (props: {
    isOpen: any;
    onClose: any;
    onSend: any;
    magicLink: string;
}) => {
    const { isOpen, onClose, onSend, magicLink } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="mb-5 text-xl font-bold">
                            Partager la boutique B2B
                        </h1>
                        <p className="text-base max-w-prose">
                            Chacun de vos clients dispose de son propre lien
                            permettant d'accéder à la boutique en
                            1 clic.
                        </p>
                        <div className="mt-5 flex gap-2 mx-auto">
                            {magicLink && (
                                <button
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            `https://app.toola.eu/command/${magicLink}`,
                                        );
                                        onClose();
                                        toast.success(
                                            "Lien copié dans le presse-papier",
                                        );
                                    }}
                                    className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                    Copier le lien
                                </button>
                            )}
                            <button
                                className="linear hover:bg-red600 col-span-12 ml-3 flex items-center justify-center rounded-xl bg-toola-500 px-3 py-3 text-base font-medium text-white active:bg-toola-700 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                onClick={onSend}
                            >
                                Envoyer par mail
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default shareOrderModal;
