import CommandPageInfo from "./components/CommandPageInfo";
// import CommandPagePay from "./components/CommandPagePay";
import SettingsMenu from "../component/SettingsMenu";
import { useEffect, useState } from "react";
import { getMyInfo, updateMyInfo } from "interfaces/user";
import SaveStatus from "components/actions/SaveStatus";
import CommandPageTuto from "./components/CommandPageTuto";
import CommandPageMailing from "./components//emailsReminders/CommandPageMailing";

const CommandPagePreference = () => {
    const [user, setUser] = useState<any>({});
    const [saveStatus, setSaveStatus] = useState<any>("none");

    const handleChange = (e: any) => {
        setSaveStatus("saving");
        setUser({...user, [e.target.id]: e.target.value});
    }

    const initUser = async () => {
        const res = await getMyInfo();
        if (res.data) {
            setUser(res.data);
        }
    }

    const updateCommandPageInfo = async () => {
        const res = await updateMyInfo({
            contactInformation: user.contactInformation,
            announcements: user.announcements,
        })
        if (res.data) setSaveStatus("saved");
        else setSaveStatus("error");

    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            updateCommandPageInfo();
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        initUser();
    }, []);

    return (
        <div className="mt-2">
            <div className="flex justify-between">
                <SettingsMenu path="/settings/command-page" />
                <SaveStatus status={saveStatus} />
            </div>
            <div className="mt-1 grid grid-cols-1 sm:grid-cols-2 gap-3">
                <CommandPageInfo user={user} handleChange={handleChange} />
                <CommandPageTuto />
                <div className="col-span-2">
                    <CommandPageMailing />
                </div>
                {/* <CommandPagePay user={{}} handleChange={() => {}} /> */}
            </div>
        </div>
    );
};

export default CommandPagePreference;
