import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { updateCustomer } from 'interfaces/customer';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const CustomerCard = (props: {
    image: string;
    company: string;
    displayName?: string;
    phone: string;
    download?: string;
    commands: string | number;
    extra?: string;
    id: string;
    customerId: string;
    returnableCount: number;
}) => {
    const { company, extra, id, customerId, displayName, returnableCount } =
        props;
    const [returnable, setReturnable] = useState(returnableCount);
    const [fistInit, setFirstInit] = useState(false);

    useEffect(() => {
        setReturnable(returnableCount);
        setFirstInit(false);
    }, [returnableCount]);

    useEffect(() => {
        if (!fistInit) {
            setFirstInit(true);
            return;
        }
        console.log(`returnableCount: ${returnableCount}`);
        const delayDebounceFn = setTimeout(async () => {
            const res = updateCustomer({
                _id: id,
                returnableCount: returnable,
            });
            if (res) toast.success('Consigne mise à jour');
            else toast.error('Erreur lors de la mise à jour de la consigne');
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [returnable]);

    return (
        <Card
            extra={`flex flex-col w-full h-[185px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25] ${extra}`}
            id={`card-${id}`}
        >
            <div className="h-full w-full">
                {/* <div className="relative w-full">
                    <img
                        src={image}
                        className="mb-3 h-full w-full rounded-xl 3xl:h-full 3xl:w-full"
                        alt=""
                    />
                </div> */}

                <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        {displayName ? (
                            <div>
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {' '}
                                    {displayName}
                                </p>
                                <p className="text-xs font-medium text-navy-700 dark:text-white">
                                    {' '}
                                    {company}
                                </p>
                            </div>
                        ) : (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {' '}
                                {company}
                                {displayName}
                            </p>
                        )}
                        <p className="text-sm text-navy-700 dark:text-white">
                            {'#'}
                            {customerId}{' '}
                        </p>
                        <div className="gap-5">
                            <div className="mt-2">
                                <InputField
                                    label="Consignes"
                                    value={returnable}
                                    placeholder="Consignes clients"
                                    id={`returnable-${id}`}
                                    type="number"
                                    onChange={(e: any) =>
                                        setReturnable(e.target.value)
                                    }
                                    inputSmall={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
                    {/* <div className="flex">
                        <p className="mb-2 text-sm font-bold text-[#0260CB] dark:text-white">
                            Commandes à traiter: {commands}
                        </p>
                    </div> */}
                </div>
            </div>
        </Card>
    );
};

export default CustomerCard;
