import Information from "./components/Information";
import Delete from "./components/Delete";
import Password from "./components/Password";
import AlertSystem from "./components/NewsLetter";
import BusinessInfo from "./components/BusinessInfo";
import OnBoardingCard from "../coursePage/components/OnBoardingCard";
import { useShepherdTour } from "react-shepherd";
import { useEffect, useState } from "react";
import SettingsMenu from "../component/SettingsMenu";

const steps: any = [
    {
        title: "Barre de progression",
        text: "Suivez les instructions de la barre de progression",
        attachTo: {
            element: ".step-onboarding-card",
            on: "bottom",
        },
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "Suivant",
                type: "next",
            },
        ],
        id: "step-user-progress",
    },
    {
        title: "Barre de progression",
        text: "Passez à l'étape suivante après avoir terminé l'étape actuelle",
        attachTo: {
            element: ".step-onboarding-next",
            on: "left",
        },
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "Suivant",
                action: function (this: any): any {
                    return this.hide();
                },
            },
        ],
        id: "step-onboarding-next",
    },
    {
        title: "2/5 Créer vos produits",
        text: "Rendez-vous dans la section Stock > Mon Stock pour créer vos produits",
        attachTo: {
            element: ".step-stock",
            on: "left",
        },
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "Créer mes produits",
                action: function (this: any): any {
                    localStorage.setItem("onboarding-step", "2");
                    window.location.href = "/stock/stock-list";
                },
            },
        ],
        id: "step-stock",
    },
];

const Setting = () => {
    const tourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
        },
        useModalOverlay: true,
    };
    const tour = useShepherdTour({ tourOptions, steps });
    const [tourStarted, setTourStarted] = useState(false);

    useEffect(() => {
        if (tourStarted) tour.start();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourStarted]);

    useEffect(() => {
        const onboardingStep = localStorage.getItem("onboarding-step");

        if (onboardingStep === "1") setTourStarted(true);
    }, [tour]);
    return (
        <div className="mt-2">
            <SettingsMenu path="/settings" />
            <div className="mt-2 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                {tourStarted && (
                    <div
                        className="flex col-span-2 step-onboarding-card"
                        onClick={() => {
                            tour.next();
                        }}
                    >
                        <OnBoardingCard
                            title="Paramétrer votre compte"
                            actualStep={1}
                            totalStep={5}
                            description="Indiquez les informations de votre entreprise, elles seront utilisées pour la facturation et la boutique B2B."
                            onClick={() => {}}
                        />
                    </div>
                )}
                <div className="flex flex-col gap-5">
                    <Information />
                    <AlertSystem />
                </div>

                <div className="flex flex-col gap-5">
                    <BusinessInfo />
                    <Password />
                    <Delete />
                </div>
            </div>
        </div>
    );
};

export default Setting;
