import { FaArrowLeftLong } from "react-icons/fa6";

import { useNavigate } from "react-router-dom";

const BackArrowButton = (props: {extra?: string, navigateTo?: string}) => {
    const navigate = useNavigate();
	const {extra, navigateTo} = props;
    return (
        <button
            className={`flex items-center justify-center w-[34px] h-[34px] rounded-full border-2 border-red-500 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10 ${extra}`}
                onClick={() => {
                    navigate(navigateTo)
                }}>
            <FaArrowLeftLong />
        </button>
    );
};

export default BackArrowButton;