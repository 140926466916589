import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { createProduct } from "interfaces/products";
import ProductTypeChoice from "./components/ProductTypeChoice";
import BrutProductForm from "./components/brutProductForm";
import TransformedProductForm from "./components/transformedProductForm";
import AssembledProductForm from "./components/assembledProductForm";

const CreateProduct = () => {
    const navigate = useNavigate();
    const [state, setState] = useState(0);
    const [productType, setProductType] = useState<string>(null);
    const [newProduct, setNewProduct] = useState<any>({
        name: "",
        category: "hidden",
        unity: "Kg",
        quantity: 0,
        daysOfConservation: 0,
        subProducts: [],
        ref: "TL-00001",
        price: 0,
        alert: 0,
        dlcAlert: 0,
    });

    const handleProductChange = (attr: string, value: string) => {
        const newProd: any = newProduct;
        newProd[attr] = value;
        if (attr === "name") {
            const words = value.split(" ");
            let ref = "";
            let refPrefixSize = 0;
            words.forEach((word: string) => {
                if (word.length > 0 && refPrefixSize < 2) {
                    ref += word[0];
                    refPrefixSize++;
                }
            });
            newProd["ref"] = (ref ? ref.toUpperCase() : "TL") + "-00001";
        }
        setNewProduct((prevProduct: any) => ({ ...prevProduct, ...newProd }));
    };

    const handleProductCreation = async (handleComposants: boolean) => {
        const newProd: any = newProduct;
        newProd["ingredients"] = [];

        // Clean subproduct
        newProd.subProducts = newProd.subProducts.map((subProduct: any) => {
            return {
                quantity: subProduct.quantity,
                id: subProduct.productId
            };
        });
        newProd.type = productType;

        const res = await createProduct(newProduct);
        if (res)
            navigate(handleComposants ? `/handle-comp/${res._id}?o=create` : "/stock/stock-list");
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-1 grid grid-cols-12">
            <div className={`col-span-12`}>
                {productType ? (
                    <div
                        className="mt-4 sm:mt-0 flex items-center justify-start cursor-pointer"
                        onClick={() => window.location.reload()}
                    >
                        <svg
                            className="w-5 h-5 mr-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                        <p className="text-xs">Changer le type de produit</p>
                    </div>
                ) : (
                    <ProductTypeChoice
                        productType={productType}
                        setProductType={setProductType}
                    />
                )}

                {productType === "brut" && (
                    <BrutProductForm
                        newProduct={newProduct}
                        handleProductChange={handleProductChange}
                        state={state}
                        setState={setState}
                    />
                )}

                {productType === "transformed" && (
                    <TransformedProductForm
                        product={newProduct}
                        handleProductChange={handleProductChange}
                        state={state}
                        setState={setState}
                    />
                )}

                {productType === "assembled" && (
                    <AssembledProductForm
                        product={newProduct}
                        handleProductChange={handleProductChange}
                        state={state}
                        setState={setState}
                    />
                )}

                {productType && (
                    <div className="mt-4">
                        <button
                            className="ml-7 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-sm font-medium text-white hover:bg-[#01499b] active:bg-[#024086]"
                            onClick={() => handleProductCreation(false)}
                        >
                            Créer mon produit
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateProduct;
