import axios from "axios";
import Cookies from "universal-cookie";

import env from "variables/config";
import { errorHandler } from "./utils";

const cookies = new Cookies(null, { path: "/" });

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    let token = localStorage.getItem("order-token");
    if (!token) token = cookies.get("order-token");

    return {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
};

export const sendAutoOrder = async (data: any) => {
    const res: any = await axios
        .post("auto-orders/create", data, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const pingAutoOrderPage = async () => {
    const res: any = await axios
        .get("auto-orders/ping", config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getOrdersFolders = async (year: string) => {
    const res: any = await axios
        .get(`auto-orders/my-orders/${year}/months`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getAutoOrderPage = async () => {
    const res: any = await axios
        .get("/auto-orders", config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getAlreadyOrderedProducts = async () => {
    const res: any = await axios
        .get("/auto-orders/already-ordered", config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
}

export const getAutoOrderConditions = async () => {
    const res: any = await axios
        .get("/auto-orders/conditions", config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
}

export const getPaginatedAutoOrderProducts = async (
    page: number,
    limit: number
) => {
    const res: any = await axios
        .get(`/auto-orders?limit=${limit}&page=${page}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getMyAutoOrdersPerMonth= async (
    month: string,
    year: string,
) => {
    const res: any = await axios
        .get(`/auto-orders/my-orders/${month}/${year}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getMyInvoicesPerMonth = async (month: string, year: string) => {
    const res: any = await axios
        .get(`/auto-orders/invoices/${month}/${year}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getDeliveryNote = async (id: string) => {
    const fileConfig: any = {
        headers: {
            Authorization: config().headers.Authorization,
        },
        responseType: "arraybuffer",
    };
    const res = await axios
        .get(`/auto-orders/delivery-note/${id}`, fileConfig)
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return new Blob([res.data], { type: "application/pdf" });
};

export const getAutoOrdersInvoice = async (id: string) => {
    const fileConfig: any = {
        headers: {
            Authorization: config().headers.Authorization,
        },
        responseType: "arraybuffer",
    };
    const res = await axios
        .get(`/auto-orders/invoice/${id}`, fileConfig)
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return new Blob([res.data], { type: "application/pdf" });
};

export const loginOrder = async (data: any) => {
    const res: any = await axios
        .post("/auto-orders/login", data)
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const verifyLoginOrder = async (data: any) => {
    const res: any = await axios
        .post("/auto-orders/verify", data)
        .catch((e) => {
            console.log(e);
        });
    if (!res) return null;
    return res.data;
};
