import GoBack from "components/actions/GoBack";

const ProductTypeChoice = (props: { productType: string, setProductType: any }) => {
  const { productType, setProductType } = props;

  return (
      <>
          <GoBack />
          {!productType && (
              <h4 className="mt-5 sm:mt-0 ml-4 text-lg font-medium text-navy-700 dark:text-white">
                  Choix du type de produit:
              </h4>
          )}
          <div className="px-3 mt-4 grid grid-cols-1 lg:grid-cols-3 text-gray-600 gap-5">
              <div
                  className={`cursor-pointer border rounded-xl p-4 ${
                      productType === "brut" && "bg-gray-50"
                  } `}
                  onClick={() => setProductType("brut")}
              >
                  <p className="font-bold">Produit brut</p>
                  <p className="text-sm max-w-[300px]">
                      Légume, emballage, produit fini
                  </p>
                  <p className="mt-3 text-sm max-w-[300px]">
                      {">"} Matière première ou produit vendu en tant que tel
                  </p>
                  <p className="mt-2 text-sm max-w-[300px]">
                      {">"} Vendu directement, assemblé ou transformé.
                  </p>
              </div>
              <div
                  className={`cursor-pointer border rounded-xl p-4 ${
                      productType === "transformed" && "bg-gray-50"
                  } `}
                  onClick={() => setProductType("transformed")}
              >
                  <p className="font-bold">Produit transformé</p>
                  <p className="text-sm max-w-[300px]">
                      Frites fraiches, jus de fruit, confiture, sauce.
                  </p>
                  <p className="mt-3 text-sm max-w-[300px]">
                      {">"} Créé à l'issue d'une production à partir de produits
                      bruts ou transformés.
                  </p>
                  <p className="mt-2 text-sm max-w-[300px]">
                      {">"} Vendu directement ou assemblé.
                  </p>
              </div>
              <div
                  className={`cursor-pointer border rounded-xl p-4 ${
                      productType === "assembled" && "bg-gray-50"
                  } `}
                  onClick={() => setProductType("assembled")}
              >
                  <p className="font-bold">Produit assemblé</p>
                  <p className="text-sm max-w-[300px]">
                      Filet, éclatement de palette, lot multi-produit
                  </p>
                  <p className="mt-3 text-sm max-w-[300px]">
                      {">"} Il s'agit d'une packaging constitué d'un ou de
                      plusieurs produits brut ou transformé.
                  </p>
                  <p className="mt-2 text-sm max-w-[300px]">
                      {">"} Vendu directement.
                  </p>
              </div>
          </div>
      </>
  );
};

export default ProductTypeChoice;