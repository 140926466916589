import { MdEuro, MdOutlineShoppingBasket } from "react-icons/md";
import { TbPigMoney, TbMoneybag } from "react-icons/tb";
import { useParams } from "react-router-dom";

import MiniStatistics from "components/card/MiniStatistics";
import { getCustomerAnalytics } from "interfaces/customer";
import { useEffect, useState } from "react";

const CustomerStats = (props: {
    id?: string;
}) => {
    const [analytics, setAnalytics] = useState(null);
    const params = useParams();
    const id = params.id || props.id;

    const getAnalytics = async () => {
        if (!id) return;
        const res = await getCustomerAnalytics(id);
        if (res) setAnalytics(res);
    };

    useEffect(() => {
        getAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="col-span-12 grid h-full w-full grid-cols-2 gap-3 sm:gap-5 md:grid-cols-2 xl:grid-cols-4">
            <MiniStatistics
                name="Panier moyen"
                value={analytics ? `${analytics.basketAverageValue} €` : "- €"}
                icon={<MdOutlineShoppingBasket color="#0260CB" />}
                iconBg="bg-lightPrimary"
            />
            <MiniStatistics
                name="Reste à payer"
                value={
                    analytics
                        ? `${analytics.leftToPay.toFixed(2)} €`
                        : "- €"
                }
                icon={<TbPigMoney color="#0260CB" />}
                iconBg="bg-lightPrimary"
            />
            <MiniStatistics
                name="CA 3 dern. mois"
                value={
                    analytics
                        ? `${analytics.basketAverageValueLast90Days} €`
                        : "- €"
                }
                icon={<MdEuro color="#0260CB" />}
                iconBg="bg-lightPrimary"
            />
            <MiniStatistics
                name="CA 6 dern. mois"
                value={
                    analytics
                        ? `${analytics.basketAverageValueLast180Days} €`
                        : "- €"
                }
                icon={<TbMoneybag color="#0260CB" />}
                iconBg="bg-lightPrimary"
            />
        </div>
    );
}

export default CustomerStats