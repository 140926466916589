import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { toast } from "react-toastify";
import { useDisclosure } from "@chakra-ui/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "components/card";
import { createCustomer } from "interfaces/customer";
import { createDeliveryTour, getDeliveryTours } from "interfaces/deliveryTour";
import InputField from "components/fields/InputField";
import { isFormErrored, isValidEmail, isValidPhoneNumber } from "utils/verification";
import GoBack from "components/actions/GoBack";


const CreateProspect = () => {
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [newCustomer, setNewCustomer] = useState<any>({
        status: 'prospect'
    });
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [deliveryTours, setDeliveryTours] = useState<any>([]);

    const handleChange = (e: any) => {
        setNewCustomer((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const addCustomer = async (redirectToList = true) => {

        const validationRules: any = {
            company: (value: any) => !!value.trim(),
        };

        if (isFormErrored(newCustomer, validationRules))
            return toast.error("Veuillez vérifier le formulaire");

        const res = await createCustomer(newCustomer);
        if (res) {
            toast.success("Prospect ajouté");
            redirectToList ? navigate("/kanban") : window.location.reload();
        }
    };

    // const handleChangeBilling = (e: any) => {
    //     setNewCustomer((prevCustomer: any) => ({
    //         ...prevCustomer,
    //         billingAddress: e.target.value,
    //         deliveryAddress: e.target.value,
    //     }));
    // };

	const createNewDeliveryTour = async (deliveryTour: any) => {
		const res = await createDeliveryTour(deliveryTour);
		if (res) {
			setDeliveryTours((prev: any) => [...prev, res]);
		}
		onClose();
	};

    const CreateDeliveryTour = (props: { isOpen: any; onClose: any }) => {
        const { isOpen, onClose } = props;
        const [newDeliveryTour, setNewDeliveryTour] = useState<any>({});

        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">
                                Rajouter une tournée de livraison
                            </h1>
                            <InputField
                                label="Nom de la tournée"
                                placeholder="Marseille-Est"
                                id="deliveryTour"
                                type="text"
                                onChange={(e: any) =>
                                    setNewDeliveryTour((prev: any) => ({
                                        ...prev,
                                        name: e.target.value,
                                    }))
                                }
                            />
                            <div className="mt-5 flex gap-2">
                                <button
                                    onClick={onClose}
                                    className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                    Annuler
                                </button>
                                <button
                                    className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] bg-[] px-3 py-3 text-base font-medium text-white hover:bg-[#01499b] active:bg-[#0260CB] sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                    onClick={() => {
										createNewDeliveryTour(
											newDeliveryTour
										);
										setNewDeliveryTour({});
									}}
                                >
                                    Créer la tournée
                                </button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    };

	const initDeliveryTours = async () => {
		const res: any = await getDeliveryTours(false);
		if (res)
			setDeliveryTours(res.data);
	}

    useEffect(() => {
        // Set prospect status if it's in the URL
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get("status");
        if (status) {
            setNewCustomer((prevCustomer: any) => ({
                ...prevCustomer,
                status,
            }));
        }

        if (localStorage.getItem("token") === null) {
            navigate("/login");
        }
		initDeliveryTours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GoBack />
            <div className="mt-3 grid  grid-cols-12">
                <CreateDeliveryTour isOpen={isOpen} onClose={onClose} />
                <div className={`col-span-12`}>
                    <Card extra={"w-full !p-5 border"}>
                        {/* Header */}
                        <div className="w-full">
                            <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                                Ajout d'un prospect
                            </h4>
                        </div>
                        {/* inputs */}
                        <div className="mt-4 grid gap-3 grid-cols-12">
                            <p className="col-span-12 text-base">
                                Informations principales
                            </p>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Raison sociale*"
                                    placeholder="Toola"
                                    id="company"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-2">
                                <InputField
                                    label="Numéro client"
                                    placeholder="00012"
                                    id="customerId"
                                    type="text"
                                    onChange={handleChange}
                                    error="Numéro de client invalide"
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-3">
                                <InputField
                                    label="Nom commercial"
                                    placeholder="Toola"
                                    id="displayName"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-2">
                                <InputField
                                    label="Téléphone"
                                    placeholder="+33678003678"
                                    id="phone"
                                    type="text"
                                    onChange={handleChange}
                                    error="invalide"
                                    value={newCustomer.phone}
                                    isInvalid={
                                        !isValidPhoneNumber(newCustomer.phone)
                                    }
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-2">
                                <InputField
                                    label={`Contact`}
                                    placeholder="Mathieu Dupont"
                                    id="contact"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-12 lg:col-span-8">
                                <InputField
                                    label={`Notes utiles`}
                                    placeholder="N'est pas disponible par téléphone le vendredi"
                                    id="notes"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                        </div>
                        {/* full width inputs */}
                        <div className="mt-4 grid gap-3 grid-cols-12">
                            <div className="col-span-12">
                                <p className="text-base">Boutique B2B</p>
                                <p className="mt-1 text-xs">
                                    Permet à votre prospect de découvrir tout
                                    vos produits via un lien de connexion envoyé
                                    par email.
                                </p>
                            </div>
                            <div className="col-span-12 lg:col-span-4">
                                <InputField
                                    label="Email"
                                    placeholder="mathieu@toola.eu"
                                    id="mail"
                                    type="text"
                                    onChange={handleChange}
                                    error="Email invalide"
                                    value={newCustomer.mail}
                                    isInvalid={!isValidEmail(newCustomer?.mail)}
                                    inputSmall={true}
                                />
                            </div>
                        </div>

                        {/* <div className="mt-8 grid  grid-cols-1 gap-3 xl:grid-cols-12">
                        <p className="col-span-12 text-base">
                            Informations de livraison
                        </p>
                        <div className="col-span-12 lg:col-span-6">
                            <InputField
                                label="Adresse de livraison*"
                                placeholder={`Champ de Mars, 5 Av. Anatole France, 75007 Paris, France`}
                                id="deliveryAddress"
                                type="text"
                                onChange={handleChangeBilling}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <SearchSelector
                                label="Tournée de livraison"
                                displayKey="name"
                                options={deliveryTours}
                                onSelect={(e: any) => {
                                    setNewCustomer((prevCustomer: any) => ({
                                        ...prevCustomer,
                                        deliveryTourId: e._id,
                                    }));
                                }}
                                canCreate={true}
                                createNew={onOpen}
                                inputSmall={true}
                            />
                        </div>
                    </div>
                    <div className="mt-8 grid  grid-cols-1 gap-3 xl:grid-cols-12">
                        <p className="col-span-12 text-base">
                            Informations de facturation
                        </p>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label="Email de facturation*"
                                placeholder="mathieu@toola.eu"
                                id="billingEmail"
                                type="text"
                                onChange={handleChange}
                                error="Email invalide"
                                value={newCustomer.billingEmail}
                                isInvalid={
                                    !isValidEmail(newCustomer.billingEmail)
                                }
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <SearchSelector
                                label="Période de facturation*"
                                displayKey="name"
                                options={[
                                    { _id: "flexible", name: "flexible" },
                                    { _id: "10-days", name: "10 jours" },
                                    { _id: "15-days", name: "15 jours" },
                                    { _id: "1-month", name: "1 mois" },
                                ]}
                                onSelect={(e: any) => {
                                    setNewCustomer((prevCustomer: any) => ({
                                        ...prevCustomer,
                                        billingPeriod: e._id,
                                    }));
                                }}
                                inputSmall={true}
                            />
                        </div>
                        <div className="mt-4 col-span-12 lg:col-span-6 lg:mt-0">
                            <InputField
                                label={`Adresse de facturation*`}
                                placeholder="Champ de Mars, 5 Av. Anatole France, 75007 Paris, France"
                                value={newCustomer.billingAddress}
                                id="billingAddress"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label={`Numéro de TVA`}
                                placeholder="FR32123456789"
                                id="vatNumber"
                                type="text"
                                onChange={handleChange}
                                error="Numéro de TVA invalide"
                                value={newCustomer.vatNumber}
                                isInvalid={
                                    !isValidVATNumber(newCustomer.vatNumber)
                                }
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-3">
                            <InputField
                                label={`Numéro de SIREN`}
                                placeholder="123568941"
                                id="siren"
                                type="text"
                                onChange={handleChange}
                                error="SIREN invalide"
                                value={newCustomer.siren}
                                isInvalid={!isValidSiren(newCustomer.siren)}
                                inputSmall={true}
                            />
                        </div>
                    </div> */}
                        <div className="mt-10 grid grid-cols-6 gap-5">
                            <button
                                className="linear col-span-3 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-sm font-medium text-white hover:bg-[#01499b] active:bg-[#024086]"
                                onClick={() => addCustomer(false)}
                            >
                                Ajouter, puis en ajouter un autre
                            </button>
                            <button
                                className="linear col-span-3 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-sm font-medium text-white hover:bg-[#01499b] active:bg-[#024086]"
                                onClick={() => addCustomer()}
                            >
                                Ajouter uniquement
                            </button>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default CreateProspect;
