import InputField from "components/fields/InputField";

const weekDays = [
    {en: "monday", fr: "lundi", display: "L"},
    {en: "tuesday", fr: "mardi", display: "M"},
    {en: "wednesday", fr: "mercredi", display: "M"},
    {en: "thursday", fr: "jeudi", display: "J"},
    {en: "friday", fr: "vendredi", display: "V"},
    {en: "saturday", fr: "samedi", display: "S"},
    {en: "sunday", fr: "dimanche", display: "D"},
];

const DeliveryToursTable = (props: {
    updateSavingStatus?: any;
    data: any;
    updateDeliveryTour: any;
    updateDistributionDay: any;
    deleteDeliveryTour: any;
}) => {
    const { data, updateDeliveryTour, updateDistributionDay, deleteDeliveryTour } = props;

    return (
        <table className="w-full">
            <thead>
                <tr key={0} className="!border-px !border-gray-400">
                    <th
                        key={1}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] w-full sm:w-[15%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Tournée
                        </p>
                    </th>
                    {/* <th
                        key={2}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-full sm:w-[15%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Type de tournée
                        </p>
                    </th> */}
                    <th
                        key={1}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-full sm:w-[15%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Délais de livraison (jours)
                        </p>
                    </th>
                    <th
                        key={1}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-full sm:w-[15%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Min. de commande (€)
                        </p>
                    </th>
                    <th
                        key={3}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[70px] w-[30%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="pl-5 text-sm font-bold text-gray-600 dark:text-white">
                            Jours de distribution
                        </p>
                    </th>
                    <th
                        key={4}
                        colSpan={0}
                        onClick={() => {}}
                        className="hidden sm:table-cell mt-[20px] w-[80px] sm:w-[10%] cursor-pointer border-b border-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:border-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Actions
                        </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.tours.map((deliveryTour: any, index: number) => (
                    <tr
                        key={`${deliveryTour._id}-${index}`}
                        className="h-[64px]"
                    >
                        {/* TourName */}
                        <td
                            key={`${deliveryTour._id}-${index}-name`}
                            className="border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <div className="hidden sm:table-cell">
                                <InputField
                                    key={`${deliveryTour._id}-${index}-name-input`}
                                    placeholder="Nom"
                                    id="name"
                                    type="text"
                                    value={deliveryTour.name}
                                    onChange={(e: any) => {
                                        updateDeliveryTour(e, deliveryTour._id);
                                    }}
                                    inputSmall={true}
                                />
                                <p className="font-base mt-[2px] text-gray-600">
                                    {deliveryTour.email}
                                </p>
                            </div>
                            <div className="sm:hidden">
                                <p className="text-sm font-bold text-navy-700 dark:text-white xl:leading-3">
                                    {deliveryTour.name}
                                </p>
                            </div>
                        </td>
                        {/* Delivery Delay */}
                        <td
                            key={`${deliveryTour._id}-${index}-name`}
                            className="border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <div className="hidden sm:table-cell">
                                <InputField
                                    key={`${deliveryTour._id}-${index}-delay-input`}
                                    id="deliveryDelay"
                                    type="number"
                                    value={deliveryTour.deliveryDelay}
                                    onChange={(e: any) => {
                                        updateDeliveryTour(e, deliveryTour._id);
                                    }}
                                    inputSmall={true}
                                />
                            </div>
                        </td>
                        {/* Min order */}
                        <td
                            key={`${deliveryTour._id}-${index}-name`}
                            className="border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <div className="hidden sm:table-cell">
                                <InputField
                                    key={`${deliveryTour._id}-${index}-min-input`}
                                    id="minimumOrderAmount"
                                    type="number"
                                    value={deliveryTour.minimumOrderAmount}
                                    onChange={(e: any) => {
                                        updateDeliveryTour(e, deliveryTour._id);
                                    }}
                                    inputSmall={true}
                                />
                            </div>
                        </td>
                        {/* <td
                            key={`${deliveryTour._id}-${index}-type`}
                            className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <SearchSelector
                                displayKey="name"
                                options={[
                                    { name: "Livraison", _id: "livraison" },
                                ]}
                                value={"livraison"}
                                disabled={true}
                                onSelect={() => {}}
                                inputSmall={true}
                            />
                        </td> */}
                        <td className="pl-10 border-t text-sm dark:border-white/10 px-[10px] py-[10px]">
                            <div className="flex items-center mb-4 gap-3">
                                {weekDays.map((day, index) => (
                                    <input
                                        key={`${deliveryTour._id}-${index}-checkbox`}
                                        id={day.en}
                                        type="checkbox"
                                        checked={deliveryTour.distributionDays.includes(
                                            day.en,
                                        )}
                                        onChange={(e) =>
                                            updateDistributionDay(
                                                e,
                                                deliveryTour._id,
                                                day.en,
                                            )
                                        }
                                        className="w-4 h-4 text-toola-500 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    ></input>
                                ))}
                            </div>
                            <div className="flex items-center text-center mb-4 gap-5">
                                {weekDays.map((day, index) => (
                                    <label
                                        key={`${deliveryTour._id}-${index}-label`}
                                        htmlFor="default-checkbox"
                                        className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        {day.display}
                                    </label>
                                ))}
                            </div>
                        </td>
                        <td
                            key={`${deliveryTour._id}-${index}-actions`}
                            className="hidden sm:table-cell border-t text-sm dark:border-white/10 px-[10px] py-[10px]"
                        >
                            <button
                                className="linear col-span-3 flex items-center justify-center rounded-xl bg-red-400 px-3 py-3 text-sm font-medium text-white hover:bg-red-500 active:bg-red-600"
                                onClick={() =>
                                    deleteDeliveryTour(deliveryTour._id)
                                }
                            >
                                Supprimer
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default DeliveryToursTable;