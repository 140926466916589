function ProductLoadingCard() {
    return (
        <div
            className={`h-[207px] items-center border border-[#7090B0/0.25] rounded-[20px] bg-white px-[10px] py-[10px] shadow-3xl shadow-shadow-500`}
        >
            <div className="grid grid-cols-12 gap-3 relative">
                <div className="col-span-12 flex flex-col">
                    <div className="mb-2 w-full animate-pulse">
                        <div className="my-[15px] mx-auto flex items-center justify-center w-[75px] h-[75px] bg-gray-300 rounded dark:bg-gray-700">
                            <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                            </svg>
                        </div>
                        <div className="h-3 bg-[#1B254B]/[0.75] rounded-full dark:bg-gray-700 w-[40%] mt-[24px] mb-[32px]"></div>
                        <div className="h-5 bg-[#0260CB]/[0.75] rounded-full dark:bg-gray-700 w-[100px] mb-5"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductLoadingCard;
