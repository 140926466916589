import Card from "components/card";
import InputField from "components/fields/InputField";
import { updatePassword } from "interfaces/user";
import { useState } from "react";
import { toast } from "react-toastify";

const Password = () => {
    const [data, setData] = useState({
        oldPassword: "",
        newPassword: "",
        passwordConfirm: "",
    });

    const [error, setError] = useState(null);

    const handleChange = (e: any) => {
        const { id, value } = e.target;
        setData({ ...data, [id]: value });
    };

    const changePassword = async () => {
        console.log(data);
        if (data.newPassword !== data.passwordConfirm) {
            setError("Les mots de passe ne correspondent pas");
            return;
        }
        setError(null);
        const res = await updatePassword({
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
        })
		if (res.error) return;
		toast.success("Votre mot de passe a été modifié avec succès");


    };

    return (
        <Card extra={"w-full px-6 py-6 border"}>
            {/* Header */}
            <div className="w-full px-2">
                <h4 className="text-lg font-medium">
                    Modifier mon mot de passe
                </h4>
                {error && <p className="text-red-500">{error}</p>}
            </div>
            {/* inputs */}
            <div className="mt-4 grid grid-cols-1 gap-3">
                <InputField
                    label="Ancien mot de passe"
                    placeholder="*********"
                    id="oldPassword"
                    type="password"
                    onChange={handleChange}
                />
                <InputField
                    label="Nouveau mot de passe"
                    placeholder="*********"
                    id="newPassword"
                    type="password"
                    onChange={handleChange}
                />
                <InputField
                    label="Nouveau mot de passe"
                    placeholder="*********"
                    id="passwordConfirm"
                    type="password"
                    onChange={handleChange}
                />

                <div className="mt-2 flex w-full justify-end">
                    <button
                        className="linear rounded-xl bg-toola-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-toola-600 active:bg-toola-700 dark:bg-toola-400 dark:text-white dark:hover:bg-toola-300 dark:active:bg-toola-200"
                        onClick={() => changePassword()}
                    >
                        Changer mon mot de passe
                    </button>
                </div>
            </div>
            {/* full width inputs */}
        </Card>
    );
};

export default Password;
