import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function CircularProgress(props: {
  title: string | JSX.Element;
  percentage: number;
  firstColor?: string;
  secondColor?: string;
}) {
  const { percentage, firstColor, secondColor } = props;

  return (
    <CircularProgressbarWithChildren
      value={percentage}
      styles={buildStyles({
        rotation: 0.25,
        strokeLinecap: "round",
        textSize: "16px",
        pathTransitionDuration: 0.5,
        pathColor: firstColor || "#0260CB",
        textColor: "#1B2559",
        trailColor: secondColor || "#E9EDF7",
        backgroundColor: "#0260CB",
      })}
    >
      <div className="flex flex-col items-center justify-center">
        {/* <div className="flex items-center justify-center text-xs font-medium text-gray-600">
          {title}
        </div> */}
        <div className="text-xs font-bold text-navy-700 dark:text-white">
          {percentage}%
        </div>
      </div>
    </CircularProgressbarWithChildren>
  );
}

export default CircularProgress;
